// src/components/PriceDisplay.js

import React, { useState, useEffect, useRef } from 'react';
import { Box, Snackbar, Typography, IconButton, TextField, Button, Link, Menu, MenuItem, } from '@mui/material';
import EggIcon from '@mui/icons-material/Egg';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import axios from 'axios';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import TopCryptoLoans from './TopCryptoLoans';
import PopularLinks from './PopularLinks';
import PaymentPopup from './PaymentPopup';
import AuthPopup from './AuthPopup';
import UsdVsBtcComparison from './UsdVsBtcComparison';
import UsdToBtcConverter from './UsdToBtcConverter';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import EducationLinks from './EducationLinks';
import CryptoPurchaseFinder from './CryptoPurchaseFinder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LenderBorrowerApply from './LenderBorrowerApply';
import NewsAndBlogs from './NewsAndBlogs';
import HomeAdBanner from './HomeAdBanner';
import PlayCoinGames from './PlayCoinGames';
import DogSprite from './DogSprite';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import DownloadIcon from '@mui/icons-material/Download';
import InstallAppButton from './InstallAppButton';
import Dashboard from './Dashboard'; // Make sure the path is correct
import { useAuth } from '../contexts/AuthContext';

const AUDIO_BASE_URL = "https://media.satoshiadvance.com/audio";


const TickerTapeComponent = ({ content }) => {
  return (
    <TickerTape>
      <TickerContent>
        {content}
      </TickerContent>
    </TickerTape>
  );
};


const tickerString = `
Hello, Good day, and thanks for Visiting SatoshiAdvance - Home for Crypto Loans, news, events and more! •
Wear headphones & Explore!
Bitcoin All Time High $108,800 January, 2025! •
Borrow, Lend, Shop, & more in Dogecoin, BTC, ETH •
Ethereum All Time High $4,7250 November, 2021! •
DOGE All time High $0.74 May, 2021! •
As of December 2024, 10,000 Satoshi's are worth about $10 USD! •
Play Retro Bit Dogs! learn about dog breeds & download your favorite card•
`;

// 2) Use JSX to display ticker text + Egg icon
const tickerContent = (
  <>
    {tickerString}
    <Tooltip
      arrow
      componentsProps={{
        tooltip: {
          sx: { fontSize: '1rem' } // increase font size as needed
        }
      }}
      title={
        <Typography variant="body1">
          Enable Purple (Juicy Plum) Theme! <br />
          1. In Microsoft Edge, click the three-dot menu in the top-right corner <br />
          2. Tap "Settings" <br />
          3. In the Upper Left, tap the three-line menu option <br />
          4. Choose "Appearance" <br />
          5. Under "Theme" or "Theme color," select "Juicy plum"
        </Typography>
      }
    >
      <EggIcon
        sx={{
          color: '#cc66ff',             // more purple
          fontSize: '1.4rem',            // slightly larger size
          verticalAlign: 'middle',
          filter: 'drop-shadow(0 0 2px #cc66ff)', // subtle glow
          marginLeft: '8px',
          cursor: 'pointer',
        }}
      />
    </Tooltip>
  </>
);

const DOG_TUNES = [
  "DogTunes1.wav",
  "DogTunes2.wav",
  "DogTunes3.wav",
  "DogTunes4.wav",
  "DogTunes5.wav",
  "DogTunes6.wav",
  "DogTunes7.wav",
  "DogTunes8.wav",
  "DogTunes9.wav",
  "DogTunes10.wav",
  "DogTunes11.mp3",
];

// Retro button with ! and ? signs styled as a white box with shaded blue interior
const RetroSquareButton = styled('div')(({ theme }) => ({
  // Base styles (tablet)
  width: '60px',
  height: '60px',
  backgroundColor: '#1a237e',
  border: '4px solid #FFFFFF',
  color: '#FFFFFF',
  fontSize: '2rem',
  fontFamily: "'Press Start 2P', cursive",
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#283593',
  },
  position: 'fixed',
  zIndex: 100000,

  // Mobile styles (55% smaller)
  [theme.breakpoints.down('sm')]: {
    width: '27px',  // 60px * 0.45 (45% of original size)
    height: '27px', // 60px * 0.45
    fontSize: '0.9rem', // Reduced font size for mobile
    border: '2px solid #FFFFFF', // Thinner border for mobile
  },

  // Desktop/widescreen styles (100% bigger)
  [theme.breakpoints.up('lg')]: {
    width: '120px',  // 60px * 2
    height: '120px', // 60px * 2
    fontSize: '4rem', // Larger font size for desktop
    border: '6px solid #FFFFFF', // Thicker border for desktop
  }
}));

const Separator = styled('span')({
  color: '#fff',
  margin: '0 8px',
});

// Add these with your other styled components
const TickerTape = styled('div')(({ theme }) => ({
  width: '80%',
  margin: '0 auto',
  marginTop: '10px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'relative',
  fontFamily: "'Roboto', sans-serif",
  fontSize: '19px',
  color: '#FFFFFF',
  padding: '8px 0',
  backgroundColor: '#283593',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    fontSize: '16px'
  }
}));

const TickerContent = styled('div')({
  display: 'inline-block',
  animation: 'ticker 45s linear infinite',
  paddingLeft: '100%',
  '& a': {
    color: '#00BFFF',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: '#00BFFF'
    }
  },
  '&:hover': {
    animationPlayState: 'paused'
  },
  '@keyframes ticker': {
    '0%': {
      transform: 'translateX(0)'
    },
    '100%': {
      transform: 'translateX(-100%)'
    }
  }
});

const HeaderTitleMobile = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '1.5rem',
  textAlign: 'center',
  marginTop: '10px',
  display: 'block',  // Always render it by default
  [theme.breakpoints.up('md')]: {
    display: 'none'  // Hide on medium and larger screens
  }
}));

const RetroButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  backgroundColor: 'transparent',
  border: '2px solid #FFFFFF',
  borderRadius: '4px',
  padding: '4px 12px',
  fontSize: '0.7rem',
  textTransform: 'none',
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    boxShadow: '0 0 10px #00BFFF'
  },
  transition: 'all 0.3s ease'
}));

const PaymentButton = ({ onClick }) => {
  const payAudioRef = useRef(null);

  const playPaySound = (e) => {
    e.preventDefault();
    if (payAudioRef.current) {
      payAudioRef.current.currentTime = 0;
      payAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playPaySound(e);
    onClick(); // This triggers the popup
  };

  return (
    <>
      <audio ref={payAudioRef} src={`${AUDIO_BASE_URL}/PayButton.mp3`} />
      <div onClick={playPaySound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>$</RetroSquareButton>
      </div>
    </>
  );
};

// Buttons with specific content and links
const LeftButton = () => {
  const wheelAudioRef = useRef(null);

  const playWheelSound = (e) => {
    e.preventDefault(); // Prevent single click from navigating
    if (wheelAudioRef.current) {
      wheelAudioRef.current.currentTime = 0;
      wheelAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playWheelSound(e);
    window.open('https://wheel.satoshiadvance.com', '_blank');
  };

  return (
    <Box sx={{ position: 'fixed', zIndex: 100000 }}>
      <audio ref={wheelAudioRef} src={`${AUDIO_BASE_URL}/WheelButton.mp3`} />
      <div onClick={playWheelSound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ top: '40px', left: '20px', position: 'fixed' }}>!</RetroSquareButton>
      </div>
    </Box>
  );
};

const RightButton = () => {
  const tanksAudioRef = useRef(null);

  const playTanksSound = (e) => {
    e.preventDefault();
    if (tanksAudioRef.current) {
      tanksAudioRef.current.currentTime = 0;
      tanksAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playTanksSound(e);
    window.open('https://tanks.satoshiadvance.com', '_blank');
  };

  return (
    <Box sx={{ position: 'fixed', zIndex: 100000 }}>
      <audio ref={tanksAudioRef} src={`${AUDIO_BASE_URL}/TanksButton.mp3`} />
      <div onClick={playTanksSound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ top: '40px', right: '20px', position: 'fixed' }}>?</RetroSquareButton>
      </div>
    </Box>
  );
};

// Add this code below your existing LeftButton and RightButton components
const BottomLeftButton = () => {
  const flipAudioRef = useRef(null);

  const playFlipSound = (e) => {
    e.preventDefault();
    if (flipAudioRef.current) {
      flipAudioRef.current.currentTime = 0;
      flipAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playFlipSound(e);
    window.open('https://flip.satoshiadvance.com', '_blank');
  };

  return (
    <Box sx={{ position: 'fixed', zIndex: 100000 }}>
      <audio ref={flipAudioRef} src={`${AUDIO_BASE_URL}/FlipButton.mp3`} />
      <div onClick={playFlipSound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ bottom: '20px', left: '20px', position: 'fixed' }}>B</RetroSquareButton>
      </div>
    </Box>
  );
};

const RetroText = styled(motion(Typography))(({ theme }) => ({
  color: '#FFFFFF', // White lettering
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  fontWeight: 'bold',
  fontSize: '2.4rem',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontFamily: "'Press Start 2P', cursive",
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}));

const RetroIconButton = styled(motion(IconButton))(({ theme }) => ({
  color: '#FFFFFF',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  fontSize: '4rem',
  marginRight: '20px',
  '& .MuiSvgIcon-root': {
    fontSize: '4rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '3.0rem',
    '& .MuiSvgIcon-root': {
      fontSize: '3.0rem',
    },
  },
}));

export const DividerBar = styled(motion('div'))(({ theme }) => ({
  width: '80%',
  height: '4px',
  backgroundColor: '#FFFFFF',
  margin: '20px auto 0',
  marginBottom: '30px',
  transformOrigin: 'center',
  boxShadow: `
    0 0 10px #FFFFFF,
    0 0 20px #00BFFF,
    0 0 30px #00BFFF,
    0 0 40px #00BFFF,
    0 0 70px #FFFFFF,
    0 0 80px #FFFFFF,
    0 0 100px #00BFFF,
    0 0 150px #00BFFF
  `,
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}));

export const MotionDivider = () => {
  return (
    <DividerBar
      initial={{ scaleX: 0 }}
      animate={{
        scaleX: 1,
        rotateX: 15,
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
      }}
    />
  );
};

const EmailInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '1rem',
    color: '#FFFFFF',
    backgroundColor: '#283593',
    borderRadius: '4px',
    width: '562.5px',
    height: '40px',
    boxSizing: 'border-box',
    padding: '10px 14px',
    border: '2px solid #FFFFFF',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#3949ab',
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused': {
      borderColor: '#FFFFFF',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
    padding: '10px 0',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '95vw',
    '& .MuiOutlinedInput-root': {
      width: '100%',
    }
  }
}));

const SignUpButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '1rem',
  color: '#FFFFFF',
  backgroundColor: '#283593',
  border: '2px solid #FFFFFF',
  borderRadius: '4px',
  padding: '10px 20px',
  marginLeft: '0',
  textTransform: 'uppercase',
  height: '40px',
  '&:hover': {
    backgroundColor: '#1a237e',
    borderColor: '#FFFFFF',
  },
  [theme.breakpoints.down('sm')]: {
    width: '120px',
    fontSize: '0.8rem',
    padding: '8px 16px',
    margin: '0 auto',
  }
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between', // Changed to space-between for all screen sizes
  alignItems: 'center', // Changed to center to align items vertically
  width: '80%',
  borderBottom: '1px solid #fff',
  height: '30px',
  position: 'fixed',
  top: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
  backgroundColor: '#283593',
  paddingTop: '5px',
  padding: '15px 20px 10px 20px',

  [theme.breakpoints.down('sm')]: {
    width: '90%',
    padding: '15px 10px 10px 10px'
  }
}));


const HeaderTitle = styled(Typography)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '22px',
  color: '#fff',
  marginBottom: '2px',
  display: 'block', // Ensure it's always displayed
});

const HeaderLink = styled(Link)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '19px',
  color: '#fff',
  textDecoration: 'none',
  marginBottom: '2px',
  cursor: 'pointer',
  '&:hover': {
    color: '#ccc',
  },
});

const FooterBar = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center', // Center the content horizontally
  alignItems: 'center',
  gap: '1rem',              // Space between items
  backgroundColor: '#283593',
  padding: '10px 20px',
  zIndex: 1000,
}));

const AppLink = styled(Link)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '19px',
  color: '#fff',
  textDecoration: 'none',
  display: 'inline-flex',   // so we can place the icon after the text
  alignItems: 'center',     // vertically center text & icon
  cursor: 'pointer',
  '&:hover': {
    color: '#ccc',
  },
});





const FooterLink = styled(Link)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '19px',
  color: '#fff',
  textDecoration: 'none',
  '&:hover': {
    color: '#ccc',
  },
});

const PriceDisplay = () => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [price, setPrice] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [playlist] = useState([
    `${AUDIO_BASE_URL}/retrodisco.wav`,
    `${AUDIO_BASE_URL}/retrolightning.wav`,
    `${AUDIO_BASE_URL}/retrotrance.wav`,
    `${AUDIO_BASE_URL}/retrogame8.wav`,
    `${AUDIO_BASE_URL}/UpbeatRetroIntro.wav`,
    `${AUDIO_BASE_URL}/DogTunes7.wav`,
    `${AUDIO_BASE_URL}/DogTunes1.wav`,
    `${AUDIO_BASE_URL}/DogTunes2.wav`,
    `${AUDIO_BASE_URL}/DogTunes3.wav`,
    `${AUDIO_BASE_URL}/DogTunes4.wav`,
    `${AUDIO_BASE_URL}/DogTunes5.wav`,
    `${AUDIO_BASE_URL}/DogTunes6.wav`,
    `${AUDIO_BASE_URL}/DogTunes8.wav`,
    `${AUDIO_BASE_URL}/DogTunes9.wav`,
    `${AUDIO_BASE_URL}/DogTunes10.wav`,
    `${AUDIO_BASE_URL}/DogTunes11.mp3`,
  ]);
  const audioRef = useRef(null);
  const aboutAudioRef = useRef(null);
  const nextButtonAudioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // Volume state & toggle
  const [volume, setVolume] = useState(1);
  const toggleVolume = () => {
    if (isIOS) {
      // Just toggle the visual state
      setVolume(volume === 1 ? 0.33 : 1);

      // Show a one-time notification to iOS users
      if (!localStorage.getItem('volume-notice-shown')) {
        alert("On iOS devices, please use the hardware volume buttons to adjust sound. This app will still show volume icons for visual feedback.");
        localStorage.setItem('volume-notice-shown', 'true');
      }
    } else {
      // Normal volume toggle for non-iOS
      if (audioRef.current) {
        audioRef.current.volume = volume === 1 ? 0.33 : 1;
      }
      setVolume(volume === 1 ? 0.33 : 1);
    }
  };

  // For TTS (speak once) & fade logic

  const [hasSpoken, setHasSpoken] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  // iOS detection
  useEffect(() => {
    // Check if the device is running iOS
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(iOS);

    // Pre-load audio for iOS
    if (iOS && audioRef.current) {
      // Set initial source to enable later playback
      audioRef.current.src = playlist[currentTrackIndex];
      // Add dummy event listener to prepare audio context
      document.addEventListener('touchstart', () => { }, { passive: true });
    }
  }, []);

  // Improved fade-in music function with iOS support
  const fadeInMusic = () => {
    if (!audioRef.current) return;

    setIsFading(true);

    // Start at volume 0
    audioRef.current.volume = 0;

    let stepCount = isIOS ? 10 : 30; // Fewer steps for iOS for quicker fade
    let currentStep = 0;
    const fadeInterval = setInterval(() => {
      currentStep++;

      // Calculate the new volume, capped at max volume
      const calculatedVolume = (currentStep / stepCount) * 0.75;
      const newVolume = Math.min(calculatedVolume, volume);

      // Apply the new volume
      if (audioRef.current) {
        audioRef.current.volume = newVolume;
      }

      if (currentStep >= stepCount) {
        clearInterval(fadeInterval);
        setIsFading(false);
      }
    }, isIOS ? 500 : 1000); // Faster intervals for iOS (0.5s vs 1s)

    return fadeInterval;
  };

  const readTickerAloud = () => {
    console.log("Read ticker aloud requested, isIOS:", isIOS);

    if (!('speechSynthesis' in window)) {
      console.log("Speech synthesis not supported in this browser");
      return;
    }

    // Force cancel any existing speech
    window.speechSynthesis.cancel();

    if (isIOS) {
      // Simple, direct approach - no alerts
      try {
        // Create a short, concise message
        const iosSummary = "Hello and Welcome to Satoshi Advance. Home for Crypto Loans, News, Shopping, Games & More. Thanks for visiting"

        // Create utterance with iOS-friendly settings
        const utterance = new SpeechSynthesisUtterance(iosSummary);
        utterance.rate = 0.9;
        utterance.pitch = 1.0;
        utterance.volume = 1.0;

        // Speak immediately without any alerts or delays
        window.speechSynthesis.speak(utterance);

        // Log for debugging
        console.log("iOS speech initiated");
      } catch (err) {
        console.error("iOS speech error:", err);
      }
    } else {
      // Non-iOS devices continue with normal behavior
      try {
        const utterance = new SpeechSynthesisUtterance(tickerString);
        utterance.rate = 0.9;
        utterance.volume = 1.0;
        window.speechSynthesis.speak(utterance);
      } catch (err) {
        console.error("Speech error:", err);
      }
    }
  };

  // Modified toggleMusic to handle iOS differently
  const toggleMusic = () => {
    console.log("Toggle music called, isIOS:", isIOS);

    if (!audioRef.current) return;

    if (isPlaying) {
      // Pause logic remains the same
      audioRef.current.pause();
      setIsPlaying(false);
      return;
    }

    // Special handling for iOS
    if (isIOS) {
      console.log("Using iOS-specific playback approach");
      // On iOS, trigger speech immediately with user action
      if (!hasSpoken) {
        console.log("Triggering speech directly from user action");
        readTickerAloud();
        setHasSpoken(true);
      }

      // Then attempt audio playback
      audioRef.current.src = playlist[currentTrackIndex];
      audioRef.current.load();
      audioRef.current.play()
        .then(() => {
          console.log("iOS audio playback started successfully");
          setIsPlaying(true);
          audioRef.current.volume = 0.1; // Start quiet on iOS

          // Simple fade-in for iOS
          setTimeout(() => {
            if (audioRef.current) audioRef.current.volume = 0.3;
          }, 2000);
        })
        .catch(error => console.log('iOS audio playback error:', error));
    } else {
      // Non-iOS logic remains largely the same
      audioRef.current.src = playlist[currentTrackIndex];
      audioRef.current.load();
      audioRef.current.play()
        .then(() => {
          setIsPlaying(true);
          fadeInMusic();

          if (!hasSpoken) {
            setTimeout(() => {
              readTickerAloud();
              setHasSpoken(true);
            }, 500);
          }
        })
        .catch((error) => {
          console.log('Error playing audio:', error);
        });
    }
  };

  const skipTrack = () => {
    const nextIndex = currentTrackIndex < playlist.length - 1 ? currentTrackIndex + 1 : 0;
    setCurrentTrackIndex(nextIndex);
  };

  // Updated track ending handler
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = playlist[currentTrackIndex];
      if (isPlaying) {
        audioRef.current.load();
        audioRef.current.play()
          .catch(error => console.log('Error playing audio:', error));
      }
    }
  }, [currentTrackIndex, playlist, isPlaying]);

  // Keep audioRef in sync with volume toggles
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        // Determine the base URL based on environment
        const baseURL = window.location.hostname === 'localhost'
          ? 'http://localhost:5000'  // Local development
          : 'https://satoshi-homepage-59650ad0a3ce.herokuapp.com'; // Production Heroku URL

        console.log('Fetching BTC price from:', baseURL);

        const response = await axios.get(`${baseURL}/api/btc-price`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });

        if (response.data?.success && response.data?.price) {
          const formattedPrice = parseFloat(response.data.price).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          });
          setPrice(formattedPrice);
          console.log('Successfully set BTC price:', formattedPrice);
        } else {
          throw new Error('Invalid price data received');
        }
      } catch (error) {
        console.error('Error fetching BTC price:', error.message);
        setPrice('Price Unavailable');

        // Detailed error logging
        if (error.response) {
          console.error('Error response:', {
            status: error.response.status,
            data: error.response.data
          });
        }
      }
    };

    fetchBTCPrice();
    const interval = setInterval(fetchBTCPrice, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Check if running on iOS
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    console.log("iOS detection:", iOS);
    setIsIOS(iOS);
  }, []);

  useEffect(() => {
    // If there's a hash, scroll to it after a short delay
    if (window.location.hash) {
      const anchorId = window.location.hash.substring(1);
      setTimeout(() => {
        const anchorElement = document.getElementById(anchorId);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 800);
    }
  }, []);

  // Autoplay attempt: pick a random track
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * playlist.length);
    setCurrentTrackIndex(randomIndex);

    // On iOS, don't attempt autoplay as it will fail
    if (audioRef.current && !isIOS) {
      audioRef.current.play().then(() => {
        setIsPlaying(true);
      }).catch((error) => {
        console.log('Autoplay failed:', error);
      });
    }
  }, []);

  useEffect(() => {
    const shouldShowWelcome = localStorage.getItem('showWelcomeNotification');
    if (shouldShowWelcome === 'true') {
      setShowWelcome(true);
      localStorage.removeItem('showWelcomeNotification');

      const audio = new Audio(`${AUDIO_BASE_URL}/retroshot.wav`);
      audio.play().catch(error => console.log('Error playing sound:', error));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nextButtonAudioRef.current) {
      nextButtonAudioRef.current.currentTime = 0;
      nextButtonAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }

    if (formStep === 1) {
      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        setEmailValid(false);
        return;
      }
      setEmailValid(true);
      setFormStep(2);
    } else {
      try {
        await axios.post('/api/signup', formData);
        alert('Thank you for signing up!');
        setFormStep(1);
        setFormData({
          email: '',
          loanAmount: '',
          useOfFunds: '',
          country: ''
        });
      } catch (error) {
        console.error('Error signing up:', error);
        alert('Failed to sign up. Please try again.');
      }
    }
  };

  const openPaymentPopup = () => {
    setIsPopupOpen(true);
  };

  const closePaymentPopup = () => {
    setIsPopupOpen(false);
  };

  const scrollToDogSprite = (e) => {
    e.preventDefault();
    const element = document.getElementById('dogSprite');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [emailValid, setEmailValid] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // For payment popup

  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    loanAmount: '',
    useOfFunds: '',
    country: ''
  });

  const [showWelcome, setShowWelcome] = useState(false);
  const { userEmail, isAuthenticated, logout } = useAuth();
  const userData = isAuthenticated ? { email: userEmail } : null;

  const [anchorEl, setAnchorEl] = useState(null);
  const [loginTab, setLoginTab] = useState('login');

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout(); // This will handle clearing localStorage/sessionStorage
    handleMenuClose();

    const logoutSound = new Audio(`${AUDIO_BASE_URL}/retroshot.wav`);
    logoutSound.play().catch(error => console.log('Error playing sound:', error));
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        backgroundColor: '#283593',
        padding: '45px 20px 50px 20px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowX: 'hidden',
        maxWidth: '100vw',
        paddingBottom: '100px',
      }}
    >
      <HeaderContainer>
        <HeaderTitle>Satoshi Advance</HeaderTitle>
        {userData ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  backgroundColor: '#283593',
                  border: '1px solid #FFFFFF',
                  '& .MuiMenuItem-root': {
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: '19px',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }
                }
              }}
            >
              <MenuItem onClick={() => {
                setShowDashboard(true);
                handleMenuClose();
              }}>
                Menu
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <HeaderLink
              onClick={handleMenuClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
              }}
            >
              {userData.email}
              <ArrowDropDownIcon />
            </HeaderLink>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <HeaderLink
              onClick={() => {
                setIsSignUpOpen(true);
                setLoginTab('login');
              }}
              sx={{
                marginBottom: '2px',
                '&:hover': { color: '#ccc' }
              }}
            >
              Login
            </HeaderLink>
            <Separator>/</Separator>
            <HeaderLink
              onClick={() => {
                setIsSignUpOpen(true);
                setLoginTab('signup');
              }}
              sx={{
                marginBottom: '2px',
                '&:hover': { color: '#ccc' }
              }}
            >
              Sign Up
            </HeaderLink>
          </Box>
        )}
      </HeaderContainer>


      <TickerTapeComponent content={tickerContent} />

      <HeaderTitleMobile>Satoshi Advance</HeaderTitleMobile>


      {showWelcome && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setShowWelcome(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Box
            sx={{
              backgroundColor: '#1a237e',
              color: '#FFFFFF',
              padding: '16px',
              borderRadius: '4px',
              border: '2px solid #FFFFFF',
              fontFamily: "'Press Start 2P', cursive",
              fontSize: '0.8rem',
              boxShadow: `
                0 0 10px #FFFFFF,
                0 0 20px #00BFFF,
                0 0 30px #00BFFF
              `,
            }}
          >
            Account setup complete! Welcome to Satoshi Advance
          </Box>
        </Snackbar>
      )}

      <LeftButton />
      <RightButton />
      <BottomLeftButton />

      <Box>
        {/* Mobile Layout (XS) */}
        <Box
          sx={{
            // Show only on extra-small screens up to the md breakpoint
            display: { xs: 'flex', md: 'none' },
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            marginTop: '40px',
          }}
        >
          {/* Row of 3 icons side by side, centered */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Play/Pause */}
            <RetroIconButton onClick={toggleMusic}>
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </RetroIconButton>

            {/* Skip */}
            <RetroIconButton onClick={skipTrack}>
              <SkipNextIcon />
            </RetroIconButton>

            {/* Volume */}
            <RetroIconButton onClick={toggleVolume}>
              {volume === 1 ? (
                <VolumeUpIcon sx={{ fontSize: '3rem', color: '#FFFFFF' }} />
              ) : (
                <VolumeDownIcon sx={{ fontSize: '3rem', color: '#FFFFFF' }} />
              )}
            </RetroIconButton>
          </Box>

          {/* BTC Price below the icons */}
          <Box sx={{ marginTop: 2 }}>
            <RetroText>{price || '$0.00'}</RetroText>
          </Box>
        </Box>

        {/* Desktop Layout (MD+) */}
        <Box
          sx={{
            // Show only on screens >= md
            display: { xs: 'none', md: 'flex' },
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 3,
            marginTop: '80px',
          }}
        >
          {/* Left: Play/Pause */}
          <RetroIconButton onClick={toggleMusic}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </RetroIconButton>

          {/* Center: BTC Price */}
          <RetroText>{price || '$0.00'}</RetroText>

          {/* Right: Skip + Volume */}
          <RetroIconButton onClick={skipTrack}>
            <SkipNextIcon />
          </RetroIconButton>
          <RetroIconButton onClick={toggleVolume}>
            {volume === 1 ? (
              <VolumeUpIcon sx={{ fontSize: '3rem', color: '#FFFFFF' }} />
            ) : (
              <VolumeDownIcon sx={{ fontSize: '3rem', color: '#FFFFFF' }} />
            )}
          </RetroIconButton>
        </Box>
      </Box>





      <MotionDivider />

      <Box
        sx={{
          width: '100%',
          padding: '40px 0',
          backgroundColor: '#1a237e',
          marginY: '40px',
          borderRadius: '10px',
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)'
        }}
      >
        <LenderBorrowerApply />
      </Box>

      <audio ref={audioRef} src={playlist[currentTrackIndex]} />
      <audio ref={nextButtonAudioRef} src={`${AUDIO_BASE_URL}/retroshot.wav`} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: {
            xs: '0',
            sm: '0',
            md: '-0.42in'
          },
          width: '100%',
          '& > *': {
            width: { xs: '95%', md: '100vw' },
            maxWidth: '2900px',
          },
        }}
      >
        <TopCryptoLoans />
      </Box>

      <TickerTapeComponent content={tickerContent} />

      <UsdVsBtcComparison />
      <PopularLinks />
      <UsdToBtcConverter />
      <EducationLinks />
      <TickerTapeComponent content={tickerContent} />
      <CryptoPurchaseFinder />
      <NewsAndBlogs />
      <HomeAdBanner />
      <PlayCoinGames />
      <HomeAdBanner />


      <PaymentButton onClick={openPaymentPopup}>$</PaymentButton>
      {isPopupOpen && <PaymentPopup onClose={closePaymentPopup} />}

      {showDashboard && <Dashboard onClose={() => setShowDashboard(false)} />}

      <Box id="dogSprite" sx={{ scrollMargin: '100px', padding: 0, marginBottom: '-20px' }}>
        <DogSprite />
      </Box>
      <TickerTapeComponent content={tickerContent} />


      <FooterBar>
        <InstallAppButton />
        <Separator>/</Separator>
        <FooterLink
          component="div"
          onClick={(e) => {
            e.preventDefault();
            if (aboutAudioRef.current) {
              aboutAudioRef.current.currentTime = 0;
              aboutAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
            }
          }}
          onDoubleClick={() => window.location.href = '/about'}
          sx={{ cursor: 'pointer' }}
        >
          About
        </FooterLink>
        <Separator>/</Separator>
        <FooterLink
          component="a"
          href="https://www.satoshiadvance.com/privacy-policy"
          sx={{ cursor: 'pointer' }}
        >
          Privacy
        </FooterLink>
      </FooterBar>



      {isSignUpOpen && <AuthPopup onClose={() => setIsSignUpOpen(false)} />}
    </Box>
  );
};

export default PriceDisplay;

