// src/components/HomeAdBanner.js
import React, { useEffect } from 'react';

const HomeAdBanner = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      console.error("AdSense error:", error);
    }
  }, []);

  return (
    <div style={{ textAlign: 'center', margin: '2rem 0' }}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-format="fluid"
        data-ad-layout-key="-dv+k+4s-l1+is"
        data-ad-client="ca-pub-1837036543658433"
        data-ad-slot="4575398190"
      ></ins>
    </div>
  );
};

export default HomeAdBanner;
