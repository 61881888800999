import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Checkbox, 
  FormControlLabel, 
  IconButton, 
  InputAdornment, 
  Tooltip 
} from '@mui/material';
import { styled } from '@mui/system';
import { QRCodeCanvas } from 'qrcode.react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

// Styled Components for the Popup
const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  padding: '20px',
  backgroundColor: '#1a237e',
  borderRadius: '10px',
  textAlign: 'center',
  zIndex: 1000,
  boxShadow: '0px 0px 20px 0px #00BFFF',
  maxHeight: '90vh',
  overflowY: 'auto',
  '@media (max-width: 600px)': {
    padding: '15px',
  }
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 999,
}));

const RetroTitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '0.9rem', // 50% smaller
  textShadow: `0 0 10px #FFFFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF`,
  fontFamily: "'Press Start 2P', cursive",
  marginBottom: '20px',
  '@media (max-width: 600px)': {
    fontSize: '0.7rem',
    marginTop: '0.5in',
  }
}));

const SummaryText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '1rem',
  marginBottom: '15px',
  '@media (max-width: 600px)': {
    fontSize: '0.9rem',
  }
}));

const CopyField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#283593',
  borderRadius: '5px',
  marginBottom: '15px',
  input: {
    color: '#FFFFFF',
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '0.9rem',
    '@media (max-width: 600px)': {
      fontSize: '0.8rem',
    }
  },
}));

const FlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: '-10px',
  marginBottom: '10px',
}));

const LabelText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '0.9rem',
  marginTop: '-10px',
  marginBottom: '10px',
  '@media (max-width: 600px)': {
    fontSize: '0.8rem',
  }
}));

// Subscription Option Component
const SubscriptionOption = ({ price, description, selectedPlan, onSelect, planType }) => {
  const isSelected = selectedPlan === price;
  
  let displayName;
  if (price === 0) {
    displayName = "Free";
  } else if (planType === 'monthly') {
    displayName = "$5/mo";
  } else {
    displayName = "$15/yr";
  }

  return (
    <Box
      onClick={() => onSelect(price, planType)}
      sx={{
        backgroundColor: isSelected
          ? 'rgba(0, 191, 255, 0.3)'
          : 'rgba(0, 191, 255, 0.1)',
        padding: '15px',
        borderRadius: '8px',
        cursor: 'pointer',
        border: isSelected
          ? '2px solid #00BFFF'
          : '1px solid rgba(255, 255, 255, 0.2)',
        transition: 'all 0.2s ease',
        '&:hover': {
          backgroundColor: 'rgba(0, 191, 255, 0.2)',
          transform: 'translateY(-2px)',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30%',
        boxSizing: 'border-box',
      }}
    >
      <Typography
        sx={{
          color: '#FFFFFF',
          fontFamily: "'Press Start 2P', cursive",
          fontSize: '1.2rem',
          mb: 1,
        }}
      >
        {displayName}
      </Typography>
      <Typography sx={{ color: '#FFFFFF', fontSize: '0.8rem' }}>
        {description}
      </Typography>
    </Box>
  );
};

const PaymentPopup = ({ onClose, amount = 15 }) => {
  const { user } = useAuth();
  const [selectedPlan, setSelectedPlan] = useState(() => {
    // Set default plan based on amount
    if (amount === 0) return 0;
    if (amount === 5) return 5;
    return 15; // Default to annual ($15)
  });
  const [selectedPlanType, setSelectedPlanType] = useState(() => {
    if (amount === 5) return 'monthly';
    return 'annual';
  });
  
  const [cryptoAmount, setCryptoAmount] = useState('0.00015');
  const [usdAmount, setUsdAmount] = useState(`$${selectedPlan}.00`);
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState('BTC');
  const [email, setEmail] = useState(user?.email || '');
  
  const cryptoAddresses = {
    BTC: '3KybzUMnSGkJMNmoshXD7wwaeLuENQNpNB',
    ETH: '0xB8ea0cEF886Fa141B3c60E220320FA911d3bBC99',
    DOGE: 'DGRAizMZUuQuqMLSTUt7AxoEsktYryA6s1'
  };

  // Update email if user changes
  useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
    }
  }, [user]);

  // Update crypto amount when plan or crypto changes
  useEffect(() => {
    const fetchCryptoPrices = async () => {
      // Skip API call for free plan
      if (selectedPlan === 0) {
        setUsdAmount('$0.00');
        setCryptoAmount('0');
        return;
      }
      
      try {
        const response = await axios.get('/api/crypto-payment-prices');
        const prices = response.data.prices;

        const cryptoEquivalents = {
          BTC: (selectedPlan / prices.BTC).toFixed(8),
          ETH: (selectedPlan / prices.ETH).toFixed(8),
          DOGE: (selectedPlan / prices.DOGE).toFixed(2)
        };

        setCryptoAmount(cryptoEquivalents[selectedCrypto]);
        setUsdAmount(`$${selectedPlan}.00`);
      } catch (error) {
        console.error('Error fetching crypto prices:', error);
        setCryptoAmount('N/A');
      }
    };

    fetchCryptoPrices();
  }, [selectedPlan, selectedCrypto]);

  const handlePlanSelect = (price, planType) => {
    setSelectedPlan(price);
    setSelectedPlanType(planType || 'annual');
  };

  const handleCryptoSelect = (crypto) => {
    setSelectedCrypto(crypto);
  };

  const handleCryptoAmountChange = (event) => {
    setCryptoAmount(event.target.value);
  };

  const handleIncrement = () => {
    setCryptoAmount((prevAmount) => {
      const newAmount = (parseFloat(prevAmount) + 0.00001).toFixed(8);
      return newAmount;
    });
  };

  const handleDecrement = () => {
    setCryptoAmount((prevAmount) => {
      const newAmount = Math.max(parseFloat(prevAmount) - 0.00001, 0).toFixed(8);
      return newAmount;
    });
  };

  const handleConfirmChange = (event) => {
    setConfirmChecked(event.target.checked);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(cryptoAddresses[selectedCrypto]);
    alert('Crypto Address Copied!');
  };

  const handleSubmit = async () => {
    // Free subscription
    if (selectedPlan === 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        alert('Please enter a valid email address.');
        return;
      }

      try {
        await axios.post('/api/free-subscription', {
          email
        });

        alert('Thank you for subscribing! You will receive a confirmation email shortly.');
        onClose();
      } catch (error) {
        console.error('Error registering free subscription:', error);
        alert('Failed to register. Please try again.');
      }
      return;
    }
    
    // Paid subscription
    if (!confirmChecked) {
      alert('Please confirm the payment before proceeding.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    try {
      await axios.post('/api/payment-notification', {
        email,
        cryptoAmount,
        selectedCrypto,
        usdAmount: selectedPlan,
        planType: selectedPlanType
      });

      alert('Receipt sent! Thank you for your payment.');
      onClose();
    } catch (error) {
      console.error('Error processing payment:', error);
      alert('Failed to process payment. Please try again.');
    }
  };

  return (
    <>
      <Overlay onClick={onClose} />
      <PopupContainer>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>

        <RetroTitle sx={{ fontSize: '1.4rem' }}>Subscribe</RetroTitle>

        {/* Subscription Options */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            mb: 3,
          }}
        >
          <SubscriptionOption
            price={0}
            description="Basic Access"
            selectedPlan={selectedPlan}
            onSelect={handlePlanSelect}
            planType="free"
          />
          <SubscriptionOption
            price={5}
            description="Premium Access"
            selectedPlan={selectedPlan}
            onSelect={handlePlanSelect}
            planType="monthly"
          />
          <SubscriptionOption
            price={15}
            description="Best Value"
            selectedPlan={selectedPlan}
            onSelect={handlePlanSelect}
            planType="annual"
          />
        </Box>

        {/* For free plan, only show email field */}
        {selectedPlan === 0 ? (
          <>

            
            <TextField
              label="Your Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                readOnly: !!user?.email,
              }}
              fullWidth
              sx={{
                backgroundColor: '#283593',
                borderRadius: '5px',
                marginBottom: '20px',
                input: {
                  color: '#FFFFFF',
                  fontFamily: "'Press Start 2P', cursive",
                },
                label: {
                  color: '#FFFFFF',
                },
                '& .Mui-disabled': {
                  color: '#FFFFFF',
                  WebkitTextFillColor: '#FFFFFF',
                },
              }}
            />
            
            <Button
              onClick={handleSubmit}
              sx={{
                backgroundColor: '#00BFFF',
                color: '#FFFFFF',
                fontFamily: "'Press Start 2P', cursive",
                textTransform: 'none',
                '&:hover': { backgroundColor: '#00BFFF' },
                marginTop: '15px',
                padding: '10px',
                fontSize: {
                  xs: '0.8rem',
                  sm: '1rem'
                }
              }}
              fullWidth
            >
              Subscribe for Free
            </Button>
          </>
        ) : (
          <>
            {/* Payment options for paid plans */}


            {/* QR Code Section */}
            <QRCodeCanvas
              value={cryptoAddresses[selectedCrypto]}
              size={150}
              style={{ margin: '10px auto', maxWidth: '100%', height: 'auto' }}
            />

            {/* Crypto Address */}
            <CopyField
              value={cryptoAddresses[selectedCrypto]}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <ContentCopyIcon
                      onClick={handleCopyToClipboard}
                      sx={{ cursor: 'pointer', color: '#FFFFFF' }}
                    />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{ mb: 2 }}
            />

            {/* Crypto Selection */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip title="Bitcoin">
                  <Typography
                    onClick={() => handleCryptoSelect('BTC')}
                    sx={{
                      color: selectedCrypto === 'BTC' ? '#FFFFFF' : '#00BFFF',
                      cursor: 'pointer',
                      fontWeight: selectedCrypto === 'BTC' ? 'bold' : 'normal',
                    }}
                  >
                    BTC
                  </Typography>
                </Tooltip>
                <Typography sx={{ color: '#FFFFFF' }}>|</Typography>
                <Tooltip title="Ethereum">
                  <Typography
                    onClick={() => handleCryptoSelect('ETH')}
                    sx={{
                      color: selectedCrypto === 'ETH' ? '#FFFFFF' : '#00BFFF',
                      cursor: 'pointer',
                      fontWeight: selectedCrypto === 'ETH' ? 'bold' : 'normal',
                    }}
                  >
                    ETH
                  </Typography>
                </Tooltip>
                <Typography sx={{ color: '#FFFFFF' }}>|</Typography>
                <Tooltip title="Dogecoin">
                  <Typography
                    onClick={() => handleCryptoSelect('DOGE')}
                    sx={{
                      color: selectedCrypto === 'DOGE' ? '#FFFFFF' : '#00BFFF',
                      cursor: 'pointer',
                      fontWeight: selectedCrypto === 'DOGE' ? 'bold' : 'normal',
                    }}
                  >
                    DOGE
                  </Typography>
                </Tooltip>
              </Box>
            </Box>

            {/* Crypto Amount Controls */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              sx={{
                gap: '8px',
                '@media (max-width: 600px)': {
                  flexDirection: 'row',
                  alignItems: 'center'
                }
              }}
            >
              <IconButton
                onClick={handleDecrement}
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#283593',
                  borderRadius: '5px',
                  padding: '8px'
                }}
              >
                <RemoveIcon />
              </IconButton>
              <CopyField
                value={cryptoAmount}
                onChange={handleCryptoAmountChange}
                fullWidth
                sx={{ textAlign: 'center' }}
              />
              <IconButton
                onClick={handleIncrement}
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#283593',
                  borderRadius: '5px',
                  padding: '8px'
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>

            {/* Payment Summary */}
            <Box
              sx={{
                backgroundColor: 'rgba(0, 191, 255, 0.1)',
                borderRadius: '8px',
                padding: '15px',
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '0.9rem',
                  textAlign: 'center',
                  mb: 1,
                }}
              >
                {cryptoAmount} {selectedCrypto}
              </Typography>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '0.9rem',
                  textAlign: 'center',
                }}
              >
                For {selectedPlanType === 'monthly' ? 'Monthly' : 'Annual'} Premium Access (${selectedPlan}.00)
              </Typography>
            </Box>

            {/* Confirmation */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmChecked}
                  onChange={handleConfirmChange}
                  sx={{
                    color: '#00BFFF',
                    '&.Mui-checked': {
                      color: '#00BFFF',
                    },
                  }}
                />
              }
              label="I confirm this payment"
              sx={{
                color: '#FFFFFF',
                fontSize: '0.9rem',
                alignSelf: 'center',
                mb: 2,
              }}
            />

            {/* Email Field */}
            <TextField
              label="Email for Receipt"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                readOnly: !!user?.email,
              }}
              fullWidth
              sx={{
                backgroundColor: '#283593',
                borderRadius: '5px',
                mb: 2,
                input: {
                  color: '#FFFFFF',
                  fontFamily: "'Press Start 2P', cursive",
                },
                label: {
                  color: '#FFFFFF',
                },
                '& .Mui-disabled': {
                  color: '#FFFFFF',
                  WebkitTextFillColor: '#FFFFFF',
                },
              }}
            />

            <SummaryText>
              You confirm this 1 time payment, <span style={{ textDecoration: 'underline' }}>with no auto pay enabled or available</span>, for {selectedPlanType === 'monthly' ? 'monthly' : '1 year annual'} subscription to SatoshiAdvance.com in exchange for {cryptoAmount} {selectedCrypto}, approximately {usdAmount} USD as of 10.24.2024.
            </SummaryText>

            {/* Purchase Button */}
            <Button
              onClick={handleSubmit}
              disabled={!confirmChecked}
              sx={{
                backgroundColor: confirmChecked
                  ? '#00BFFF'
                  : 'rgba(0, 191, 255, 0.3)',
                color: '#FFFFFF',
                fontFamily: "'Press Start 2P', cursive",
                fontSize: '0.9rem',
                padding: '10px 20px',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: confirmChecked
                    ? '#00A0E0'
                    : 'rgba(0, 191, 255, 0.3)',
                },
                textTransform: 'none',
                width: '100%',
              }}
            >
              Complete & Send Receipt
            </Button>
          </>
        )}
      </PopupContainer>
    </>
  );
};

export default PaymentPopup;