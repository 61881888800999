import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { Tooltip } from '@mui/material';

// Import Material UI icons (feel free to add more if needed)
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import TvIcon from '@mui/icons-material/Tv';
import HomeIcon from '@mui/icons-material/Home';
import WeekendIcon from '@mui/icons-material/Weekend';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import FlightIcon from '@mui/icons-material/Flight';
import TrainIcon from '@mui/icons-material/Train';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import SpaIcon from '@mui/icons-material/Spa';
import DiamondIcon from '@mui/icons-material/Diamond';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';


// Create a styled RetroTextField for our retro look
const RetroTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '1rem',
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#283593',
    borderRadius: '5px',
    border: '2px solid #FFFFFF',
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      fontFamily: "'Press Start 2P', cursive",
      color: '#FFFFFF',
      padding: '10px 12px',
    },
    '& input::placeholder': {
      color: '#ccc',
    },
    '&:hover': {
      boxShadow: '0 0 10px #9932CC',
    },
    '&.Mui-focused': {
      boxShadow: '0 0 10px #BA55D3',
    },
  },
}));

// Styled container with retro glowing style
const AnimatedGlowBox = styled(motion(Box))(({ theme }) => ({
  maxWidth: '600px',
  padding: '30px',
  margin: '20px auto',
  backgroundColor: '#283593',
  borderRadius: '10px',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    maxWidth: '90%',
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '2rem',
  marginBottom: '20px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

/**
 * Below is an expanded typicalProducts array with synonyms and variations.
 * In production, you’d keep adding until you have ~500 items covering
 * as many user queries as you expect.
 */
const typicalProducts = [
  // FOOD & GROCERIES
  { product: 'food', category: 'food' },
  { product: 'groceries', category: 'food' },
  { product: 'meal', category: 'food' },
  { product: 'cuisine', category: 'food' },
  { product: 'edibles', category: 'food' },
  { product: 'produce', category: 'food' },
  { product: 'snacks', category: 'food' },
  { product: 'chips', category: 'food' },
  { product: 'cookies', category: 'food' },
  { product: 'candy', category: 'food' },
  { product: 'chocolate', category: 'food' },
  { product: 'milk', category: 'food' },
  { product: 'milk products', category: 'food' },
  { product: 'bread', category: 'food' },
  { product: 'cheese', category: 'food' },
  { product: 'chicken', category: 'food' },
  { product: 'pork', category: 'food' },
  { product: 'beef', category: 'food' },
  { product: 'seafood', category: 'food' },
  { product: 'fish', category: 'food' },
  { product: 'rice', category: 'food' },
  { product: 'pasta', category: 'food' },
  { product: 'apples', category: 'food' },
  { product: 'oranges', category: 'food' },
  { product: 'carrots', category: 'food' },
  { product: 'vegetables', category: 'food' },
  { product: 'fruits', category: 'food' },
  { product: 'cereal', category: 'food' },
  { product: 'eggs', category: 'food' },
  { product: 'liquor', category: 'food' },
  { product: 'beer', category: 'food' },

  // MOVIES / ENTERTAINMENT
  { product: 'movies', category: 'entertainment' },
  { product: 'films', category: 'entertainment' },
  { product: 'cinema', category: 'entertainment' },
  { product: 'dvd', category: 'entertainment' },
  { product: 'blu-ray', category: 'entertainment' },
  { product: 'comedy', category: 'entertainment' },
  { product: 'horror', category: 'entertainment' },
  { product: 'romance', category: 'entertainment' },
  { product: 'thriller', category: 'entertainment' },
  { product: 'drama', category: 'entertainment' },
  { product: 'music', category: 'entertainment' },

  // CLOTHING / FASHION
  { product: 'clothing', category: 'fashion' },
  { product: 'apparel', category: 'fashion' },
  { product: 'garments', category: 'fashion' },
  { product: 'footwear', category: 'fashion' },
  { product: 'shoes', category: 'fashion' },
  { product: 'boots', category: 'fashion' },
  { product: 'heels', category: 'fashion' },
  { product: 'dress', category: 'fashion' },
  { product: 'dresses', category: 'fashion' },
  { product: 'purse', category: 'fashion' },
  { product: 'handbag', category: 'fashion' },
  { product: 'bag', category: 'fashion' },
  { product: 'jewelry', category: 'fashion' },
  { product: 'necklaces', category: 'fashion' },
  { product: 'rings', category: 'fashion' },
  { product: 'bracelets', category: 'fashion' },
  { product: 'silver ring', category: 'fashion' },
  { product: 'diamond ring', category: 'fashion' },
  { product: 't-shirts', category: 'fashion' },
  { product: 'jeans', category: 'fashion' },
  { product: 'sneakers', category: 'fashion' },
  { product: 'watches', category: 'watch' }, // Note: watch category
  { product: 'sunglasses', category: 'fashion' },

  // AUTOMOTIVE
  { product: 'cars', category: 'automotive' },
  { product: 'vehicles', category: 'automotive' },
  { product: 'automobiles', category: 'automotive' },
  { product: 'auto', category: 'automotive' },
  { product: 'car parts', category: 'automotive' },
  { product: 'tires', category: 'automotive' },
  { product: 'engine oil', category: 'automotive' },
  { product: 'spark plugs', category: 'automotive' },
  { product: 'shock absorbers', category: 'automotive' },
  { product: 'battery', category: 'automotive' },
  { product: 'bicycles', category: 'automotive' },
  { product: 'motorcycles', category: 'automotive' },
  { product: 'helmets', category: 'automotive' },

  // ELECTRONICS
  { product: 'electronics', category: 'electronics' },
  { product: 'phones', category: 'electronics' },
  { product: 'smartphones', category: 'electronics' },
  { product: 'mobile phones', category: 'electronics' },
  { product: 'pc', category: 'electronics' },
  { product: 'laptop', category: 'electronics' },
  { product: 'laptops', category: 'electronics' },
  { product: 'desktops', category: 'electronics' },
  { product: 'tablets', category: 'electronics' },
  { product: 'computer', category: 'electronics' },
  { product: 'monitor', category: 'electronics' },
  { product: 'tv', category: 'electronics' },
  { product: 'television', category: 'electronics' },
  { product: 'headphones', category: 'electronics' },
  { product: 'speakers', category: 'electronics' },
  { product: 'earbuds', category: 'electronics' },
  { product: 'bluetooth headphones', category: 'electronics' },
  { product: 'camera', category: 'electronics' },
  { product: 'cameras', category: 'electronics' },
  { product: 'dslr', category: 'electronics' },
  { product: 'mirrorless camera', category: 'electronics' },
  { product: 'phone chargers', category: 'electronics' },
  { product: 'laptop chargers', category: 'electronics' },

  // GAMING
  { product: 'gaming', category: 'gaming' },
  { product: 'games', category: 'gaming' },
  { product: 'video games', category: 'gaming' },
  { product: 'steam keys', category: 'gaming' },
  { product: 'ps5', category: 'gaming' },
  { product: 'nintendo switch', category: 'gaming' },
  { product: 'xbox', category: 'gaming' },
  { product: 'consoles', category: 'gaming' },
  { product: 'game discs', category: 'gaming' },

  // SOFTWARE
  { product: 'software', category: 'software' },
  { product: 'apps', category: 'software' },
  { product: 'vpn', category: 'software' },
  { product: 'antivirus', category: 'software' },
  { product: 'office suite', category: 'software' },
  { product: 'operating system', category: 'software' },
  { product: 'vpn subscriptions', category: 'software' },

  // INTERNET SERVICES
  { product: 'internet services', category: 'internetServices' },
  { product: 'hosting', category: 'internetServices' },
  { product: 'domains', category: 'internetServices' },
  { product: 'domain names', category: 'internetServices' },
  { product: 'domain registration', category: 'internetServices' },

  // TELECOM
  { product: 'telecom', category: 'telecom' },
  { product: 'phone plan', category: 'telecom' },
  { product: 'mobile plan', category: 'telecom' },
  { product: 'internet plan', category: 'telecom' },
  { product: 'telecommunications', category: 'telecom' },
  { product: 'landline', category: 'telecom' },

  // FURNITURE
  { product: 'furniture', category: 'furniture' },
  { product: 'sofa', category: 'furniture' },
  { product: 'couch', category: 'furniture' },
  { product: 'bed', category: 'furniture' },
  { product: 'desk', category: 'furniture' },
  { product: 'wardrobe', category: 'furniture' },
  { product: 'dresser', category: 'furniture' },
  { product: 'mirror', category: 'furniture' },
  { product: 'nightstand', category: 'furniture' },
  { product: 'tables', category: 'furniture' },
  { product: 'chairs', category: 'furniture' },
  { product: 'lamps', category: 'furniture' },
  { product: 'lighting', category: 'furniture' },
  { product: 'beds', category: 'furniture' },
  { product: 'sofas', category: 'furniture' },

  // ART
  { product: 'art', category: 'art' },
  { product: 'paintings', category: 'art' },
  { product: 'sculptures', category: 'art' },
  { product: 'drawings', category: 'art' },
  { product: 'photography', category: 'art' },
  { product: 'print', category: 'art' },
  { product: 'canvas', category: 'art' },
  { product: 'antiques', category: 'art' },

  // SPECIALTY
  { product: 'wine', category: 'specialty' },
  { product: 'crystals', category: 'specialty' },
  { product: 'pet supplies', category: 'specialty' },
  { product: 'cannabis seeds', category: 'specialty' },
  { product: 'outdoors', category: 'specialty' },
  { product: 'sports', category: 'entertainment' }, // or specialty
  { product: 'petlove', category: 'specialty' },
  // Already in your mapping: seedsman, vapePenStore, etc.

  // NFT
  { product: 'nft', category: 'nft' },
  { product: 'digital art', category: 'nft' },
  { product: 'digital collectibles', category: 'nft' },
  { product: 'rare digital items', category: 'nft' },

  // GIFTCARDS
  { product: 'gift cards', category: 'giftcards' },
  { product: 'vouchers', category: 'giftcards' },
  { product: 'steam gift cards', category: 'giftcards' },
  { product: 'amazon gift cards', category: 'giftcards' },
  { product: 'google play gift cards', category: 'giftcards' },
  { product: 'itunes gift cards', category: 'giftcards' },

  // EDUCATION
  { product: 'books', category: 'education' },
  { product: 'magazines', category: 'education' },
  { product: 'journals', category: 'education' },
  { product: 'school', category: 'education' },
  { product: 'university', category: 'education' },
  { product: 'college', category: 'education' },
  { product: 'course', category: 'education' },
  { product: 'class', category: 'education' },

  // TRAVEL
  { product: 'travel', category: 'travel' },
  { product: 'trip', category: 'travel' },
  { product: 'vacation', category: 'travel' },
  { product: 'flight', category: 'travel' },
  { product: 'hotel', category: 'travel' },
  { product: 'train', category: 'travel' },
  { product: 'bus', category: 'travel' },

  // PROFESSIONAL
  { product: 'professional', category: 'professional' },
  { product: 'services', category: 'professional' },
  { product: 'consulting', category: 'professional' },
  { product: 'legal', category: 'professional' },
  { product: 'accounting', category: 'professional' },

  // FALLBACK EXAMPLES
  { product: 'misc', category: 'retail' },
  { product: 'stuff', category: 'retail' },
];

/**
 * Our retailer suggestions mapping (from your CSV data and previous mappings).
 * Each key corresponds to a category, which we'll look up from typicalProducts.
 */
const suggestionsMapping = {
  travel: [
    { icon: <FlightIcon fontSize="large" />, label: 'Flight Deals', url: 'https://flightcrypto.example.com' },
    { icon: <TrainIcon fontSize="large" />, label: 'Train Tickets', url: 'https://traincrypto.example.com' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'Travel Agencies', url: 'https://travelagency.example.com' },
  ],
  videogames: [
    { icon: <SportsEsportsIcon fontSize="large" />, label: 'Video Game Shop', url: 'https://videogamescrypto.example.com' },
    { icon: <SportsEsportsIcon fontSize="large" />, label: 'Esports Arena', url: 'https://esportscrypto.example.com' },
  ],
  automotive: [
    { icon: <DirectionsCarIcon fontSize="large" />, label: 'AutocoinCar', url: 'https://autocoincar.com' },
    { icon: <DirectionsCarIcon fontSize="large" />, label: 'CarWow', url: 'https://carwow.de' },
    { icon: <DirectionsCarIcon fontSize="large" />, label: 'AutoScout24', url: 'https://autoscout24.com' },
    { icon: <DirectionsCarIcon fontSize="large" />, label: 'Kavak', url: 'https://kavak.com' },
    { icon: <DirectionsCarIcon fontSize="large" />, label: 'BMW USA', url: 'https://bmwusa.com' },
  ],
  electronics: [
    { icon: <LaptopMacIcon fontSize="large" />, label: 'Newegg', url: 'https://newegg.com' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'Microsoft', url: 'https://microsoft.com' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'Fasttech', url: 'https://fasttech.com' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'Scan UK', url: 'https://scan.co.uk' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'Alternate', url: 'https://alternate.de' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'Alza', url: 'https://alza.cz' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'Coolmod', url: 'https://coolmod.com' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'PC Componentes', url: 'https://pccomponentes.com' },
  ],
  retail: [
    { icon: <StorefrontIcon fontSize="large" />, label: 'Overstock', url: 'https://overstock.com' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'Shopinbit', url: 'https://shopinbit.com' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'Gipsybee', url: 'https://gipsybee.com' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'Galaxus', url: 'https://galaxus.ch' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'Fancy', url: 'https://fancy.com' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'MercadoLibre', url: 'https://mercadolibre.com' },
  ],
  travelRetail: [
    { icon: <FlightIcon fontSize="large" />, label: 'Travala', url: 'https://travala.com' },
    { icon: <FlightIcon fontSize="large" />, label: 'CheapAir', url: 'https://cheapair.com' },
    { icon: <FlightIcon fontSize="large" />, label: 'AirBaltic', url: 'https://airbaltic.com' },
    { icon: <FlightIcon fontSize="large" />, label: 'Latam', url: 'https://latam.com' },
    { icon: <FlightIcon fontSize="large" />, label: 'Alternative Airlines', url: 'https://alternativeairlines.com' },
  ],
  fashion: [
    { icon: <LocalMallIcon fontSize="large" />, label: 'LuisaViaRoma', url: 'https://luisaviaroma.com' },
    { icon: <LocalMallIcon fontSize="large" />, label: 'Farfetch', url: 'https://farfetch.com' },
    { icon: <LocalMallIcon fontSize="large" />, label: 'Dafiti', url: 'https://dafiti.com.br' },
    { icon: <LocalMallIcon fontSize="large" />, label: 'BitDials', url: 'https://bitdials.eu' },
    { icon: <LocalMallIcon fontSize="large" />, label: 'Jomashop', url: 'https://jomashop.com' },
  ],
  watch: [
    { icon: <LocalMallIcon fontSize="large" />, label: 'Jomashop', url: 'https://jomashop.com' },
    { icon: <LocalMallIcon fontSize="large" />, label: 'BitDials', url: 'https://bitdials.eu' },
  ],
  internetServices: [
    { icon: <ComputerIcon fontSize="large" />, label: 'Namecheap', url: 'https://namecheap.com' },
    { icon: <ComputerIcon fontSize="large" />, label: 'WordPress', url: 'https://wordpress.com' },
    { icon: <ComputerIcon fontSize="large" />, label: 'Hostinger', url: 'https://hostinger.com' },
    { icon: <ComputerIcon fontSize="large" />, label: 'InternetBS', url: 'https://internetbs.net' },
  ],
  software: [
    { icon: <LaptopMacIcon fontSize="large" />, label: 'ExpressVPN', url: 'https://expressvpn.com' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'NordVPN', url: 'https://nordvpn.com' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'Private Internet Access', url: 'https://privateinternetaccess.com' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'Threema', url: 'https://threema.ch' },
    { icon: <LaptopMacIcon fontSize="large" />, label: 'Billwerk', url: 'https://billwerk.com' },
  ],
  gaming: [
    { icon: <SportsEsportsIcon fontSize="large" />, label: 'PlayAsia', url: 'https://playasia.com' },
    { icon: <SportsEsportsIcon fontSize="large" />, label: 'Keys4Coins', url: 'https://keys4coins.com' },
    { icon: <SportsEsportsIcon fontSize="large" />, label: 'GameTimeZone', url: 'https://gametimezone.com' },
    { icon: <SportsEsportsIcon fontSize="large" />, label: 'LandsOfCrypto', url: 'https://landsofcrypto.com' },
  ],
  entertainment: [
    { icon: <TvIcon fontSize="large" />, label: 'Ticketmaster', url: 'https://ticketmaster.es' },
    { icon: <TvIcon fontSize="large" />, label: 'Entradium', url: 'https://entradium.com' },
    { icon: <TvIcon fontSize="large" />, label: 'Cinepolis', url: 'https://cinepolis.com' },
    { icon: <TvIcon fontSize="large" />, label: 'Teleticket', url: 'https://teleticket.com.pe' },
  ],
  food: [
    { icon: <RestaurantIcon fontSize="large" />, label: 'Menufy', url: 'https://menufy.com' },
    { icon: <RestaurantIcon fontSize="large" />, label: 'Heineken', url: 'https://heineken.com' },
    { icon: <RestaurantIcon fontSize="large" />, label: 'Deliveroo', url: 'https://deliveroo.es' },
    { icon: <RestaurantIcon fontSize="large" />, label: 'Glovo', url: 'https://glovo.com' },
    { icon: <RestaurantIcon fontSize="large" />, label: 'Rappi', url: 'https://rappi.com' },
    { icon: <RestaurantIcon fontSize="large" />, label: 'iFood', url: 'https://ifood.com.br' },
    { icon: <RestaurantIcon fontSize="large" />, label: 'PexPeppers', url: 'https://pexpeppers.com' },
  ],
  telecom: [
    { icon: <PhoneIphoneIcon fontSize="large" />, label: 'AT&T', url: 'https://att.com' },
    { icon: <PhoneIphoneIcon fontSize="large" />, label: 'Movistar', url: 'https://movistar.com' },
    { icon: <PhoneIphoneIcon fontSize="large" />, label: 'Orange', url: 'https://orange.es' },
    { icon: <PhoneIphoneIcon fontSize="large" />, label: 'Claro', url: 'https://claro.com.ar' },
    { icon: <PhoneIphoneIcon fontSize="large" />, label: 'Entel', url: 'https://entel.cl' },
  ],
  education: [
    { icon: <MenuBookIcon fontSize="large" />, label: 'iversity', url: 'https://iversity.org' },
    { icon: <MenuBookIcon fontSize="large" />, label: 'OpenLearning', url: 'https://openlearning.com' },
    { icon: <MenuBookIcon fontSize="large" />, label: 'UTEC', url: 'https://utec.edu.uy' },
    { icon: <MenuBookIcon fontSize="large" />, label: 'Uniandes', url: 'https://uniandes.edu.co' },
  ],
  professional: [
    { icon: <StorefrontIcon fontSize="large" />, label: 'LetsLaw', url: 'https://letslaw.es' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'ContadorContigo', url: 'https://contadorcontigo.cl' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'Biscred', url: 'https://biscred.com.br' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'ConsulThink', url: 'https://consulthink.it' },
  ],
  art: [
    { icon: <DiamondIcon fontSize="large" />, label: 'Artsy', url: 'https://artsy.net' },
    { icon: <DiamondIcon fontSize="large" />, label: 'Catawiki', url: 'https://catawiki.com' },
    { icon: <DiamondIcon fontSize="large" />, label: 'Subasta', url: 'https://subasta.com' },
    { icon: <DiamondIcon fontSize="large" />, label: 'Dorotheum', url: 'https://dorotheum.com' },
  ],
  furniture: [
    { icon: <WeekendIcon fontSize="large" />, label: 'Miliboo', url: 'https://miliboo.com' },
    { icon: <WeekendIcon fontSize="large" />, label: 'Kave Home', url: 'https://kave-home.com' },
    { icon: <WeekendIcon fontSize="large" />, label: 'MadeiraMadeira', url: 'https://madeiramadeira.com.br' },
    { icon: <WeekendIcon fontSize="large" />, label: 'Sodimac', url: 'https://sodimac.cl' },
  ],
  specialty: [
    { icon: <StorefrontIcon fontSize="large" />, label: 'Bodeboca', url: 'https://bodeboca.com' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'Vinoseleccion', url: 'https://vinoseleccion.com' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'Naturitas', url: 'https://naturitas.es' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'FarmaciasDirect', url: 'https://farmaciasdirect.com' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'GroovyTek', url: 'https://groovytek.com.br' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'PetLove', url: 'https://petlove.com.br' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'VapePenStore', url: 'https://vapepenstore.com' },
    { icon: <StorefrontIcon fontSize="large" />, label: 'Seedsman', url: 'https://seedsman.com' },
  ],
  nft: [
    { icon: <DiamondIcon fontSize="large" />, label: 'OpenSea', url: 'https://opensea.io' },
    { icon: <DiamondIcon fontSize="large" />, label: 'Blur', url: 'https://blur.io' },
    { icon: <DiamondIcon fontSize="large" />, label: 'Rarible', url: 'https://rarible.com' },
    { icon: <DiamondIcon fontSize="large" />, label: 'NiftyGateway', url: 'https://niftygateway.com' },
    { icon: <DiamondIcon fontSize="large" />, label: 'Foundation', url: 'https://foundation.app' },
  ],
  giftcards: [
    { icon: <CreditCardIcon fontSize="large" />, label: 'eGifter', url: 'https://eGifter.com' },
    { icon: <CreditCardIcon fontSize="large" />, label: 'Bitrefill', url: 'https://bitrefill.com' },
    { icon: <CreditCardIcon fontSize="large" />, label: 'CoinCards', url: 'https://coincards.com' },
  ],
};

/**
 * Finds a product's category from the typicalProducts array
 * based on user input (synonyms). If found, returns that category;
 * otherwise returns null.
 */
const findCategoryForQuery = (q) => {
  const lowerQuery = q.toLowerCase();
  const matchingProducts = typicalProducts.filter(item =>
    item.product.toLowerCase().includes(lowerQuery) ||
    lowerQuery.includes(item.product.toLowerCase())
  );
  if (matchingProducts.length > 0) {
    return matchingProducts[0].category;
  }
  return null;
};

const CryptoPurchaseFinder = () => {
  const [query, setQuery] = useState('');
  const [matches, setMatches] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    // 1) Try to find an exact category from typicalProducts
    const category = findCategoryForQuery(value);
    if (category) {
      const suggestions = suggestionsMapping[category] || [];
      setMatches(suggestions);
    } else {
      // 2) Otherwise, fallback: check if the query includes any key from suggestionsMapping
      const newMatches = [];
      const lowerVal = value.toLowerCase();
      Object.keys(suggestionsMapping).forEach((key) => {
        if (lowerVal.includes(key)) {
          newMatches.push(...suggestionsMapping[key]);
        }
      });
      // Remove duplicates by URL
      const uniqueMatches = Array.from(new Map(newMatches.map(item => [item.url, item])).values());
      setMatches(uniqueMatches);
    }
  };

  return (
    <AnimatedGlowBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, boxShadow: '0 0 20px #9932CC, 0 0 30px #9932CC, 0 0 40px #9932CC' }}
      whileHover={{ boxShadow: '0 0 40px #BA55D3, 0 0 60px #BA55D3, 0 0 80px #BA55D3' }}
      transition={{ duration: 1.5, ease: 'easeInOut' }}
    >
      <TitleText>Shop with Bitcoin & Crypto Online</TitleText>
      <RetroTextField
        value={query}
        onChange={handleInputChange}
        placeholder="Type a product (e.g., Food, Movies, Clothing, Tires)"
        variant="outlined"
        fullWidth
      />
      {matches.length > 0 && (
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "'Press Start 2P', cursive",
              color: '#FFFFFF',
              mb: 2,
              textShadow: `
                -1px -1px 0 #000,
                1px -1px 0 #000,
                -1px 1px 0 #000,
                1px 1px 0 #000
              `,
            }}
          >
            Compare & Save:
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0.5} // small gap ~4px
            flexWrap="nowrap"
          >
            {matches.slice(0, 4).map((suggestion, index) => {
              // Extract domain from the URL (e.g. "amazon.com")
              const domain = new URL(suggestion.url).hostname;

              return (
                <Tooltip
                  key={index}
                  title={domain}
                  slotProps={{
                    tooltip: {
                      sx: {
                        fontSize: '1.4rem',    // 3× bigger
                        whiteSpace: 'normal',  // allow wrapping
                        maxWidth: 300,         // prevent it from getting too wide
                      },
                    },
                  }}
                >
                  <Button
                    href={suggestion.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="contained"
                    sx={{
                      width: 80,
                      height: 80,
                      minWidth: 80,
                      minHeight: 80,
                      backgroundColor: 'rgba(186,85,211,0.7)',
                      fontFamily: "'Press Start 2P', cursive",
                      textTransform: 'none',
                      boxShadow: '0 0 10px #9932CC',
                      '&:hover': { backgroundColor: 'rgba(153,50,204,0.7)' },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      p: 0,
                    }}
                  >
                    {suggestion.icon}
                    <Typography
                      sx={{
                        color: '#fff',
                        fontSize: '0.6rem',
                        mt: 0.5,
                        textAlign: 'center',
                      }}
                    >
                      {suggestion.label}
                    </Typography>
                  </Button>
                </Tooltip>


              );
            })}
          </Stack>


        </Box>
      )}
    </AnimatedGlowBox>
  );
};

export default CryptoPurchaseFinder;
