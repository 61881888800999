// src/components/InfoCenter.js
import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Home, X, Mail, ChevronDown, Menu } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import AdBanner from './AdBanner';
import BlogPost from './BlogPost';
import { blogPostData, loanCompanyPosts } from './BlogPostData';


// 1) Import your popups
import AuthPopup from './AuthPopup';
import PaymentPopup from './PaymentPopup';

const MEDIA_BASE_URL = 'https://media.satoshiadvance.com/images';

function InfoCenter() {
  const { articleSlug } = useParams();
  const navigate = useNavigate();
  
  // Desktop vs. mobile
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  // 2) Popup states
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [amount, setAmount] = useState(50); // Default to $50 (annual plan)

  // Check if article is in blogPostData or loanCompanyPosts
  const isInBlogPosts = articleSlug && blogPostData[articleSlug];
  const isInLoanPosts = articleSlug && loanCompanyPosts && loanCompanyPosts[articleSlug];

  // Currently selected article, or null
  const currentArticle = isInBlogPosts
    ? blogPostData[articleSlug]
    : isInLoanPosts
      ? loanCompanyPosts[articleSlug]
      : null;

  // Default tab state
  const [selectedOption, setSelectedOption] = useState(() => {
    if (isInLoanPosts) return 'Top Loans';
    if (isInBlogPosts) return 'News & Blogs';
    return 'News & Blogs';
  });

  // Current date for "Read Next" etc.
  const currentDate = new Date().toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  // Handle articleSlug changes
  useEffect(() => {
    if (articleSlug) {
      window.scrollTo({ top: 0, behavior: 'auto' });
      if (isInLoanPosts) {
        setSelectedOption('Top Loans');
      } else if (isInBlogPosts) {
        setSelectedOption('News & Blogs');
      }
    }
  }, [articleSlug, isInLoanPosts, isInBlogPosts]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Convert blog data to arrays for rendering
  const newsAndBlogs = Object.values(blogPostData).map(post => ({
    id: post.id,
    title: post.title,
    date: post.date,
    tagline: post.category,
    quote: post.mainQuote,
    slug: post.slug,
    image: post.headerImage,
    isBlogPost: true
  }));

  const loanCompanyCards = loanCompanyPosts
    ? Object.values(loanCompanyPosts).map(post => ({
        id: post.id,
        title: post.title,
        date: post.date,
        tagline: post.category,
        quote: post.mainQuote,
        slug: post.slug,
        image: post.headerImage,
        isBlogPost: true,
        isLoanPost: true
      }))
    : [];

  const sections = {
    'News & Blogs': newsAndBlogs,
    'Top Loans': loanCompanyCards.length > 0
      ? loanCompanyCards
      : [
          {
            image: `${MEDIA_BASE_URL}/RetroLoans1.png`,
            title: 'Best Lending Platforms',
            quote: 'Compare and choose the right platform for your needs.',
            content: 'Discover top-rated cryptocurrency lending platforms...'
          },
          {
            image: `${MEDIA_BASE_URL}/RetroLoans2.png`,
            title: 'Featured Loan Opportunities',
            quote: 'Maximize your crypto lending potential.',
            content: 'Explore current lending opportunities across various platforms.'
          }
        ],
    'Top Links': [
      {
        image: `${MEDIA_BASE_URL}/RetroLinks1.png`,
        title: 'Essential Resources',
        quote: 'Your gateway to crypto lending excellence.',
        content: 'Access our carefully selected collection of links...'
      },
      {
        image: `${MEDIA_BASE_URL}/RetroLinks2.png`,
        title: 'Community Favorites',
        quote: 'Trusted by the crypto community.',
        content: 'Discover community-recommended platforms and tools.'
      }
    ],
    'Resources': [
      {
        image: `${MEDIA_BASE_URL}/RetroResources1.png`,
        title: 'Educational Materials',
        quote: 'Knowledge is your greatest asset.',
        content: 'Access comprehensive guides, tutorials, and educational resources.'
      },
      {
        image: `${MEDIA_BASE_URL}/RetroResources2.png`,
        title: 'Tools & Calculators',
        quote: 'Make informed decisions with powerful tools.',
        content: 'Utilize our suite of tools and calculators.'
      }
    ]
  };

  // Filter the content based on the articleSlug
  const filteredContent = () => {
    if (!articleSlug) {
      if (selectedOption === 'Top Loans') {
        return [];
      }
      return sections[selectedOption];
    }
    if (selectedOption === 'News & Blogs' && isInBlogPosts) {
      return sections['News & Blogs'].filter(item => item.slug !== articleSlug);
    } else if (selectedOption === 'Top Loans' && isInLoanPosts) {
      return sections['Top Loans'].filter(item => item.slug !== articleSlug);
    } else {
      return sections[selectedOption];
    }
  };

  const getOrderedLoanPosts = () => {
    if (!loanCompanyPosts) return [];
    const orderedSlugs = ['gemini-exchange', 'milo-crypto-lending'];
    let allPosts = Object.values(loanCompanyPosts);

    let result = [];
    orderedSlugs.forEach(slug => {
      const post = allPosts.find(p => p.slug === slug);
      if (post) {
        result.push(post);
      }
    });

    const remainingPosts = allPosts.filter(post => !orderedSlugs.includes(post.slug));
    result = [...result, ...remainingPosts];
    return result;
  };

  const getRelatedArticles = () => {
    if (!currentArticle) return [];
    const currentSection = isInBlogPosts ? 'News & Blogs' : 'Top Loans';
    const sectionArticles = currentSection === 'News & Blogs'
      ? Object.values(blogPostData)
      : Object.values(loanCompanyPosts || {});
    
    return sectionArticles.filter(post => post.slug !== articleSlug);
  };

  const handleArticleClick = slug => {
    navigate(`/info-center/${slug}`);
  };

  const handleHomeClick = () => {
    window.location.href = '/';
  };

  // Updated HeaderBar component with centered title
function HeaderBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  
  // Create refs for the menu and button
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  
  // Add click outside handler
  useEffect(() => {
    function handleClickOutside(event) {
      // Close menu if click is outside both the menu and the button
      if (
        menuOpen && 
        menuRef.current && 
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    }
    
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    
    // Clean up
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Header Bar */}
      <div
        style={{
          width: '100%',
          maxWidth: '1000px',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          padding: '0.75rem 0.75rem 0.75rem 1rem',
          position: 'relative', // Make position relative for absolute positioning of title
        }}
      >
        {/* Left side - Home icon only */}
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleHomeClick}
        >
          <Home size={20} color="#0066cc" />
        </motion.div>

        {/* Center - Title */}
        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
          }}
        >
          <span
            style={{
              fontWeight: '600',
              fontSize: '1.1rem',
              color: '#0066cc',
            }}
          >
            Satoshi Advance News
          </span>
        </div>
        
        {/* Right side - Icon and Dropdown */}
        <div style={{ marginLeft: 'auto' }}>
          <motion.div
            ref={buttonRef} // Add ref to the button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            style={{
              cursor: 'pointer',
              padding: '0.5rem',
              borderRadius: '50%',
              backgroundColor: '#f5f5f5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '36px',
              height: '36px',
            }}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {/* Use lucide-react's Menu / X icons */}
            {menuOpen ? (
              <X size={20} strokeWidth={2} color="#0066cc" />
            ) : (
              <Menu size={20} strokeWidth={2} color="#0066cc" />
            )}
          </motion.div>

          {/* Dropdown Menu - Fixed positioning */}
          {menuOpen && (
            <motion.div
              ref={menuRef} // Add ref to the menu
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              style={{
                position: 'absolute',
                top: '100%',
                right: 0,
                width: isDesktop ? '250px' : '200px',
                backgroundColor: 'white',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                borderRadius: '0 0 8px 8px',
                overflow: 'hidden',
                zIndex: 1000,
              }}
            >
              {/* Menu items remain the same as before */}
              {Object.keys(sections).map((option) => (
                <motion.div
                  key={option}
                  whileHover={{ backgroundColor: '#f5f5f5' }}
                  style={{
                    padding: '1rem 1.5rem',
                    borderBottom: '1px solid #f0f0f0',
                    cursor: 'pointer',
                    backgroundColor: selectedOption === option ? '#e6f0ff' : 'white',
                    color: selectedOption === option ? '#0066cc' : '#333',
                    fontWeight: selectedOption === option ? '600' : '500',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setSelectedOption(option);
                    setMenuOpen(false);
                    if (articleSlug) {
                      navigate('/info-center');
                    }
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                >
                  {option}
                </motion.div>
              ))}

            

              <motion.div
                whileHover={{ backgroundColor: '#f5f5f5' }}
                style={{
                  padding: '1rem 1.5rem',
                  borderBottom: '1px solid #f0f0f0',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setShowPaymentPopup(true);
                  setMenuOpen(false);
                }}
              >
                Subscribe
              </motion.div>

              <motion.div
                whileHover={{ backgroundColor: '#f5f5f5' }}
                style={{
                  padding: '1rem 1.5rem',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.75rem',
                }}
                onClick={() => {
                  setShowAuthPopup(true);
                  setMenuOpen(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#0066cc"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                Login / Sign Up
              </motion.div>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
}

  

  function Section({ image, title, quote, content, tagline, date, slug, isBlogPost }) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        onClick={() => isBlogPost && handleArticleClick(slug)}
        style={{
          cursor: isBlogPost ? 'pointer' : 'default',
          width: '100%',
          maxWidth: isDesktop ? '1000px' : '100%',
          margin: isDesktop ? '0 auto 3rem' : '0 0 2rem 0',
          backgroundColor: isDesktop ? '#fff' : 'transparent',
          borderRadius: isDesktop ? '15px' : '0',
          boxShadow: isDesktop ? '0 4px 24px rgba(0, 0, 0, 0.1)' : 'none',
          padding: isDesktop ? '2rem' : '0'
        }}
      >
        <div style={{ width: '100%' }}>
          <img
            src={image}
            alt={title}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              borderRadius: isDesktop ? '12px' : '0',
              marginBottom: isDesktop ? '2rem' : '1rem'
            }}
          />
        </div>

        <div
          style={{
            width: '100%',
            maxWidth: isDesktop ? '800px' : '100%',
            margin: '0 auto',
            padding: isDesktop ? '0 1rem' : '0'
          }}
        >
          <h2
            style={{
              fontSize: isDesktop ? '2.8rem' : '2rem',
              fontWeight: 'bold',
              marginBottom: '1.5rem',
              textAlign: 'center',
              lineHeight: '1.2',
              color: '#1a1a1a',
              letterSpacing: '-0.02em'
            }}
          >
            {title}
          </h2>

          {tagline && date && (
            <div
              style={{
                textAlign: 'center',
                color: '#666',
                marginBottom: '1.5rem',
                fontSize: isDesktop ? '1.1rem' : '1rem'
              }}
            >
              <span style={{ fontWeight: '600', color: '#0066cc' }}>
                {tagline}
              </span>
              {' '}• {date}
            </div>
          )}

          <blockquote
            style={{
              fontStyle: 'italic',
              marginBottom: '2rem',
              color: '#444',
              textAlign: 'center',
              fontSize: isDesktop ? '1.4rem' : '1.2rem',
              lineHeight: '1.6',
              padding: isDesktop ? '1rem' : '0',
              borderLeft: isDesktop ? '2px solid #0066cc' : 'none',
              backgroundColor: isDesktop ? '#f8f9fa' : 'transparent'
            }}
          >
            {quote}
          </blockquote>

          {content && (
            <p
              style={{
                fontSize: isDesktop ? '1.2rem' : '1.4rem',
                lineHeight: isDesktop ? '1.9' : '2.1',
                color: '#374151',
                marginBottom: '2rem'
              }}
            >
              {content}
            </p>
          )}
        </div>
      </motion.div>
    );
  }

  function RelatedArticlesSection() {
    const relatedArticles = getRelatedArticles();
    if (relatedArticles.length === 0) return null;
    const sectionName = isInBlogPosts ? 'News & Blogs' : 'Top Loans';
    
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{
          width: '100%',
          maxWidth: isDesktop ? '1000px' : '100%',
          margin: isDesktop ? '2rem auto 3rem' : '2rem 0 3rem',
          backgroundColor: isDesktop ? '#fff' : 'transparent',
          borderRadius: isDesktop ? '15px' : '0',
          boxShadow: isDesktop ? '0 4px 24px rgba(0, 0, 0, 0.1)' : 'none',
          padding: isDesktop ? '2rem' : '1.5rem',
        }}
      >
        <h2
          style={{
            fontSize: isDesktop ? '2.4rem' : '2rem',
            fontWeight: 'bold',
            marginBottom: '1.5rem',
            color: '#1a1a1a',
            letterSpacing: '-0.02em',
            textAlign: 'center'
          }}
        >
          Read More Articles in {sectionName}
        </h2>
        
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1.5rem',
            textAlign: 'center'
          }}
        >
          <div
            style={{
              padding: '0.75rem 1.5rem',
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              color: '#666'
            }}
          >
            <span style={{ fontWeight: '600' }}>Today:</span> {currentDate}
          </div>
        </div>
        
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? 'repeat(auto-fill, minmax(300px, 1fr))' : '1fr',
            gap: '2rem',
            marginTop: '2rem'
          }}
        >
          {relatedArticles.slice(0, 3).map((article) => (
            <motion.div
              key={article.slug}
              whileHover={{ scale: 1.03 }}
              style={{
                backgroundColor: '#f8f9fa',
                borderRadius: '12px',
                overflow: 'hidden',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer'
              }}
              onClick={() => handleArticleClick(article.slug)}
            >
              <img
                src={article.headerImage}
                alt={article.title}
                style={{
                  width: '100%',
                  height: '160px',
                  objectFit: 'cover'
                }}
              />
              <div style={{ padding: '1.5rem' }}>
                <h3
                  style={{
                    fontSize: '1.4rem',
                    fontWeight: 'bold',
                    marginBottom: '0.5rem',
                    color: '#1a1a1a',
                  }}
                >
                  {article.title}
                </h3>
                <p
                  style={{
                    fontSize: '0.9rem',
                    color: '#666',
                    marginBottom: '1rem'
                  }}
                >
                  {article.date}
                </p>
                <p
                  style={{
                    fontSize: '1rem',
                    color: '#374151',
                    marginBottom: '1rem'
                  }}
                >
                  {article.mainQuote}
                </p>
                <div
                  style={{
                    display: 'inline-block',
                    padding: '0.5rem 1rem',
                    backgroundColor: '#0066cc',
                    color: 'white',
                    borderRadius: '6px',
                    fontWeight: '600',
                    fontSize: '0.9rem'
                  }}
                >
                  Read Article
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    );
  }

  function ReadNextSection({ prevDate }) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{
          width: '100%',
          maxWidth: isDesktop ? '1000px' : '100%',
          margin: isDesktop ? '2rem auto 3rem' : '2rem 0 3rem',
          backgroundColor: isDesktop ? '#fff' : 'transparent',
          borderRadius: isDesktop ? '15px' : '0',
          boxShadow: isDesktop ? '0 4px 24px rgba(0, 0, 0, 0.1)' : 'none',
          padding: isDesktop ? '2rem' : '1.5rem',
          textAlign: 'center'
        }}
      >
        <h2
          style={{
            fontSize: isDesktop ? '2.4rem' : '2rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            color: '#1a1a1a',
            letterSpacing: '-0.02em'
          }}
        >
          Read Next
        </h2>
        <h3
          style={{
            fontSize: isDesktop ? '1.8rem' : '1.5rem',
            fontWeight: '600',
            marginBottom: '1.5rem',
            color: '#333',
          }}
        >
          Other Recent Articles in Top Loans
        </h3>
        
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '2rem',
            flexWrap: 'wrap',
            marginTop: '1rem'
          }}
        >
          <div
            style={{
              padding: '0.75rem 1.5rem',
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              color: '#666'
            }}
          >
            <span style={{ fontWeight: '600' }}>Today:</span> {currentDate}
          </div>
          <div
            style={{
              padding: '0.75rem 1.5rem',
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              color: '#666'
            }}
          >
            <span style={{ fontWeight: '600' }}>Previous Article:</span> {prevDate}
          </div>
        </div>
        
        <div style={{ marginTop: '2rem', animation: 'bounce 2s infinite' }}>
          <ChevronDown size={32} color="#0066cc" />
        </div>
      </motion.div>
    );
  }

  // 3) Combine all bottom buttons (Contact, Login/Signup, Subscribe)
  function ActionButtonsSection() {
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '1rem',
          margin: isDesktop ? '3rem auto 2rem' : '2rem auto',
          maxWidth: isDesktop ? '800px' : '100%',
          alignItems: 'center'
        }}
      >
        {/* Contact (mailto) */}
        <motion.a
          href="mailto:contact@satoshiadvance.com"
          whileHover={{ scale: 1.05, backgroundColor: '#005bb7' }}
          whileTap={{ scale: 0.95 }}
          style={{
            display: 'inline-block',
            backgroundColor: '#0066cc',
            color: 'white',
            padding: isDesktop ? '1rem 1.5rem' : '0.75rem 1.25rem',
            borderRadius: '12px',
            fontSize: isDesktop ? '1rem' : '1.2rem',
            fontWeight: '600',
            textDecoration: 'none',
            boxShadow: '0 4px 12px rgba(0, 102, 204, 0.2)'
          }}
        >
          Contact
        </motion.a>

        {/* Login/Signup (show Auth popup) */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          style={{
            backgroundColor: '#0066cc',
            color: 'white',
            padding: isDesktop ? '1rem 1.5rem' : '0.75rem 1.25rem',
            borderRadius: '12px',
            fontSize: isDesktop ? '1rem' : '1.2rem',
            fontWeight: '600',
            border: 'none',
            cursor: 'pointer',
            boxShadow: '0 4px 12px rgba(0, 102, 204, 0.2)'
          }}
          onClick={() => setShowAuthPopup(true)}
        >
          Login/Signup
        </motion.button>

       

        {/* Subscribe (show Payment popup) */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          style={{
            backgroundColor: '#0066cc',
            color: 'white',
            padding: isDesktop ? '1rem 1.5rem' : '0.75rem 1.25rem',
            borderRadius: '12px',
            fontSize: isDesktop ? '1rem' : '1.2rem',
            fontWeight: '600',
            border: 'none',
            cursor: 'pointer',
            boxShadow: '0 4px 12px rgba(0, 102, 204, 0.2)'
          }}
          onClick={() => setShowPaymentPopup(true)}
        >
          Subscribe
        </motion.button>
      </div>
    );
  }

  // Ensure a valid content array for the blog post
  function getFullBlogPost(post) {
    if (!post) return post;
    return {
      ...post,
      content: Array.isArray(post.content) && post.content.length > 0 
        ? post.content 
        : [
            {
              type: 'regular',
              text: `This article covers key aspects of ${post.title} including their services, history, and features.`
            },
            ...(post.keyTakeaways && post.keyTakeaways.length > 0 
              ? [{ 
                  type: 'bullet-points',
                  heading: 'Key Highlights',
                  points: post.keyTakeaways
                }] 
              : [])
          ]
    };
  }

  // Render full blog posts for Top Loans
  const renderTopLoansView = () => {
    if (selectedOption === 'Top Loans' && !articleSlug) {
      const orderedPosts = getOrderedLoanPosts();
      if (orderedPosts.length === 0) return null;
      
      return (
        <>
          <BlogPost post={getFullBlogPost(orderedPosts[0])} isDesktop={isDesktop} />
          {orderedPosts.length > 1 && (
            <ReadNextSection prevDate={orderedPosts[0].date} />
          )}
          {orderedPosts.slice(1).map((post, index) => (
            <BlogPost 
              key={post.slug || index}
              post={getFullBlogPost(post)}
              isDesktop={isDesktop}
            />
          ))}
        </>
      );
    }
    return null;
  };

  // Bouncing arrow CSS
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-20px);
        }
        60% {
          transform: translateY(-10px);
        }
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div
      id="top"
      style={{
        backgroundColor: isDesktop ? '#f5f5f5' : '#fff',
        minHeight: '100vh',
        padding: isDesktop ? '2rem' : '1rem',
        overflowX: 'hidden'
      }}
    >
      {/* Header */}
      <HeaderBar />
      {/* Spacer so content isn't hidden under the fixed header */}
      <div style={{ height: isDesktop ? '80px' : '60px' }}></div>

      {/* If an article is selected */}
      {articleSlug && currentArticle && (
        <>
          <BlogPost post={getFullBlogPost(currentArticle)} isDesktop={isDesktop} />
          <RelatedArticlesSection />
        </>
      )}

      {/* If on Top Loans tab (and no article selected), show the full blog posts */}
      {!articleSlug && renderTopLoansView()}

      {/* Otherwise, show the default list of cards */}
      {!(selectedOption === 'Top Loans' && !articleSlug) && !articleSlug &&
        filteredContent().map((item, index) => (
          <Section
            key={item.slug || index}
            isDesktop={isDesktop}
            handleArticleClick={handleArticleClick}
            {...item}
          />
        ))
      }

      <AdBanner adSlot="4575398190" />

      {/* Bottom Action Buttons */}
      <ActionButtonsSection />

      {/* Back to Top link */}
      <div style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '2rem' }}>
        <a
          href="#top"
          style={{
            textDecoration: 'none',
            color: '#0066cc',
            fontWeight: 'bold',
            fontSize: isDesktop ? '1.2rem' : '1rem'
          }}
        >
          Back to Top
        </a>
      </div>

      {/* 4) Conditionally render the popups */}
      {showAuthPopup && (
  <AuthPopup 
    onClose={() => setShowAuthPopup(false)} 
    fromInfoCenter={true}
    openPaymentPopup={(paymentAmount) => {
      setAmount(paymentAmount); // Set the amount from the selected plan
      setShowAuthPopup(false);
      setTimeout(() => {
        setShowPaymentPopup(true);
      }, 300);
    }}
  />
)}
      {showPaymentPopup && (
  <PaymentPopup 
    onClose={() => setShowPaymentPopup(false)} 
    amount={amount} // Pass the selected amount
  />
)}
    </div>
  );
}

export default InfoCenter;


