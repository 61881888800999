// src/components/PrivacyPolicy.jsx
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const PageBackground = styled(Box)(({ theme }) => ({
  backgroundColor: '#212121', // Dark grey background
  minHeight: '100vh',
  width: '100%',
  padding: '20px',
}));

const Container = styled(Box)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  marginTop: '40px',
  padding: '30px',
  backgroundColor: '#1a237e',
  borderRadius: '10px',
  boxShadow: '0px 0px 20px 0px #00BFFF',
  color: '#FFFFFF',
  fontFamily: "'Roboto', sans-serif",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '2rem',
  textAlign: 'center',
  marginBottom: '20px',
  textShadow: '0 0 10px #FFFFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '1.2rem',
  marginTop: '20px',
  marginBottom: '10px',
  textDecoration: 'underline',
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  marginBottom: '10px',
  lineHeight: 1.5,
}));

const PrivacyPolicy = () => {
  return (
    <PageBackground>
      <Helmet>
        <title>Satoshi Advance | Privacy Policy</title>
        <meta
          name="description"
          content="Learn how Satoshi Advance collects, uses, and protects your personal data. This policy covers email collection, data retention, and user choices."
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://www.satoshiadvance.com/privacy-policy" />
      </Helmet>

      <Container>
        <Header>Privacy Policy</Header>
        <Paragraph>Last updated: March 16, 2025</Paragraph>
        
        <SectionTitle>1. Information We Collect</SectionTitle>
        <Paragraph>
          • <strong>Email Address:</strong> When you sign up or log in with Google OAuth, or otherwise provide your email to us, we collect and store your email address.
        </Paragraph>
        <Paragraph>
          • <strong>Automatically Collected Data:</strong> We may also collect non‑personal data automatically (e.g., logs, browser type, or usage information) to improve our services.
        </Paragraph>
        
        <SectionTitle>2. How We Use Your Information</SectionTitle>
        <Paragraph>
          • <strong>Account Creation &amp; Authentication:</strong> We use your email address to create your account, authenticate your login, and provide personalized services.
        </Paragraph>
        <Paragraph>
          • <strong>Communication:</strong> We may send you transactional or service‑related emails (e.g., password resets, updates on our Terms or Privacy Policy).
        </Paragraph>
        <Paragraph>
          • <strong>Improving Our Services:</strong> We analyze usage data to understand how users interact with our site and to enhance user experience.
        </Paragraph>
        
        <SectionTitle>3. Data Sharing &amp; Disclosure</SectionTitle>
        <Paragraph>
          • <strong>Third‑Party Services:</strong> We do not sell or rent your personal information to third parties. However, we may use third‑party services (e.g., hosting providers, analytics) to help operate our services.
        </Paragraph>
        <Paragraph>
          • <strong>Legal Compliance:</strong> We may disclose information if required by law, subpoena, or in response to a government request.
        </Paragraph>
        
        <SectionTitle>4. Cookies &amp; Tracking</SectionTitle>
        <Paragraph>
          We may use cookies or similar technologies to enhance your user experience, save your preferences, or track site usage. You can manage your cookie settings through your browser.
        </Paragraph>
        
        <SectionTitle>5. Data Retention</SectionTitle>
        <Paragraph>
          We retain your email and related account data for as long as you have an account or as needed to fulfill the purposes described in this policy. You can request deletion of your account at any time.
        </Paragraph>
        
        <SectionTitle>6. Data Security</SectionTitle>
        <Paragraph>
          We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of data transmission or storage is completely secure, and we cannot guarantee absolute security.
        </Paragraph>
        
        <SectionTitle>7. Children’s Privacy</SectionTitle>
        <Paragraph>
          Our services are not directed to children under the age of 13. We do not knowingly collect personal information from children. If you believe a child has provided us with personal data, please contact us immediately.
        </Paragraph>
        
        <SectionTitle>8. Your Choices</SectionTitle>
        <Paragraph>
          • <strong>Account Deletion:</strong> You can delete your account or request removal of your email by contacting us.
        </Paragraph>
        <Paragraph>
          • <strong>Opt‑Out:</strong> If you receive emails from us, you can opt out by following the unsubscribe link or contacting us.
        </Paragraph>
        
        <SectionTitle>9. Changes to This Policy</SectionTitle>
        <Paragraph>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated “Last updated” date. By continuing to use our services, you agree to the revised policy.
        </Paragraph>
        
        <SectionTitle>10. Contact Us</SectionTitle>
        <Paragraph>
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </Paragraph>
        <Paragraph>
          <strong>support@satoshiadvance.com</strong>
        </Paragraph>
      </Container>
    </PageBackground>
  );
};

export default PrivacyPolicy;
