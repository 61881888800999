import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Typography, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Styled components
const RetroText = styled(motion(Typography))(({ theme }) => ({
  color: '#FFFFFF',
  textShadow: `-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000`,
  fontWeight: 'bold',
  fontSize: '2.5rem',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontFamily: "'Press Start 2P', cursive",
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const LoanBox = styled(motion(Box))(({ theme }) => ({
  // Base styles
  backgroundColor: '#1a237e',
  padding: '20px',
  borderRadius: '16px',
  marginBottom: '20px',
  color: '#FFFFFF',
  width: '45%', // Default width for desktop
  margin: '0 auto',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',

  // Improved gradient background
  background: `
    linear-gradient(135deg, 
      rgba(26, 35, 126, 0.95) 0%,
      rgba(21, 101, 192, 0.9) 100%
    )
  `,

  // Texture overlay
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      repeating-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.03) 0px,
        rgba(255, 255, 255, 0.03) 1px,
        transparent 1px,
        transparent 2px
      )
    `,
    pointerEvents: 'none',
  },

  // Shine effect
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.05), transparent)',
    transform: 'translateX(-100%)',
    transition: 'transform 0.5s ease',
  },

  // Box shadow
  boxShadow: '0 4px 15px rgba(0,0,0,0.2), inset 0 0 30px rgba(26,35,126,0.2)',

  // Hover effects
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0,0,0,0.3), inset 0 0 35px rgba(26,35,126,0.3)',
    '&:after': {
      transform: 'translateX(100%)',
      transition: 'transform 1s ease',
    }
  },

  // Tablet styles
  [theme.breakpoints.between('sm', 'md')]: {
    width: '80%',               // Wider width for tablets
    padding: '16px',            // Slightly reduced padding
    margin: '0 auto 16px auto', // Adjusted margin
  },

  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    width: '100%',                // Full width on mobile without padding
    margin: '0 auto',             // Center the box
    marginBottom: '10px',         // Keep bottom margin
    padding: '8px',               // Minimal padding
    borderRadius: '8px',          // Smaller radius
    boxShadow: '0 2px 6px rgba(0,0,0,0.15)',
    transform: 'none',            // Remove any transform that might affect centering
  }
}));

const RetroLink = styled('a')({
  color: '#00BFFF',
  textDecoration: 'none',
  fontSize: '1.1rem',
  display: 'block',
  margin: '10px 0',
  wordBreak: 'break-word',
  '&:hover': {
    textDecoration: 'underline',
    color: '#80DFFF',
  },
});

// Create a new plain manila-colored link for "Read Full Article"
const ReadMoreLink = styled('a')(({ theme }) => ({
  color: '#F0E68C', // Manila/khaki color
  textDecoration: 'none',
  fontSize: '2rem',
  fontFamily: 'Arial, sans-serif', // Plain modern font
  display: 'block',
  margin: '15px 0 5px 0',
  padding: '5px 0',
  cursor: 'pointer',
  textAlign: 'center',
  fontWeight: 'normal',
  '&:hover': {
    textDecoration: 'underline',
    color: '#FFFACD', // Lighter shade on hover
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
    margin: '10px 0 0 0',
  }
}));

const DataCell = styled(motion.div)(({ theme }) => ({
  // Base styles
  padding: '20px',
  background: 'linear-gradient(145deg, #2a2a3a 0%, #1a1a2a 100%)',
  borderRadius: '12px',
  border: '1px solid rgba(255,255,255,0.07)',
  transition: 'all 0.4s ease',
  position: 'relative',
  overflow: 'hidden',
  backdropFilter: 'blur(4px)',
  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',

  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 20px rgba(0,0,0,0.3)',
    border: '1px solid rgba(255,255,255,0.12)',
  },

  // Tablet styles
  [theme.breakpoints.between('sm', 'md')]: {
    padding: '14px',              // Slightly reduced padding for tablets
  },

  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    padding: '10px',                    // Reduced padding
    borderRadius: '8px',                // Smaller border radius
    boxShadow: '0 2px 6px rgba(0,0,0,0.2)',

    '&:hover': {
      transform: 'translateY(-2px)',    // Smaller hover lift
      boxShadow: '0 4px 12px rgba(0,0,0,0.25)',
    }
  }
}));

// Update the label styling
const DataLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  color: '#FFE873',
  marginBottom: '8px',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  fontWeight: '500',
  textShadow: '0 0 8px rgba(255,216,0,0.3)',
  '&:hover': {
    textShadow: '0 0 12px rgba(255,216,0,0.4)',
  },
  // Tablet styles
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '0.8rem',
    letterSpacing: '1.5px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.6rem',
    letterSpacing: '1px',
    marginBottom: '4px',
    fontWeight: '400'
  }
}));

const DataValue = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  color: '#FFFFFF',
  fontWeight: '500',
  letterSpacing: '0.5px',
  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
  // Tablet styles
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    letterSpacing: '0.3px',
    fontWeight: '400',
    lineHeight: '1.2'
  }
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  // Desktop styles
  width: '100%',
  height: 'auto',         // Changed from fixed height
  maxHeight: '400px',     // Maximum height on desktop
  borderRadius: '16px',
  overflow: 'hidden',
  marginBottom: '32px',
  boxShadow: '0 12px 40px rgba(0,0,0,0.3)',
  position: 'relative',

  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%)',
    pointerEvents: 'none',
  },

  // Tablet styles
  [theme.breakpoints.between('sm', 'md')]: {
    maxHeight: '300px',           // Adjusted height for tablets
    marginBottom: '24px',         // Less bottom margin
    borderRadius: '12px',         // Slightly smaller radius
  },

  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'auto',
    maxHeight: '200px',    // Constrain height on mobile
    marginBottom: '8px',   // Reduced margin
    borderRadius: '4px',   // Minimal border radius
    boxShadow: 'none',     // Remove shadow on mobile
  }
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',   // Changed from 'cover' to 'contain'
  objectPosition: 'center',
  transition: 'transform 0.6s ease',

  [theme.breakpoints.down('sm')]: {
    objectFit: 'contain',
    width: '100%',
    height: 'auto',
    maxHeight: '200px'    // Match container max-height
  }
}));

const HexGrid = styled(motion.div)(({ theme }) => ({
  // Base styles for all screen sizes
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '20px',
  marginTop: '24px',
  width: '100%',
  position: 'relative',

  // Gradient overlay
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(45deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)',
    pointerEvents: 'none',
  },

  // Tablet styles
  [theme.breakpoints.between('sm', 'md')]: {
    gap: '16px',                   // Slightly reduced gap for tablets
    marginTop: '20px',
    padding: '0 4px',              // Minimal padding on tablets
  },

  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)', // Keep 2 columns
    gap: '8px',                           // Reduced gap for mobile
    marginTop: '12px',                    // Less top margin
    padding: '0',                         // Remove padding completely
  }
}));

// New arrow button component
const ArrowButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: '#1a237e',
  width: '48px',
  height: '48px',
  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
  margin: '20px auto',
  display: 'block',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 15px rgba(0,0,0,0.3)',
  },
  [theme.breakpoints.down('sm')]: {
    width: '36px',
    height: '36px',
    margin: '12px auto',
  }
}));

// Mapping for company names to blog post slugs
const companyToSlugMap = {
  'Gemini': 'gemini-exchange',
  'Milo': 'milo-crypto-lending',
  'Nexo': 'nexo-platform',
  'Aave': 'aave-protocol',
  'Compound': 'compound-finance',
  'BlockFi': 'blockfi-platform',
  'Celsius': 'celsius-network',
  'Maker': 'makerdao-platform',
  'Yearn': 'yearn-finance',
  'dYdX': 'dydx-exchange',
  'Solend': 'solend-protocol',
  'Flux Finance': 'flux-finance',
  'Unchained Capital': 'unchained-capital',
  'Maple Finance': 'maple-finance'
  // Add mappings for all your loan companies
};

const TopCryptoLoans = () => {
  const [loans, setLoans] = useState([]);
  const [expandedId, setExpandedId] = useState(null);
  const [visibleSection, setVisibleSection] = useState(0); // 0 = first section, 1 = second section
  const navigate = useNavigate();
  const sectionRefs = [useRef(null), useRef(null)]; // Refs for the two sections for smooth scrolling

  useEffect(() => {
    const fetchLoans = async () => {
      try {
        const response = await axios.get('/api/top-loan-companies');
        setLoans(response.data);
      } catch (error) {
        console.error('Error fetching loans:', error);
        // Fallback data in case the API is not available during development
        setLoans([
          {
            "_id": "1",
            "Name": "Gemini",
            "URL": "https://www.gemini.com/credit-card",
            "Description": "NY Based Exchange & Financial Services, including credit card",
            "ImageURL": "https://media.satoshiadvance.com/images/GeminiExchange.png",
            "xTwitter": "https://x.com/Gemini",
            "Location": "USA",
            "Founded": "2014",
            "EmployeeCount": "1000",
            "Token": "GUSD",
            "Protocol": "BTC"
          },
          {
            "_id": "2",
            "Name": "Milo",
            "URL": "https://milo.io",
            "Description": "Premier platform for crypto-backed loans, offering competitive rates and flexible terms",
            "ImageURL": "https://media.satoshiadvance.com/images/MiloCryptoLending.png",
            "xTwitter": "https://x.com/milodotio",
            "Location": "USA",
            "Founded": "2019",
            "EmployeeCount": "85",
            "Token": "-",
            "Protocol": "BTC, ETH"
          },
          {
            "_id": "3",
            "Name": "Flux Finance",
            "URL": "https://fluxfinance.com",
            "Description": "DeFi lending protocol built by Ondo Finance, supporting both permissionless and permissioned tokens",
            "ImageURL": "https://media.satoshiadvance.com/images/FluxFinance.png",
            "xTwitter": "https://x.com/FluxFinance",
            "Location": "Cayman Islands",
            "Founded": "2022",
            "EmployeeCount": "N/A",
            "Token": "ONDO",
            "Protocol": "Compound V2 Fork"
          },
          {
            "_id": "4",
            "Name": "Nexo",
            "URL": "https://nexo.io",
            "Description": "Global regulated digital assets institution offering instant crypto loans",
            "ImageURL": "https://media.satoshiadvance.com/images/NexoLending.png",
            "xTwitter": "https://x.com/Nexo",
            "Location": "Switzerland",
            "Founded": "2018",
            "EmployeeCount": "600",
            "Token": "NEXO",
            "Protocol": "Multi-chain"
          },
          {
            "_id": "5",
            "Name": "Aave",
            "URL": "https://aave.com",
            "Description": "Open source liquidity protocol where users can participate as suppliers or borrowers",
            "ImageURL": "https://media.satoshiadvance.com/images/AaveProtocol.png",
            "xTwitter": "https://x.com/AaveAave",
            "Location": "Decentralized",
            "Founded": "2017",
            "EmployeeCount": "Decentralized",
            "Token": "AAVE",
            "Protocol": "Ethereum, Polygon, Avalanche"
          },
          {
            "_id": "6",
            "Name": "Compound",
            "URL": "https://compound.finance",
            "Description": "Algorithmic, autonomous interest rate protocol built for developers",
            "ImageURL": "https://media.satoshiadvance.com/images/CompoundFinance.png",
            "xTwitter": "https://x.com/compoundfinance",
            "Location": "Decentralized",
            "Founded": "2018",
            "EmployeeCount": "Decentralized",
            "Token": "COMP",
            "Protocol": "Ethereum"
          },
          {
            "_id": "7",
            "Name": "BlockFi",
            "URL": "https://blockfi.com",
            "Description": "Crypto wealth management platform offering interest-bearing accounts and low-cost loans",
            "ImageURL": "https://media.satoshiadvance.com/images/BlockFi.png",
            "xTwitter": "https://x.com/BlockFi",
            "Location": "USA",
            "Founded": "2017",
            "EmployeeCount": "850",
            "Token": "-",
            "Protocol": "Custodial"
          },
          {
            "_id": "8",
            "Name": "Maker",
            "URL": "https://makerdao.com",
            "Description": "Decentralized autonomous organization that manages the Dai stablecoin",
            "ImageURL": "https://media.satoshiadvance.com/images/MakerDAO.png",
            "xTwitter": "https://x.com/MakerDAO",
            "Location": "Decentralized",
            "Founded": "2015",
            "EmployeeCount": "Decentralized",
            "Token": "MKR",
            "Protocol": "Ethereum"
          },
          {
            "_id": "9",
            "Name": "Yearn",
            "URL": "https://yearn.finance",
            "Description": "Suite of DeFi products including vaults for automated yield generation",
            "ImageURL": "https://media.satoshiadvance.com/images/YearnFinance.png",
            "xTwitter": "https://x.com/iearnfinance",
            "Location": "Decentralized",
            "Founded": "2020",
            "EmployeeCount": "Decentralized",
            "Token": "YFI",
            "Protocol": "Ethereum"
          },
          {
            "_id": "10",
            "Name": "dYdX",
            "URL": "https://dydx.exchange",
            "Description": "Decentralized exchange for margin trading and lending",
            "ImageURL": "https://media.satoshiadvance.com/images/dYdX.png",
            "xTwitter": "https://x.com/dydx",
            "Location": "Decentralized",
            "Founded": "2017",
            "EmployeeCount": "Decentralized",
            "Token": "DYDX",
            "Protocol": "Ethereum, StarkWare"
          }
        ]);
      }
    };
    fetchLoans();
  }, []);

  const handleCardClick = (id, event) => {
    // If the click originated from the Read More link, don't toggle the card
    if (event.target.tagName === 'A' ||
      event.target.closest('a')) {
      return;
    }

    setExpandedId(expandedId === id ? null : id); // Toggle card expansion

    // Scroll the clicked card into view
    const element = document.getElementById(`loan-card-${id}`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300); // Allow time for the animation to start
    }
  };

  const navigateToBlogPost = (companyName, event) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent the card expansion

    // Get the correct slug from the mapping or generate a fallback
    const slug = companyToSlugMap[companyName] ||
      companyName.toLowerCase().replace(/\s+/g, '-');

    // Use React Router navigate instead of changing window.location directly
    navigate(`/info-center/${slug}`);
  };

  // Function to switch between sections with smooth scrolling
  const switchSection = (sectionIndex) => {
    setVisibleSection(sectionIndex);

    // Smooth scroll to the section
    if (sectionRefs[sectionIndex] && sectionRefs[sectionIndex].current) {
      sectionRefs[sectionIndex].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }

    // Close any expanded cards when switching sections
    setExpandedId(null);
  };

  if (loans.length === 0) {
    return <Typography sx={{ color: '#FFFFFF' }}>Loading...</Typography>;
  }

  // Split loans into sections of 5
  const firstSection = loans.slice(0, 5);
  const secondSection = loans.slice(5, 10);

  return (
    <Box sx={{
      marginTop: '40px',
      textAlign: 'center',
      px: {
        xs: 0,   // No padding on mobile
        sm: 0.5, // Minimal padding on small tablets
        md: 2    // Normal padding on larger screens
      },
      position: 'relative'
    }}>
      <RetroText>2025's Top Crypto Loans</RetroText>

      {/* First Section */}
      <Box
        ref={sectionRefs[0]}
        sx={{
          opacity: visibleSection === 0 ? 1 : 0.3,
          transition: 'opacity 0.5s ease',
          pointerEvents: visibleSection === 0 ? 'auto' : 'none'
        }}
      >
        <AnimatePresence mode="wait">
          {visibleSection === 0 && (
            <motion.div
              key="section1"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              {firstSection.map((loan, index) => (
                <LoanBox
                  key={loan._id}
                  id={`loan-card-${loan._id}`}
                  onClick={(e) => handleCardClick(loan._id, e)}
                  initial={{ opacity: 0, x: index % 2 === 0 ? '-100vw' : '100vw', rotateY: -90 }}
                  animate={{ opacity: 1, x: 0, rotateY: 0 }}
                  transition={{ duration: 1, delay: index * 0.3, type: 'spring' }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                    #{index + 1} {loan.Name}
                  </Typography>
                  {loan.URL && (
                    <RetroLink href={loan.URL} target="_blank" rel="noopener noreferrer">
                      {loan.URL}
                    </RetroLink>
                  )}
                  <Typography variant="body1" sx={{ marginTop: '10px', fontSize: '1.1rem', opacity: 0.9 }}>
                    {loan.Description}
                  </Typography>

                  <Collapse in={expandedId === loan._id} timeout="auto">
                    <Box sx={{
                      mt: { xs: 1, sm: 2, md: 3 },
                      p: { xs: 0.5, sm: 2, md: 3 },
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      borderRadius: { xs: '4px', sm: '12px', md: '16px' }
                    }}>
                      {loan.ImageURL && (
                        <ImageContainer
                          as={motion.div}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.5 }}
                        >
                          <StyledImage
                            src={loan.ImageURL}
                            alt={`${loan.Name} platform`}
                            onError={(e) => {
                              e.target.alt = 'Platform image not available';
                            }}
                          />
                        </ImageContainer>
                      )}

                      <HexGrid
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                      >
                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Social</DataLabel>
                          <DataValue>{loan.xTwitter}</DataValue>
                        </DataCell>

                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Location</DataLabel>
                          <DataValue>{loan.Location}</DataValue>
                        </DataCell>

                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Founded</DataLabel>
                          <DataValue>{loan.Founded}</DataValue>
                        </DataCell>

                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Team Size</DataLabel>
                          <DataValue>{loan.EmployeeCount}</DataValue>
                        </DataCell>

                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Token</DataLabel>
                          <DataValue>{loan.Token}</DataValue>
                        </DataCell>

                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Protocol</DataLabel>
                          <DataValue>{loan.Protocol}</DataValue>
                        </DataCell>
                      </HexGrid>

                      <ReadMoreLink
                        as={motion.a}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.4, duration: 0.3 }}
                        onClick={(e) => navigateToBlogPost(loan.Name, e)}
                      >
                        Read Full Article
                      </ReadMoreLink>
                    </Box>
                  </Collapse>
                </LoanBox>
              ))}
            </motion.div>
          )}
        </AnimatePresence>

        {/* Down Arrow Button - only shown when viewing first section */}
        {visibleSection === 0 && secondSection.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.5, duration: 0.5 }}
          >
            <ArrowButton
              onClick={() => switchSection(1)}
              aria-label="Show more companies"
            >
              <KeyboardArrowDownIcon fontSize="large" />
            </ArrowButton>
          </motion.div>
        )}
      </Box>

      {/* Second Section */}
      <Box
        ref={sectionRefs[1]}
        sx={{
          opacity: visibleSection === 1 ? 1 : 0.3,
          transition: 'opacity 0.5s ease',
          pointerEvents: visibleSection === 1 ? 'auto' : 'none',
          mt: 4 // Add margin top for spacing between sections
        }}
      >
        {/* Up Arrow Button - only shown when viewing second section */}
        {visibleSection === 1 && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ArrowButton
              onClick={() => switchSection(0)}
              aria-label="Show previous companies"
            >
              <KeyboardArrowUpIcon fontSize="large" />
            </ArrowButton>
          </motion.div>
        )}

        <AnimatePresence mode="wait">
          {visibleSection === 1 && secondSection.length > 0 && (
            <motion.div
              key="section2"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              {secondSection.map((loan, index) => (
                <LoanBox
                  key={loan._id}
                  id={`loan-card-${loan._id}`}
                  onClick={(e) => handleCardClick(loan._id, e)}
                  initial={{ opacity: 0, x: index % 2 === 0 ? '-100vw' : '100vw', rotateY: -90 }}
                  animate={{ opacity: 1, x: 0, rotateY: 0 }}
                  transition={{ duration: 1, delay: index * 0.3, type: 'spring' }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                    #{index + 6} {loan.Name}
                  </Typography>
                  {loan.URL && (
                    <RetroLink href={loan.URL} target="_blank" rel="noopener noreferrer">
                      {loan.URL}
                    </RetroLink>
                  )}
                  <Typography variant="body1" sx={{ marginTop: '10px', fontSize: '1.1rem', opacity: 0.9 }}>
                    {loan.Description}
                  </Typography>

                  <Collapse in={expandedId === loan._id} timeout="auto">
                    <Box sx={{
                      mt: { xs: 1, sm: 2, md: 3 },
                      p: { xs: 0.5, sm: 2, md: 3 },
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      borderRadius: { xs: '4px', sm: '12px', md: '16px' }
                    }}>
                      {loan.ImageURL && (
                        <ImageContainer
                          as={motion.div}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.5 }}
                        >
                          <StyledImage
                            src={loan.ImageURL}
                            alt={`${loan.Name} platform`}
                            onError={(e) => {
                              e.target.alt = 'Platform image not available';
                            }}
                          />
                        </ImageContainer>
                      )}

                      <HexGrid
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                      >
                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Social</DataLabel>
                          <DataValue>{loan.xTwitter}</DataValue>
                        </DataCell>

                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Location</DataLabel>
                          <DataValue>{loan.Location}</DataValue>
                        </DataCell>

                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Founded</DataLabel>
                          <DataValue>{loan.Founded}</DataValue>
                        </DataCell>

                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Team Size</DataLabel>
                          <DataValue>{loan.EmployeeCount}</DataValue>
                        </DataCell>

                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Token</DataLabel>
                          <DataValue>{loan.Token}</DataValue>
                        </DataCell>

                        <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                          <DataLabel>Protocol</DataLabel>
                          <DataValue>{loan.Protocol}</DataValue>
                        </DataCell>
                      </HexGrid>

                      <ReadMoreLink
                        as={motion.a}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.4, duration: 0.3 }}
                        onClick={(e) => navigateToBlogPost(loan.Name, e)}
                      >
                        Read Full Article
                      </ReadMoreLink>
                    </Box>
                  </Collapse>
                </LoanBox>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );
};

export default TopCryptoLoans;
