import React, { useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

// Constants for Media Base URL
const MEDIA_BASE_URL = "https://media.satoshiadvance.com/images";

// Title Styling
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '2.4rem',
  marginTop: '40px',
  marginBottom: '20px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  '@media (max-width: 600px)': {
    fontSize: '1.2rem',
  },
}));

// Orange Divider Styling
const OrangeDividerBar = styled(motion('div'))(({ theme }) => ({
  width: '80%',
  height: '3.5px',
  backgroundColor: '#FF6B00',
  margin: '30px auto 30px',
  transformOrigin: 'center',
  position: 'relative',
  zIndex: 1,
  boxShadow: `
    0 0 5px #FF8F00,
    0 0 10px #FF6B00,
    0 0 20px #FF6B00,
    0 0 30px #FF4500,
    0 0 50px #FF4500
  `,
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}));

// Blog Card Container with fixed dimensions
const BlogCard = styled(motion(Box))(({ theme, isExpanded }) => ({
  backgroundColor: '#1a237e',
  padding: '20px',
  borderRadius: '16px',
  marginBottom: '20px',
  color: '#FFFFFF',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',
  // Exactly fixed height - no flexibility
  height: '220px', // Fixed height for collapsed state - ALWAYS the same
  background: `
    linear-gradient(135deg, 
      rgba(26, 35, 126, 0.95) 0%,
      rgba(21, 101, 192, 0.9) 100%
    )
  `,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      repeating-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.03) 0px,
        rgba(255, 255, 255, 0.03) 1px,
        transparent 1px,
        transparent 2px
      )
    `,
    pointerEvents: 'none',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.05), transparent)',
    transform: 'translateX(-100%)',
    transition: 'transform 0.5s ease',
  },
  boxShadow: '0 4px 15px rgba(0,0,0,0.2), inset 0 0 30px rgba(26,35,126,0.2)',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0,0,0,0.3), inset 0 0 35px rgba(26,35,126,0.3)',
    '&:after': {
      transform: 'translateX(100%)',
      transition: 'transform 1s ease',
    }
  },
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    borderRadius: '8px',
    height: '180px', // Fixed height for mobile
  }
}));

// Expanded card with fixed height
const ExpandedBlogCard = styled(motion(Box))(({ theme }) => ({
  backgroundColor: '#1a237e',
  padding: '20px',
  borderRadius: '16px',
  marginBottom: '20px',
  color: '#FFFFFF',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',
  // Fixed height for expanded state - all cards will be exactly the same height
  height: '550px',
  background: `
    linear-gradient(135deg, 
      rgba(26, 35, 126, 0.95) 0%,
      rgba(21, 101, 192, 0.9) 100%
    )
  `,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      repeating-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.03) 0px,
        rgba(255, 255, 255, 0.03) 1px,
        transparent 1px,
        transparent 2px
      )
    `,
    pointerEvents: 'none',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.05), transparent)',
    transform: 'translateX(-100%)',
    transition: 'transform 0.5s ease',
  },
  boxShadow: '0 4px 15px rgba(0,0,0,0.2), inset 0 0 30px rgba(26,35,126,0.2)',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0,0,0,0.3), inset 0 0 35px rgba(26,35,126,0.3)',
    '&:after': {
      transform: 'translateX(100%)',
      transition: 'transform 1s ease',
    }
  },
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    borderRadius: '8px',
    height: '450px', // Fixed height for mobile expanded state
  }
}));

// Content wrapper to ensure proper layout
const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

// Expanded content wrapper with scrollable area if needed
const ExpandedContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  // Add a subtle scrollbar for overflow content
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0,0,0,0.1)',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255,255,255,0.2)',
    borderRadius: '3px',
  }
}));

// Constrained image height
const BlogImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '180px', // Fixed height for image
  objectFit: 'cover',
  borderRadius: '8px',
  border: '1px solid #FFFFFF',
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    height: '120px', // Smaller fixed height on mobile
  }
}));

const BlogTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.4rem',
  fontWeight: 'bold',
  color: '#FFFFFF',
  marginBottom: '8px',
  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
  // Ensure title doesn't break layout
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
    WebkitLineClamp: 1, // Single line on mobile
  },
}));

const BlogMetadata = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  color: '#FFE873',
  marginBottom: '8px',
  textShadow: '0 0 8px rgba(255,216,0,0.3)',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
  },
}));

// Preview text with strict line limits
const BlogPreview = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: 'rgba(255, 255, 255, 0.9)',
  marginTop: '12px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2, // Strict 2 lines 
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  lineHeight: '1.5',
  flexGrow: 1, // Take available space
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
    WebkitLineClamp: 2,
    lineHeight: '1.4',
  },
}));

// Text content in expanded view with fixed dimensions
const ExpandedText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  lineHeight: 1.6,
  fontSize: '1.1rem',
  height: '150px', // Fixed height
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 5, // Limit to 5 lines
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.95rem',
    height: '120px',
    WebkitLineClamp: 4, // Fewer lines on mobile
  },
}));

// Read more link
const ReadMoreLink = styled(Box)(({ theme }) => ({
  color: '#FFE873',
  marginTop: '16px',
  cursor: 'pointer',
  textAlign: 'center',
  position: 'absolute',
  bottom: '16px',
  width: 'calc(100% - 60px)', // Account for padding
  '&:hover': { 
    opacity: 0.8 
  },
  [theme.breakpoints.down('sm')]: {
    bottom: '10px',
    width: 'calc(100% - 40px)',
    fontSize: '0.9rem',
  },
}));

// Ellipsis with fixed positioning
const EllipsisIndicator = styled(Box)(({ theme }) => ({
  color: '#FFE873',
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  cursor: 'pointer',
  '&:hover': { opacity: 0.8 },
  [theme.breakpoints.down('sm')]: {
    bottom: '12px',
    right: '12px',
  },
}));

// Function to truncate text to a specific length
const truncateText = (text, maxLength) => {
  if (!text || text.length <= maxLength) return text || '';
  return text.substring(0, maxLength);
};

const NewsAndBlogs = () => {
  const [expandedIds, setExpandedIds] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const blogs = [
    {
      id: 1,
      title: "World Liberty Financial released Worldwide",
      date: "January, 2025",
      tagline: "Defi & Investments",
      preview: truncateText("President Donald J. Trump & sons release revolutionary WLFI Token Sale in 2024", 80),
      firstParagraph: "Welcome to Satoshi Advance News, where we spotlight the boldest trends pushing the boundaries of bitcoin and crypto financing. Get ready for an adrenaline-charged journey into the 2024 launch of Donald Trump's World Liberty Financial (WLFI) token,",
      image: `${MEDIA_BASE_URL}/WLFIHeader1.png`,
      slug: "world-liberty-financial"
    },
    {
      id: 2,
      title: "Why Dogecoin Will Go to the Moon (or at Least $10)",
      date: "Feb 1, 2025",
      tagline: "Crypto & Memecoins",
      preview: truncateText("The original memecoin that keeps on winning—could Dogecoin reach $10?", 80),
      firstParagraph: "If there's one cryptocurrency that can put a genuine smile on your face—and possibly make your wallet grin too—it's Dogecoin. As the original memecoin and undeniably the most adorable digital currency around, Dogecoin has charmed the crypto world with its trademark Shiba Inu logo...",
      image: `${MEDIA_BASE_URL}/NewsAndBlogs2.png`,
      slug: "dogecoin-moon"
    },
    {
      id: 3,
      title: "Empowering Your Financial Future in the Crypto Era",
      date: "February 2025",
      tagline: "Defi & Investments",
      preview: truncateText("Discover how Satoshi Advance connects borrowers with crypto lenders, provides interactive tools, and empowers you with knowledge for a brighter financial future.", 80),
      firstParagraph: "Introducing Satoshi Advance: Empowering Your Financial Future in the Crypto Era. Welcome to Satoshi Advance—your one-stop hub for exploring cryptocurrency borrowing and lending opportunities. In a world where traditional currencies like the USD can lose value, cryptocurrencies such as Bitcoin—and even its smallest unit, the Satoshi—are on the rise.",
      image: `${MEDIA_BASE_URL}/NewsAndBlogs3.png`,
      slug: "welcome-to-satoshi-advance"
    },
    {
      id: 4,
      title: "Flip into Fun with Bit Flip: A Modern Take on the Classic Heads or Tails",
      date: "February 2025",
      tagline: "Crypto Gaming",
      preview: truncateText("Are you looking for a fun, hassle-free way to make quick decisions, settle friendly wagers, or just spice up game night? Yes! Then check out Bit Flip on SatoshiAdvance.com.", 80),
      firstParagraph: "Flip into Fun with Bit Flip: A Modern Take on the Classic Heads or Tails. Enjoy a virtual coin toss that brings modern features like voice-activated guessing, retro music, and interactive gameplay to the timeless coin flip experience.",
      image: `${MEDIA_BASE_URL}/NewsAndBlogs4.png`,
      slug: "coin-flip-heads-or-tails"
    },
  ];

  const handleCardClick = (id) => {
    if (expandedIds.includes(id)) {
      setExpandedIds(expandedIds.filter(expandedId => expandedId !== id));
    } else {
      setExpandedIds([...expandedIds, id]);
    }
  };

  const handleReadMore = (blogSlug, e) => {
    e.stopPropagation();
    navigate(`/info-center/${blogSlug}`);
  };

  return (
    <div>
      <SectionTitle>News & Blogs</SectionTitle>

      <OrangeDividerBar
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 2, ease: "easeInOut" }}
      />

      <Box sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
        gap: { xs: 2, md: 3 },
        padding: { xs: 2, md: 4 },
        maxWidth: '1400px',
        margin: '0 auto',
        alignItems: 'start'
      }}>
        {blogs.map((blog, index) => {
          // Completely different component rendering based on expanded state
          return expandedIds.includes(blog.id) ? (
            // EXPANDED CARD - fixed height with internal scroll if needed
            <ExpandedBlogCard
              key={blog.id}
              onClick={() => handleCardClick(blog.id)}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <ExpandedContentWrapper>
                {/* Header content */}
                <BlogTitle>{blog.title}</BlogTitle>
                <BlogMetadata>{blog.tagline} • {blog.date}</BlogMetadata>
                
                {/* Fixed height image */}
                <BlogImage src={blog.image} alt={blog.title} />
                
                {/* Content box with fixed dimensions */}
                <Box sx={{
                  mt: 2,
                  p: 2,
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '8px',
                  position: 'relative',
                  flexGrow: 1,
                }}>
                  <ExpandedText>
                    {blog.firstParagraph}
                  </ExpandedText>
                  
                  {/* Absolutely positioned read more link */}
                  <ReadMoreLink onClick={(e) => handleReadMore(blog.slug, e)}>
                    Read More...
                  </ReadMoreLink>
                </Box>
              </ExpandedContentWrapper>
            </ExpandedBlogCard>
          ) : (
            // COLLAPSED CARD - fixed height
            <BlogCard
              key={blog.id}
              onClick={() => handleCardClick(blog.id)}
              initial={{ opacity: 0, x: index % 2 === 0 ? '-100vw' : '100vw', rotateY: -90 }}
              animate={{ opacity: 1, x: 0, rotateY: 0 }}
              transition={{ duration: 1, delay: index * 0.3, type: 'spring' }}
            >
              <ContentWrapper>
                <BlogTitle>{blog.title}</BlogTitle>
                <BlogMetadata>{blog.tagline} • {blog.date}</BlogMetadata>
                <BlogPreview>{blog.preview}</BlogPreview>
                <EllipsisIndicator>...</EllipsisIndicator>
              </ContentWrapper>
            </BlogCard>
          );
        })}
      </Box>
    </div>
  );
};

export default NewsAndBlogs;

