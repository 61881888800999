import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ArrowRight } from 'lucide-react';
import axios from 'axios';

// Add this with your other imports at the top of LenderBorrowerApply.js


const TextMotion = styled(motion.span)({
    display: 'inline-block'
});

const IconMotion = styled(motion.div)({
    display: 'inline-flex',
    alignItems: 'center'
});

const RetroTextField = styled(motion.input)(({ theme }) => ({
    width: '100%',
    padding: '12px',
    backgroundColor: 'transparent',
    border: '2px solid #FFFFFF',
    borderRadius: '4px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontFamily: "'Press Start 2P', cursive",
    boxSizing: 'border-box', // Ensure borders are included in the width
    '&::placeholder': {
        color: 'rgba(255, 255, 255, 0.7)',
    },
    outline: 'none',
    marginBottom: '15px',
    transition: 'all 0.3s ease',
    '&:focus': {
        borderColor: '#00BFFF',
        boxShadow: `
            0 0 10px #FFFFFF,
            0 0 20px #00BFFF,
            0 0 30px #00BFFF
        `,
    }
}));

const RetroFieldLabel = styled(Typography)({
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '14px',
    color: '#FFFFFF',
    marginBottom: '10px',
    textAlign: 'center'
});

const RetroRadioGroup = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
});

const RetroChoicesContainer = styled(Box)({
    display: 'flex',
    gap: '4px',
    justifyContent: 'center',
});

const RetroRadioButton = styled(motion.button)(({ selected }) => ({
    background: 'transparent',
    border: '2px solid #FFFFFF',
    padding: '8px 16px',
    color: '#FFFFFF',
    fontSize: '14px',
    fontFamily: "'Press Start 2P', cursive",
    cursor: 'pointer',
    position: 'relative',
    textShadow: selected ? `
        0 0 10px #00BFFF,
        0 0 20px #00BFFF
    ` : 'none',
    boxShadow: selected ? `
        0 0 10px #00BFFF,
        0 0 20px #00BFFF
    ` : 'none',
    '&:first-of-type': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderRight: '2px solid #FFFFFF',
        marginRight: '-2px', // Compensate for the double border
    },
    '&:last-of-type': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
    },
    '&:hover': {
        textShadow: `
            0 0 10px #00BFFF,
            0 0 20px #00BFFF
        `,
        boxShadow: `
            0 0 10px #00BFFF,
            0 0 20px #00BFFF
        `,
    }
}));


const RetroTextArea = styled(motion.textarea)(({ theme }) => ({
    width: '100%',
    padding: '12px',
    backgroundColor: 'transparent',
    border: '2px solid #FFFFFF',
    borderRadius: '4px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontFamily: "'Press Start 2P', cursive",
    resize: 'none',
    minHeight: '100px',
    '&::placeholder': {
        color: 'rgba(255, 255, 255, 0.7)',
    },
    outline: 'none',
    marginBottom: '15px',
    transition: 'all 0.3s ease',
    boxSizing: 'border-box',
    '&:focus': {
        borderColor: '#00BFFF',
        boxShadow: `
            0 0 10px #FFFFFF,
            0 0 20px #00BFFF,
            0 0 30px #00BFFF
        `
    }
}));




const SubmitButton = ({ mode, isClicked, onClick, formStep }) => {
    const getTextParts = () => {
        switch (formStep) {
            case 1:
                return mode === 'borrow'
                    ? { first: 'APPLY', second: 'NOW' }
                    : { first: 'FIND', second: 'BORROWERS' };
            case 2:
                return { first: 'FINISH &', second: 'SUBMIT' };
            case 3:
                return { first: 'SUBMIT', second: '' }; // Only one word for final step
            default:
                return { first: '', second: '' };
        }
    };

    const textParts = getTextParts();

    return (
        <motion.button
            onClick={onClick}
            style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '15px',
                padding: '20px',
                margin: '0 auto',
                color: '#FFFFFF',
                fontSize: '24px',
                fontFamily: "'Press Start 2P', cursive",
                textShadow: `
                    0 0 10px #00BFFF,
                    0 0 20px #00BFFF,
                    0 0 30px #00BFFF
                `,
            }}
            whileHover={{
                scale: 1.1,
                rotateX: 360,
                transition: {
                    duration: 0.6,
                    ease: "easeInOut"
                }
            }}
            whileTap={{ scale: 0.95 }}
        >
            <TextMotion
                animate={isClicked ? {
                    x: -1000,
                    opacity: 0,
                    transition: {
                        duration: 0.5,
                        ease: [0.2, 0.65, 0.3, 0.9]
                    }
                } : {
                    x: 0,
                    opacity: 1
                }}
            >
                {textParts.first}
            </TextMotion>

            {textParts.second && (
                <>
                    <span style={{ width: '10px' }}></span>
                    <TextMotion
                        animate={isClicked ? {
                            x: 1000,
                            opacity: 0,
                            transition: {
                                duration: 0.5,
                                ease: [0.2, 0.65, 0.3, 0.9]
                            }
                        } : {
                            x: 0,
                            opacity: 1
                        }}
                    >
                        {textParts.second}
                    </TextMotion>
                </>
            )}

            <IconMotion
                animate={isClicked ? {
                    x: 1000,
                    scale: 2,
                    opacity: 0,
                    transition: {
                        duration: 0.5,
                        scale: {
                            duration: 0.3,
                            ease: "easeOut"
                        },
                        ease: [0.2, 0.65, 0.3, 0.9]
                    }
                } : {
                    x: 0,
                    scale: 1,
                    opacity: 1
                }}
            >
                <ArrowRight
                    size={32}
                    style={{
                        filter: `drop-shadow(0 0 10px #00BFFF)
                                drop-shadow(0 0 20px #00BFFF)`,
                    }}
                />
            </IconMotion>
        </motion.button>
    );
};

const ToggleBar = styled(Box)(({ theme }) => ({
    width: '90%',
    maxWidth: '1200px',
    height: '6px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    position: 'relative',
    margin: '20px auto 40px',
    borderRadius: '3px',
    '@media (max-width: 600px)': {
        width: '100%',
        maxWidth: '300px',
    },
}));

const GlowingSlider = styled(motion.div)({
    position: 'absolute',
    top: '-3px',
    height: '12px',
    width: '50%',
    borderRadius: '6px',
    background: 'linear-gradient(90deg, rgba(255,255,255,0.9), rgba(255,255,255,1))',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        borderRadius: '6px',
        animation: 'pulse 2s infinite',
        boxShadow: `
            0 0 15px #FFFFFF,
            0 0 30px #00BFFF,
            0 0 45px #00BFFF,
            0 0 60px #00BFFF
        `,
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        borderRadius: '6px',
        background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)',
        animation: 'shimmer 2s infinite',
    },
    '@keyframes pulse': {
        '0%': {
            boxShadow: `
                0 0 15px #FFFFFF,
                0 0 30px #00BFFF,
                0 0 45px #00BFFF,
                0 0 60px #00BFFF
            `
        },
        '50%': {
            boxShadow: `
                0 0 20px #FFFFFF,
                0 0 40px #00BFFF,
                0 0 60px #00BFFF,
                0 0 80px #00BFFF
            `
        },
        '100%': {
            boxShadow: `
                0 0 15px #FFFFFF,
                0 0 30px #00BFFF,
                0 0 45px #00BFFF,
                0 0 60px #00BFFF
            `
        }
    },
    '@keyframes shimmer': {
        '0%': { transform: 'translateX(-100%)' },
        '100%': { transform: 'translateX(100%)' }
    }
});

const ToggleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    maxWidth: '800px',
    margin: '0 auto',
    '@media (max-width: 600px)': {
        width: '100%',
        maxWidth: '300px',
    },
}));

const ToggleText = styled(Typography)(({ active }) => ({
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '28px',
    color: active ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    textShadow: active ? `
        0 0 10px #FFFFFF,
        0 0 20px #00BFFF,
        0 0 30px #00BFFF
    ` : 'none',
    '&:hover': {
        color: '#FFFFFF',
        textShadow: `
            0 0 10px #FFFFFF,
            0 0 20px #00BFFF,
            0 0 30px #00BFFF
        `
    }
}));

const LenderBorrowerApply = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [mode, setMode] = useState('borrow');
    const [isClicked, setIsClicked] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        amount: '',
        purpose: '',
        country: ''
    });

    const [formStep, setFormStep] = useState(1);
    const [extendedFormData, setExtendedFormData] = useState({
        name: '',
        company: '',
        cryptoCollateral: '',
        lendingExperience: '',
        fiatCollateral: ''
    });

    const [finalFormData, setFinalFormData] = useState({
        hasProperty: '',
        otherAssets: '',
        address: '',
        phone: '',
        description: ''
    });


    // Add this helper function
    const handleRadioSelect = (field, value) => {
        setExtendedFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isClicked) {
            setIsClicked(true);

            // Wait for animation to complete before submitting
            setTimeout(async () => {
                try {
                    const response = await fetch('/api/apply', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ...formData,
                            type: mode
                        }),
                    });

                    if (!response.ok) throw new Error('Failed to submit application');
                    setFormData({ email: '', amount: '', purpose: '', country: '' });
                    alert('Application submitted successfully!');
                } catch (error) {
                    console.error('Error:', error);
                    alert('Failed to submit application. Please try again.');
                }
                setIsClicked(false);
            }, 500); // Wait for shoot animation to complete
        }
    };

    const handleStepSubmit = () => {
        if (formStep === 1) {
            setIsClicked(true);
            setTimeout(() => {
                setFormStep(2);
                setIsClicked(false);
            }, 800);
        } else if (formStep === 2) {
            setIsClicked(true);
            setTimeout(() => {
                setFormStep(3);
                setIsClicked(false);
            }, 800);
        } else {
            handleFinalSubmit();
        }
    };

    const handleFinalSubmit = async () => {
        setIsClicked(true);

        try {
            // Call signup endpoint with email and application data
            const response = await axios.post('/api/signup', {
                email: formData.email,
                applicationData: {
                    ...formData,
                    ...extendedFormData,
                    ...finalFormData,
                    type: mode
                }
            });

            if (response.status === 201 || response.status === 200) {
                setIsSubmitted(true);  // This will trigger your existing success message
            }
        } catch (error) {
            console.error('Submission error:', error);
            alert(error.response?.data?.error || 'Failed to submit application. Please try again.');
            setIsClicked(false);
        }
    };




    const renderThirdStep = () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {!isSubmitted ? (
                <>
                    {/* Own Property Selection */}
                    <motion.div
                        initial={{ x: '100%', opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: '-100%', opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <RetroRadioGroup>
                            <RetroFieldLabel>
                                {mode === 'borrow' ? 'OWN PROPERTY' : 'REAL ESTATE'}
                            </RetroFieldLabel>
                            <RetroChoicesContainer>
                                <RetroRadioButton
                                    selected={finalFormData.hasProperty === 'yes'}
                                    onClick={() => setFinalFormData({ ...finalFormData, hasProperty: 'yes' })}
                                    type="button"
                                >
                                    YES
                                </RetroRadioButton>
                                <RetroRadioButton
                                    selected={finalFormData.hasProperty === 'no'}
                                    onClick={() => setFinalFormData({ ...finalFormData, hasProperty: 'no' })}
                                    type="button"
                                >
                                    NO
                                </RetroRadioButton>
                            </RetroChoicesContainer>
                        </RetroRadioGroup>
                    </motion.div>

                    {/* Other Assets Selection */}
                    <motion.div
                        initial={{ x: '-100%', opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: '100%', opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                    >
                        <RetroRadioGroup>
                            <RetroFieldLabel>
                                OTHER ASSETS
                            </RetroFieldLabel>
                            <RetroChoicesContainer>
                                <RetroRadioButton
                                    selected={finalFormData.otherAssets === 'yes'}
                                    onClick={() => setFinalFormData({ ...finalFormData, otherAssets: 'yes' })}
                                    type="button"
                                >
                                    YES
                                </RetroRadioButton>
                                <RetroRadioButton
                                    selected={finalFormData.otherAssets === 'no'}
                                    onClick={() => setFinalFormData({ ...finalFormData, otherAssets: 'no' })}
                                    type="button"
                                >
                                    NO
                                </RetroRadioButton>
                            </RetroChoicesContainer>
                        </RetroRadioGroup>
                    </motion.div>

                    {/* Address Input */}
                    <motion.div
                        initial={{ x: '100%', opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: '-100%', opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <RetroTextField
                            type="text"
                            placeholder="Address"
                            value={finalFormData.address}
                            onChange={(e) => setFinalFormData({ ...finalFormData, address: e.target.value })}
                            required
                        />
                    </motion.div>

                    {/* Phone Input */}
                    <motion.div
                        initial={{ x: '-100%', opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: '100%', opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                    >
                        <RetroTextField
                            type="tel"
                            placeholder="Phone"
                            value={finalFormData.phone}
                            onChange={(e) => setFinalFormData({ ...finalFormData, phone: e.target.value })}
                            required
                        />
                    </motion.div>

                    {/* Detailed Description Input */}
                    <motion.div
                        initial={{ x: '100%', opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: '-100%', opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        <RetroTextArea
                            placeholder="Detailed Description"
                            value={finalFormData.description}
                            onChange={(e) => {
                                if (e.target.value.length <= 120) {
                                    setFinalFormData({ ...finalFormData, description: e.target.value });
                                }
                            }}
                            maxLength={120}
                            required
                        />
                    </motion.div>

                    {/* Sparkling Flash Animation */}
                    {isClicked && (
                        <motion.div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                background: 'linear-gradient(to right, transparent, #FFFFFF, transparent)',
                                opacity: 0,
                                zIndex: 10
                            }}
                            animate={{
                                opacity: [0, 1, 0],
                                x: ['-100%', '100%'],
                            }}
                            transition={{ duration: 0.8 }}
                        />
                    )}
                </>
            ) : (
                // Success Message
                <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                        duration: 0.5,
                        delay: 0.3,
                        ease: "easeOut",
                    }}
                    style={{
                        textAlign: 'center',
                        padding: '40px 20px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "'Press Start 2P', cursive",
                            fontSize: '20px',
                            color: '#FFFFFF',
                            textShadow: `
                                0 0 10px #FFFFFF,
                                0 0 20px #00BFFF,
                                0 0 30px #00BFFF
                            `,
                            lineHeight: '1.5',
                        }}
                    >
                        🎉 Success! Check your email to verify your account & login 🎉
                    </Typography>
                </motion.div>
            )}
        </Box>
    );


    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: { xs: '100%', md: '1200px' }, // Use full width on mobile
                mx: 'auto',
                px: { xs: 1, md: 2 }                   // Reduce side padding on mobile
            }}
        >

            <Box sx={{ position: 'relative', mb: 6, textAlign: 'center', mt: 4 }}>
                <ToggleContainer>
                    <ToggleText
                        active={mode === 'borrow'}
                        onClick={() => setMode('borrow')}
                    >
                        BORROW
                    </ToggleText>
                    <ToggleText
                        active={mode === 'lend'}
                        onClick={() => setMode('lend')}
                    >
                        LEND
                    </ToggleText>
                </ToggleContainer>

                <ToggleBar>
                    <GlowingSlider
                        initial={false}
                        animate={{
                            x: mode === 'borrow' ? '0%' : '100%'
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 25,
                            mass: 1
                        }}
                    />
                </ToggleBar>
            </Box>

            <Box sx={{ maxWidth: '600px', mx: 'auto', overflow: 'hidden' }}>
                <form onSubmit={(e) => e.preventDefault()}>
                    {formStep === 1 && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <motion.div
                                animate={isClicked ? { x: '-100%', opacity: 0 } : { x: 0, opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                <RetroTextField
                                    type="email"
                                    placeholder="Email Address"
                                    value={formData.email}
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    required
                                />
                            </motion.div>

                            <motion.div
                                animate={isClicked ? { x: '100%', opacity: 0 } : { x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.1 }}
                            >
                                <RetroTextField
                                    type="number"
                                    placeholder={`${mode === 'borrow' ? 'Loan' : 'Investment'} Amount`}
                                    value={formData.amount}
                                    onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                                    required
                                />
                            </motion.div>

                            <motion.div
                                animate={isClicked ? { x: '-100%', opacity: 0 } : { x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <RetroTextField
                                    type="text"
                                    placeholder={mode === 'borrow' ? 'Loan Purpose' : 'Investment Strategy'}
                                    value={formData.purpose}
                                    onChange={(e) => setFormData({ ...formData, purpose: e.target.value })}
                                    required
                                />
                            </motion.div>

                            <motion.div
                                animate={isClicked ? { x: '100%', opacity: 0 } : { x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                            >
                                <RetroTextField
                                    type="text"
                                    placeholder="Country"
                                    value={formData.country}
                                    onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                                    required
                                />
                            </motion.div>
                        </Box>
                    )}

                    {formStep === 2 && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <motion.div
                                initial={{ x: '100%', opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                <RetroTextField
                                    type="text"
                                    placeholder="Name"
                                    value={extendedFormData.name}
                                    onChange={(e) => setExtendedFormData({ ...extendedFormData, name: e.target.value })}
                                    required
                                />
                            </motion.div>

                            <motion.div
                                initial={{ x: '-100%', opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.1 }}
                            >
                                <RetroTextField
                                    type="text"
                                    placeholder="Company"
                                    value={extendedFormData.company}
                                    onChange={(e) => setExtendedFormData({ ...extendedFormData, company: e.target.value })}
                                    required
                                />
                            </motion.div>

                            <motion.div
                                initial={{ x: '100%', opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <RetroRadioGroup>
                                    <RetroFieldLabel>
                                        CRYPTO COLLATERAL
                                    </RetroFieldLabel>
                                    <RetroChoicesContainer>
                                        <RetroRadioButton
                                            selected={extendedFormData.cryptoCollateral === 'yes'}
                                            onClick={() => handleRadioSelect('cryptoCollateral', 'yes')}
                                            type="button"
                                        >
                                            YES
                                        </RetroRadioButton>
                                        <RetroRadioButton
                                            selected={extendedFormData.cryptoCollateral === 'no'}
                                            onClick={() => handleRadioSelect('cryptoCollateral', 'no')}
                                            type="button"
                                        >
                                            NO
                                        </RetroRadioButton>
                                    </RetroChoicesContainer>
                                </RetroRadioGroup>
                            </motion.div>

                            {mode === 'lend' ? (
                                <motion.div
                                    initial={{ x: '-100%', opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.5, delay: 0.3 }}
                                >
                                    <RetroTextField
                                        type="number"
                                        placeholder="Years of Lending Experience"
                                        value={extendedFormData.lendingExperience}
                                        onChange={(e) => setExtendedFormData({ ...extendedFormData, lendingExperience: e.target.value })}
                                        required
                                    />
                                </motion.div>
                            ) : (
                                <motion.div
                                    initial={{ x: '-100%', opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.5, delay: 0.3 }}
                                >
                                    <RetroTextField
                                        type="number"
                                        placeholder="Annual Income"
                                        value={extendedFormData.annualIncome}
                                        onChange={(e) => setExtendedFormData({ ...extendedFormData, annualIncome: e.target.value })}
                                        required
                                    />
                                </motion.div>
                            )}

                            <motion.div
                                initial={{ x: '100%', opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.4 }}
                            >
                                <RetroRadioGroup>
                                    <RetroFieldLabel>
                                        FIAT COLLATERAL
                                    </RetroFieldLabel>
                                    <RetroChoicesContainer>
                                        <RetroRadioButton
                                            selected={extendedFormData.fiatCollateral === 'yes'}
                                            onClick={() => handleRadioSelect('fiatCollateral', 'yes')}
                                            type="button"
                                        >
                                            YES
                                        </RetroRadioButton>
                                        <RetroRadioButton
                                            selected={extendedFormData.fiatCollateral === 'no'}
                                            onClick={() => handleRadioSelect('fiatCollateral', 'no')}
                                            type="button"
                                        >
                                            NO
                                        </RetroRadioButton>
                                    </RetroChoicesContainer>
                                </RetroRadioGroup>
                            </motion.div>
                        </Box>
                    )}

                    {formStep === 3 && renderThirdStep()}

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 4
                    }}>
                        <SubmitButton
                            mode={mode}
                            isClicked={isClicked}
                            formStep={formStep}
                            onClick={() => {
                                if (formStep === 1) {
                                    setIsClicked(true);
                                    setTimeout(() => {
                                        setFormStep(2);
                                        setIsClicked(false);
                                    }, 800);
                                } else if (formStep === 2) {
                                    setIsClicked(true);
                                    setTimeout(() => {
                                        setFormStep(3);
                                        setIsClicked(false);
                                    }, 800);
                                } else {
                                    handleFinalSubmit();
                                }
                            }}
                        />
                    </Box>
                </form>
            </Box>
        </Box>
    );

};
export default LenderBorrowerApply;