import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, InputAdornment, Tab, Tabs, Divider } from '@mui/material';
import { styled } from '@mui/system';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';
import PetsIcon from '@mui/icons-material/Pets';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

axios.defaults.baseURL = window.location.hostname === 'localhost'
  ? 'http://localhost:5000' // Development
  : 'https://www.satoshiadvance.com'; // Production

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  padding: '20px',
  backgroundColor: '#1a237e',
  borderRadius: '10px',
  textAlign: 'center',
  zIndex: 10000,
  boxShadow: '0px 0px 20px 0px #00BFFF',
  maxHeight: '90vh',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  '@media (max-width: 600px)': {
    padding: '15px',
    width: '85%'
  }
}));

const Overlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 9999,
});

const StyledTabs = styled(Tabs)({
  marginBottom: '20px',
  '& .MuiTab-root': {
    color: '#FFFFFF',
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '0.8rem',
    opacity: 0.7,
    '&.Mui-selected': {
      color: '#00BFFF',
      opacity: 1,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#00BFFF',
  },
});

const RetroTitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '1.8rem',
  textShadow: `0 0 10px #FFFFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF`,
  fontFamily: "'Press Start 2P', cursive",
  marginBottom: '15px',
  '@media (max-width: 600px)': {
    fontSize: '1.4rem',
  }
}));

const PromotionalText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '1rem',
  fontFamily: "'Roboto', sans-serif",
  fontWeight: '500',
  letterSpacing: '0.5px',
  marginBottom: '20px',
  textAlign: 'center',
  width: '100%',
  '@media (max-width: 600px)': {
    fontSize: '0.9rem'
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#283593',
  borderRadius: '5px',
  marginBottom: '15px',
  input: {
    color: '#FFFFFF',
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '0.9rem',
    '@media (max-width: 600px)': {
      fontSize: '0.8rem',
    }
  },
}));

const PlanOption = styled(Box)(({ theme, selected }) => ({
  backgroundColor: selected ? '#00BFFF' : '#283593',
  color: '#FFFFFF',
  padding: '15px',
  borderRadius: '8px',
  margin: '8px 0',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: selected ? '#00BFFF' : '#3949ab',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: selected ? '0 0 15px #00BFFF' : 'none',
}));

const PlanTitle = styled(Typography)({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '0.9rem',
  marginBottom: '8px',
  textAlign: 'center'
});

const PlanPrice = styled(Typography)({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '1.1rem',
  marginBottom: '5px',
  textAlign: 'center'
});

const PlanDescription = styled(Typography)({
  fontSize: '0.8rem',
  opacity: 0.9,
  textAlign: 'center'
});

const AuthPopup = ({ 
  onClose, 
  fromDogCards = false, 
  fromInfoCenter = false,
  openPaymentPopup = null
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState('form');
  const [error, setError] = useState('');
  const [mode, setMode] = useState(fromDogCards || fromInfoCenter ? 'signup' : 'login');
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  
  // Get the updateAuthState function from context
  const { updateAuthState } = useAuth();

  const handleTabChange = (event, newMode) => {
    setMode(newMode);
    setError('');
    setStep('form');
  };

  const handleEmailSubmit = async () => {
    try {
      if (mode === 'login') {
        const response = await axios.post('/api/login', { email, password });

        if (response.status === 200) {
          // Store user data consistently across storage methods
          const userData = {
            email: response.data.email,
            isVerified: response.data.isVerified,
            lastLogin: new Date().toISOString()
          };

          // Store in both localStorage and sessionStorage
          localStorage.setItem('userEmail', response.data.email);
          sessionStorage.setItem('userEmail', response.data.email);
          localStorage.setItem('user', JSON.stringify(userData));
          sessionStorage.setItem('user', JSON.stringify(userData));

          document.cookie = `userEmail=${response.data.email}; domain=.satoshiadvance.com; path=/; max-age=86400`;

          console.log('Auth state stored:', {
            email: response.data.email,
            localStorage: localStorage.getItem('userEmail'),
            sessionStorage: sessionStorage.getItem('userEmail'),
            timestamp: new Date().toISOString()
          });

          // Update auth state in context
          updateAuthState(response.data.email);

          // If we came from InfoCenter and user is already logged in,
          // we can show subscription options
          if (fromInfoCenter) {
            setStep('subscription');
          } else {
            // Close popup without page reload
            onClose();
          }
        }
      } else {
        // Signup flow
        if (!email) {
          setError('Please enter an email address');
          return;
        }

        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          setError('Please enter a valid email address');
          return;
        }

        // If we have a subscription plan selected and we're from the InfoCenter
        if (fromInfoCenter && subscriptionPlan) {
          // Store the email for later registration
          sessionStorage.setItem('pendingSignupEmail', email);
          
          // Close this popup and open payment popup with the selected amount
          if (openPaymentPopup) {
            let amount = 0;
            if (subscriptionPlan === 'premium-monthly') amount = 5;
            if (subscriptionPlan === 'premium-annual') amount = 15;
            
            onClose();
            openPaymentPopup(amount);
            return;
          }
        }

        const response = await axios.post('/api/signup', { email });

        if (response.status === 201 || response.status === 200) {
          // Store signup email temporarily
          sessionStorage.setItem('pendingSignupEmail', email);
          
          // For InfoCenter flow, show subscription options after signup
          if (fromInfoCenter) {
            setStep('subscription');
          } else {
            setStep('checkEmail');
          }
          
          setError('');
        }
      }
    } catch (error) {
      console.error('Auth error:', {
        mode,
        error: error.message,
        response: error.response?.data
      });

      // Handle specific error cases
      if (error.response?.status === 401) {
        setError('Invalid email or password');
      } else if (error.response?.status === 429) {
        setError('Too many attempts. Please try again later');
      } else {
        setError(
          error.response?.data?.error ||
          `Failed to ${mode === 'login' ? 'login' : 'send verification email'}`
        );
      }
    }
  };

  const handleSubscriptionSelect = (plan) => {
    setSubscriptionPlan(plan);
  };

  const handleSubscriptionSubmit = () => {
    if (!subscriptionPlan) {
      setError('Please select a subscription plan');
      return;
    }

    if (openPaymentPopup) {
      let amount = 0;
      if (subscriptionPlan === 'premium-monthly') amount = 5;
      if (subscriptionPlan === 'premium-annual') amount = 15;
      
      onClose();
      openPaymentPopup(amount);
    } else {
      setError('Payment system is currently unavailable');
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const response = await axios.post('/api/google-auth', {
        token: credentialResponse.credential,
        mode: mode
      });

      if (response.status === 200) {
        // Store user data consistently
        const userData = {
          email: response.data.email,
          isVerified: true
        };

        // Store in both localStorage and sessionStorage
        localStorage.setItem('userEmail', response.data.email);
        sessionStorage.setItem('userEmail', response.data.email);
        localStorage.setItem('user', JSON.stringify(userData));
        sessionStorage.setItem('user', JSON.stringify(userData));

        document.cookie = `userEmail=${response.data.email}; domain=.satoshiadvance.com; path=/; max-age=86400`;

        console.log('Google Auth stored:', {
          email: response.data.email,
          localStorage: localStorage.getItem('userEmail'),
          sessionStorage: sessionStorage.getItem('userEmail')
        });

        // Update auth state in context
        updateAuthState(response.data.email);

        // Handle welcome notification if needed
        if (mode === 'signup' && response.data.isNewUser === true) {
          localStorage.setItem('showWelcomeNotification', 'true');
        }

        // For InfoCenter flow, show subscription options after login/signup
        if (fromInfoCenter) {
          setStep('subscription');
        } else {
          // Close popup without page reload
          onClose();
        }
      }
    } catch (error) {
      console.error('Google auth error:', error);
      setError(`Failed to ${mode} with Google`);
    }
  };

  const handleGoogleError = () => {
    setError('Google authentication failed');
  };

  // Get route-specific promotional text
  const getPromotionalText = () => {
    if (fromDogCards) {
      return "Sign up for 25 free dog cards per month!";
    } else if (fromInfoCenter) {
      return "Get exclusive access to premium content and detailed cryptocurrency analysis!";
    }
    return "";
  };

  // Render content based on the current step
  const renderContent = () => {
    if (step === 'form') {
      return (
        <>
          <StyledTabs value={mode} onChange={handleTabChange} centered>
            <Tab value="login" label="Login" />
            <Tab value="signup" label="Sign Up" />
          </StyledTabs>

          {error && (
            <Typography sx={{
              color: '#ff4444',
              mb: 2,
              fontFamily: "'Press Start 2P', cursive",
              fontSize: '0.8rem'
            }}>
              {error}
            </Typography>
          )}

          <StyledTextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon sx={{ color: '#FFFFFF' }} />
                </InputAdornment>
              ),
            }}
          />
          
          {mode === 'login' && (
            <StyledTextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: '#FFFFFF' }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
          
          <Button
            onClick={handleEmailSubmit}
            sx={{
              backgroundColor: '#00BFFF',
              color: '#FFFFFF',
              fontFamily: "'Press Start 2P', cursive",
              textTransform: 'none',
              '&:hover': { backgroundColor: '#0095ff' },
              marginBottom: '15px',
              padding: '10px',
              fontSize: { xs: '0.8rem', sm: '1rem' }
            }}
            fullWidth
          >
            {mode === 'login' ? 'Login' : 'Sign Up'}
          </Button>

          <Box sx={{ width: '100%', mt: 2 }}>
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleError}
              type="standard"
              theme="filled_blue"
              size="large"
              text={mode === 'login' ? 'signin_with' : 'continue_with'}
              shape="rectangular"
              width="100%"
            />
          </Box>
        </>
      );
    } else if (step === 'checkEmail') {
      return (
        <Box sx={{ textAlign: 'center', color: '#FFFFFF' }}>
          <RetroTitle>Check Your Email</RetroTitle>
          <Typography sx={{
            mb: 3,
            fontFamily: "'Press Start 2P', cursive",
            fontSize: '0.8rem'
          }}>
            We sent a verification link to {email}
          </Typography>
          <Typography sx={{
            mb: 4,
            fontFamily: "'Press Start 2P', cursive",
            fontSize: '0.7rem',
            opacity: 0.8
          }}>
            Click the link in your email to verify your account and set your password
          </Typography>
          
          {fromDogCards && (
            <Typography sx={{
              mb: 4,
              fontFamily: "'Press Start 2P', cursive",
              fontSize: '0.7rem',
              color: '#FFD700',
              opacity: 0.9
            }}>
              Once verified, you'll get 25 free dog cards per month!
            </Typography>
          )}
          
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: '#00BFFF',
              color: '#FFFFFF',
              fontFamily: "'Press Start 2P', cursive",
              textTransform: 'none',
              '&:hover': { backgroundColor: '#0095ff' },
              padding: '10px',
              fontSize: { xs: '0.8rem', sm: '1rem' }
            }}
            fullWidth
          >
            Close
          </Button>
        </Box>
      );
    } else if (step === 'subscription') {
      return (
        <Box sx={{ textAlign: 'center', color: '#FFFFFF' }}>
          <RetroTitle>Choose Your Plan</RetroTitle>
          
          <Typography sx={{
            mb: 3,
            fontFamily: "'Press Start 2P', cursive",
            fontSize: '0.8rem'
          }}>
            Select a subscription option:
          </Typography>
          
          {error && (
            <Typography sx={{
              color: '#ff4444',
              mb: 2,
              fontFamily: "'Press Start 2P', cursive",
              fontSize: '0.8rem'
            }}>
              {error}
            </Typography>
          )}
          
          <PlanOption 
            selected={subscriptionPlan === 'free'} 
            onClick={() => handleSubscriptionSelect('free')}
          >
            <PlanTitle>Free Plan</PlanTitle>
            <PlanPrice>$0</PlanPrice>
            <PlanDescription>
              Basic access to info center
            </PlanDescription>
          </PlanOption>
          
          <PlanOption 
            selected={subscriptionPlan === 'premium-monthly'} 
            onClick={() => handleSubscriptionSelect('premium-monthly')}
          >
            <PlanTitle>Premium Monthly</PlanTitle>
            <PlanPrice>$5/month</PlanPrice>
            <PlanDescription>
              Advanced features and priority access
            </PlanDescription>
          </PlanOption>
          
          <PlanOption 
            selected={subscriptionPlan === 'premium-annual'} 
            onClick={() => handleSubscriptionSelect('premium-annual')}
          >
            <PlanTitle>Premium Annual</PlanTitle>
            <PlanPrice>$15/year</PlanPrice>
            <PlanDescription>
              Best value - save 75% compared to monthly
            </PlanDescription>
          </PlanOption>
          
          <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
            <Button
              onClick={() => subscriptionPlan === 'free' ? onClose() : handleSubscriptionSubmit()}
              sx={{
                backgroundColor: '#00BFFF',
                color: '#FFFFFF',
                fontFamily: "'Press Start 2P', cursive",
                textTransform: 'none',
                '&:hover': { backgroundColor: '#0095ff' },
                padding: '10px',
                fontSize: { xs: '0.8rem', sm: '1rem' },
                flex: 1
              }}
            >
              {subscriptionPlan === 'free' ? 'Continue Free' : 'Continue'}
            </Button>
            
            <Button
              onClick={onClose}
              sx={{
                backgroundColor: 'transparent',
                color: '#FFFFFF',
                border: '1px solid #FFFFFF',
                fontFamily: "'Press Start 2P', cursive",
                textTransform: 'none',
                '&:hover': { backgroundColor: 'rgba(255,255,255,0.1)' },
                padding: '10px',
                fontSize: { xs: '0.8rem', sm: '1rem' },
                flex: 1
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      <Overlay
        onClick={onClose}
        onKeyDown={(e) => e.key === 'Escape' && onClose()}
        tabIndex={0}
      />
      <PopupContainer onClick={(e) => e.stopPropagation()}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            color: '#FFFFFF'
          }}
        >
          <CloseIcon />
        </IconButton>

        <RetroTitle>
          {fromInfoCenter ? 'Satoshi Advance News' : fromDogCards ? 'Dog Cards' : 'Welcome'}
        </RetroTitle>

        {/* Show route-specific icon if applicable */}
        {(fromInfoCenter || fromDogCards) && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            {fromInfoCenter && (
              <NewspaperIcon sx={{ fontSize: 40, color: '#00BFFF' }} />
            )}
            {fromDogCards && (
              <PetsIcon sx={{ fontSize: 40, color: '#00BFFF' }} />
            )}
          </Box>
        )}

        {/* Promotional text based on route */}
        {getPromotionalText() && (
          <PromotionalText>
            {getPromotionalText()}
          </PromotionalText>
        )}

        {renderContent()}
      </PopupContainer>
    </>
  );
};

export default AuthPopup;