import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

/**
 * Detect iOS devices (which don't support beforeinstallprompt).
 */
function isIos() {
  const ua = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(ua);
}

const InstallAppButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showIosInstructions, setShowIosInstructions] = useState(false);

  useEffect(() => {
    // Listen for the 'beforeinstallprompt' event on Android/Desktop
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e); // Stash the event for later
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstallClick = async () => {
    if (isIos()) {
      // iOS -> show instructions
      setShowIosInstructions(true);
    } else if (deferredPrompt) {
      // Android/Desktop -> show the actual install prompt
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User response: ${outcome}`);
      setDeferredPrompt(null);
    } else {
      alert('Installation is not available at this time.');
    }
  };

  return (
    <Box>
      {/* Button styled to match the "About" link */}
      <Button
        onClick={handleInstallClick}
        endIcon={<DownloadIcon style={{ fontSize: '1.2rem' }} />}
        sx={{
          fontFamily: "'Roboto', sans-serif",
          fontSize: '19px',
          color: '#fff',
          textDecoration: 'none',
          textTransform: 'none',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          '&:hover': { color: '#ccc' },
        }}
      >
        App
      </Button>

      {/* iOS instructions dialog */}
      <Dialog open={showIosInstructions} onClose={() => setShowIosInstructions(false)}>
        <DialogTitle>Add App to Home Screen</DialogTitle>
        <DialogContent>
          <p>
            To install this app on your iOS device, tap the share icon at the bottom of your screen,
            then select "Add to Home Screen."
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowIosInstructions(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InstallAppButton;
