import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Button, 
  Checkbox, 
  FormControlLabel,
  Tooltip,
  TextField,
  InputAdornment
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { QRCodeCanvas } from 'qrcode.react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

// Existing styled components from your Dashboard
const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  padding: '20px',
  backgroundColor: '#1a237e',
  borderRadius: '10px',
  textAlign: 'center',
  zIndex: 10000,
  boxShadow: '0px 0px 20px 0px #00BFFF',
  maxHeight: '90vh',
  overflowY: 'auto',
  '@media (max-width: 600px)': {
    padding: '15px',
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const RetroTitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '1.8rem',
  textShadow: `0 0 10px #FFFFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF`,
  fontFamily: "'Press Start 2P', cursive",
  marginBottom: '20px',
  '@media (max-width: 600px)': {
    fontSize: '1.12rem',
    marginTop: '0.5in',
  },
}));

const StatCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 191, 255, 0.15)',
  padding: '20px',
  borderRadius: '10px',
  margin: '20px 0',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
}));

const StatValue = styled(Typography)({
  fontSize: '4rem',
  fontWeight: 'bold',
  color: '#50E3C2',
  textShadow: '0 0 10px rgba(80, 227, 194, 0.5)',
  fontFamily: "'Press Start 2P', cursive",
  margin: '15px 0',
});

const StatLabel = styled(Typography)({
  fontSize: '0.9rem',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  color: '#FFFFFF',
  fontFamily: "'Press Start 2P', cursive",
});

const DateTimeDisplay = styled(Typography)({
  fontSize: '0.8rem',
  color: '#FFFFFF',
  opacity: 0.7,
  marginTop: '15px',
  fontFamily: "'Press Start 2P', cursive",
});

// New styled components for PaymentPopup
const SummaryText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '1rem',
  marginBottom: '15px',
  '@media (max-width: 600px)': {
    fontSize: '0.9rem',
  },
}));

const CopyField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#283593',
  borderRadius: '5px',
  marginBottom: '15px',
  input: {
    color: '#FFFFFF',
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '0.9rem',
    '@media (max-width: 600px)': {
      fontSize: '0.8rem',
    },
  },
}));

const FlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: '-10px',
  marginBottom: '10px',
}));

const LabelText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '0.9rem',
  marginTop: '-10px',
  marginBottom: '10px',
  '@media (max-width: 600px)': {
    fontSize: '0.8rem',
  },
}));

// Card Pack Option Component
const CardPackOption = ({ price, cardCount, selectedPack, onSelect }) => {
  const isSelected = selectedPack === price;

  return (
    <Box
      onClick={() => onSelect(price, cardCount)}
      sx={{
        backgroundColor: isSelected
          ? 'rgba(0, 191, 255, 0.3)'
          : 'rgba(0, 191, 255, 0.1)',
        padding: '15px',
        borderRadius: '8px',
        cursor: 'pointer',
        border: isSelected
          ? '2px solid #00BFFF'
          : '1px solid rgba(255, 255, 255, 0.2)',
        transition: 'all 0.2s ease',
        '&:hover': {
          backgroundColor: 'rgba(0, 191, 255, 0.2)',
          transform: 'translateY(-2px)',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30%',
        boxSizing: 'border-box',
      }}
    >
      <Typography
        sx={{
          color: '#FFFFFF',
          fontFamily: "'Press Start 2P', cursive",
          fontSize: '1.2rem',
          mb: 1,
        }}
      >
        ${price}
      </Typography>
      <Typography sx={{ color: '#FFFFFF', fontSize: '0.8rem' }}>
        {cardCount} Cards
      </Typography>
    </Box>
  );
};

// Dashboard Payment Component
const DashboardPaymentPopup = ({ onClose }) => {
  const { user } = useAuth();
  const [selectedPack, setSelectedPack] = useState(10); // Default to middle option
  const [selectedCardCount, setSelectedCardCount] = useState(100);
  const [selectedCrypto, setSelectedCrypto] = useState('BTC');
  const [cryptoAmount, setCryptoAmount] = useState('0.00015');
  const [usdAmount, setUsdAmount] = useState(`$${selectedPack}.00`);
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [email, setEmail] = useState(user?.email || '');

  const cryptoAddresses = {
    BTC: '3KybzUMnSGkJMNmoshXD7wwaeLuENQNpNB',
    ETH: '0xB8ea0cEF886Fa141B3c60E220320FA911d3bBC99',
    DOGE: 'DGRAizMZUuQuqMLSTUt7AxoEsktYryA6s1',
  };

  // Update crypto amount when pack or crypto changes
  useEffect(() => {
    const fetchCryptoPrices = async () => {
      try {
        const response = await axios.get('/api/crypto-payment-prices');
        const prices = response.data.prices;

        const cryptoEquivalents = {
          BTC: (selectedPack / prices.BTC).toFixed(8),
          ETH: (selectedPack / prices.ETH).toFixed(8),
          DOGE: (selectedPack / prices.DOGE).toFixed(2),
        };

        setCryptoAmount(cryptoEquivalents[selectedCrypto]);
        setUsdAmount(`$${selectedPack}.00`);
      } catch (error) {
        console.error('Error fetching crypto prices:', error);
        setCryptoAmount('N/A');
      }
    };

    fetchCryptoPrices();
  }, [selectedPack, selectedCrypto]);

  const handlePackSelect = (price, cardCount) => {
    setSelectedPack(price);
    setSelectedCardCount(cardCount);
  };

  const handleCryptoSelect = (crypto) => {
    setSelectedCrypto(crypto);
  };

  const handleConfirmChange = (event) => {
    setConfirmChecked(event.target.checked);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(cryptoAddresses[selectedCrypto]);
    alert('Crypto Address Copied!');
  };

  const handlePurchase = async () => {
    if (!confirmChecked) {
      alert('Please confirm the payment before proceeding.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    try {
      await axios.post('/api/payment-notification', {
        email,
        cryptoAmount,
        selectedCrypto,
        usdAmount: selectedPack,
        cardCount: selectedCardCount,
      });

      alert(
        'Receipt sent! Thank you for your payment. You will receive your Dog Cards soon!'
      );
      onClose();
    } catch (error) {
      console.error('Error processing payment:', error);
      alert('Failed to process payment. Please try again.');
    }
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ position: 'relative' }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>

        <RetroTitle>Get More Cards!</RetroTitle>

        <Typography
          sx={{
            color: '#FFFFFF',
            fontSize: '0.9rem',
            textAlign: 'center',
            mb: 3,
          }}
        >
          You're running low on Dog Cards! Purchase more to continue discovering
          new breeds.
        </Typography>

        {/* Package Options */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            mb: 3,
          }}
        >
          <CardPackOption
            price={5}
            cardCount={25}
            selectedPack={selectedPack}
            onSelect={handlePackSelect}
          />
          <CardPackOption
            price={10}
            cardCount={100}
            selectedPack={selectedPack}
            onSelect={handlePackSelect}
          />
          <CardPackOption
            price={15}
            cardCount={200}
            selectedPack={selectedPack}
            onSelect={handlePackSelect}
          />
        </Box>

        {/* QR Code Section */}
        <QRCodeCanvas
          value={cryptoAddresses[selectedCrypto]}
          size={150}
          style={{ margin: '10px auto', maxWidth: '100%', height: 'auto' }}
        />

        {/* Crypto Address */}
        <CopyField
          value={cryptoAddresses[selectedCrypto]}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ContentCopyIcon
                  onClick={handleCopyToClipboard}
                  sx={{ cursor: 'pointer', color: '#FFFFFF' }}
                />
              </InputAdornment>
            ),
          }}
          fullWidth
          sx={{ mb: 2 }}
        />

        {/* Crypto Selection */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tooltip title="Bitcoin">
              <Typography
                onClick={() => handleCryptoSelect('BTC')}
                sx={{
                  color: selectedCrypto === 'BTC' ? '#FFFFFF' : '#00BFFF',
                  cursor: 'pointer',
                  fontWeight: selectedCrypto === 'BTC' ? 'bold' : 'normal',
                }}
              >
                BTC
              </Typography>
            </Tooltip>
            <Typography sx={{ color: '#FFFFFF' }}>|</Typography>
            <Tooltip title="Ethereum">
              <Typography
                onClick={() => handleCryptoSelect('ETH')}
                sx={{
                  color: selectedCrypto === 'ETH' ? '#FFFFFF' : '#00BFFF',
                  cursor: 'pointer',
                  fontWeight: selectedCrypto === 'ETH' ? 'bold' : 'normal',
                }}
              >
                ETH
              </Typography>
            </Tooltip>
            <Typography sx={{ color: '#FFFFFF' }}>|</Typography>
            <Tooltip title="Dogecoin">
              <Typography
                onClick={() => handleCryptoSelect('DOGE')}
                sx={{
                  color: selectedCrypto === 'DOGE' ? '#FFFFFF' : '#00BFFF',
                  cursor: 'pointer',
                  fontWeight: selectedCrypto === 'DOGE' ? 'bold' : 'normal',
                }}
              >
                DOGE
              </Typography>
            </Tooltip>
          </Box>
        </Box>

        {/* Payment Summary */}
        <Box
          sx={{
            backgroundColor: 'rgba(0, 191, 255, 0.1)',
            borderRadius: '8px',
            padding: '15px',
            mb: 2,
          }}
        >
          <Typography
            sx={{
              color: '#FFFFFF',
              fontSize: '0.9rem',
              textAlign: 'center',
              mb: 1,
            }}
          >
            {cryptoAmount} {selectedCrypto}
          </Typography>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontSize: '0.9rem',
              textAlign: 'center',
            }}
          >
            For {selectedCardCount} Dog Cards (${selectedPack}.00)
          </Typography>
        </Box>

        {/* Confirmation */}
        <FormControlLabel
          control={
            <Checkbox
              checked={confirmChecked}
              onChange={handleConfirmChange}
              sx={{
                color: '#00BFFF',
                '&.Mui-checked': {
                  color: '#00BFFF',
                },
              }}
            />
          }
          label="I confirm this payment"
          sx={{
            color: '#FFFFFF',
            fontSize: '0.9rem',
            alignSelf: 'center',
            mb: 2,
          }}
        />

        {/* Email Field (only if not logged in) */}
        {!user?.email && (
          <TextField
            label="Email for Receipt"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: '#283593',
              borderRadius: '5px',
              mb: 2,
              input: {
                color: '#FFFFFF',
              },
              label: {
                color: '#FFFFFF',
              },
            }}
          />
        )}

        {/* Purchase Button */}
        <Button
          onClick={handlePurchase}
          disabled={!confirmChecked}
          sx={{
            backgroundColor: confirmChecked
              ? '#00BFFF'
              : 'rgba(0, 191, 255, 0.3)',
            color: '#FFFFFF',
            fontFamily: "'Press Start 2P', cursive",
            fontSize: '0.9rem',
            padding: '10px 20px',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: confirmChecked
                ? '#00A0E0'
                : 'rgba(0, 191, 255, 0.3)',
            },
            textTransform: 'none',
            width: '100%',
          }}
        >
          Purchase with {selectedCrypto}
        </Button>
      </Box>
    </Box>
  );
};

const Dashboard = ({ onClose }) => {
  const { cardGenerationInfo, savedCards, fetchSavedCards } = useAuth();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [selectedCard, setSelectedCard] = useState(null);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);

  // 1) Track which 3-card "page" we’re on
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    if (fetchSavedCards) {
      fetchSavedCards();
    }
  }, [fetchSavedCards]);

  // Calculate generated cards (assuming total is 25)
  const totalCards = 25;
  const remaining = cardGenerationInfo?.remainingGenerations || 0;
  const generated = totalCards - remaining;

  // Check if user is running low on cards (5 or fewer)
  useEffect(() => {
    // For testing, you can set this to true to always show the popup
    // For production, use: if (remaining <= 5 && remaining > 0)
    if (remaining <= 5 && remaining > 0) {
      setShowPaymentPopup(true);
    }
  }, [remaining]);

  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  // Format date and time
  const formattedDate = currentDateTime.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const formattedTime = currentDateTime.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

  // Handlers
  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleCloseDetailView = (e) => {
    e.stopPropagation();
    setSelectedCard(null);
  };

  // Handle payment popup close
  const handlePaymentClose = () => {
    setShowPaymentPopup(false);
  };

  // 2) Show next 3
  const showNextCards = () => {
    if (visibleIndex + 3 < savedCards.length) {
      setVisibleIndex((prev) => prev + 3);
    }
  };

  // 3) Show previous 3
  const showPreviousCards = () => {
    if (visibleIndex - 3 >= 0) {
      setVisibleIndex((prev) => prev - 3);
    }
  };

  if (selectedCard) {
    // Show detail view for selected card
    return (
      <Overlay onClick={onClose}>
        <PopupContainer onClick={(e) => e.stopPropagation()}>
          <Box sx={{ position: 'relative' }}>
            <IconButton
              onClick={handleCloseDetailView}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                color: 'white',
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* Just show the image directly, no additional elements */}
            <Box
              component="img"
              src={selectedCard.imageUrl}
              alt={selectedCard.breedName}
              sx={{
                maxWidth: '100%',
                width: 'auto',
                height: 'auto',
                maxHeight: '70vh',
                borderRadius: '8px',
                mt: 4,
                mb: 2,
              }}
            />
          </Box>
        </PopupContainer>
      </Overlay>
    );
  }

  // Main dashboard view
  return (
    <Overlay onClick={onClose}>
      <PopupContainer onClick={(e) => e.stopPropagation()}>
        {showPaymentPopup ? (
          <DashboardPaymentPopup onClose={handlePaymentClose} />
        ) : (
          <>
            <RetroTitle>Dashboard</RetroTitle>

            <StatCard>
              <StatLabel sx={{ fontSize: '0.7rem' }}>Dog Cards</StatLabel>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 1 }}>
                  <StatValue sx={{ fontSize: '2rem', mr: 1 }}>
                    {generated}
                  </StatValue>
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontFamily: "'Press Start 2P', cursive",
                      fontSize: '0.8rem',
                    }}
                  >
                    Generated
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                  <StatValue sx={{ fontSize: '2rem' }}>
                    {remaining}
                  </StatValue>
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontFamily: "'Press Start 2P', cursive",
                      fontSize: '0.8rem',
                      ml: 1,
                    }}
                  >
                    Remaining
                  </Typography>
                </Box>
              </Box>
            </StatCard>

            {/* Saved Cards Section */}
            <Box sx={{ mt: 4, width: '100%' }}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontFamily: "'Press Start 2P', cursive",
                  fontSize: '1.2rem',
                  mb: 2,
                  textShadow: '0 0 5px rgba(0, 191, 255, 0.5)',
                }}
              >
                Your Saved Cards
              </Typography>

              {!savedCards || savedCards.length === 0 ? (
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontFamily: "'Press Start 2P', cursive",
                    fontSize: '0.8rem',
                  }}
                >
                  No saved cards yet
                </Typography>
              ) : (
                <>
                  {/* 4) Show exactly 3 cards at a time */}
                  <Box
  sx={{
    display: 'grid',
    // Use minmax to ensure each column is at least 120px but shrinks if needed
    gridTemplateColumns: 'repeat(3, minmax(120px, 1fr))',
    gap: 2,
    mb: 2,
  }}
>
  {savedCards
    .slice(visibleIndex, visibleIndex + 3)
    .map((card) => (
      <Box
        key={card.id}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={card.imageUrl}
          alt={card.breedName}
          onClick={() => handleCardClick(card)}
          sx={{
            width: '120px',
            height: '120px',
            objectFit: 'cover',
            borderRadius: '4px',
            cursor: 'pointer',
            transition: 'transform 0.2s, box-shadow 0.2s',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 0 8px rgba(0, 191, 255, 0.5)',
            },
          }}
        />
      </Box>
    ))}
</Box>


                  {/* 5) Arrow up/down buttons to navigate */}
                  <Box
                    sx={{
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 2,
                    }}
                  >
                    <IconButton
                      onClick={showPreviousCards}
                      disabled={visibleIndex === 0}
                      sx={{
                        color: '#FFFFFF',
                        '&.Mui-disabled': {
                          opacity: 0.3,
                        },
                      }}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton
                      onClick={showNextCards}
                      disabled={visibleIndex + 3 >= savedCards.length}
                      sx={{
                        color: '#FFFFFF',
                        '&.Mui-disabled': {
                          opacity: 0.3,
                        },
                      }}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>

            <DateTimeDisplay>
              {formattedDate} • {formattedTime}
            </DateTimeDisplay>
          </>
        )}
      </PopupContainer>
    </Overlay>
  );
};

export default Dashboard;
