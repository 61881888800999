// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

// API instance for consistent API calls
const api = axios.create({
  baseURL: window.location.hostname === 'localhost'
    ? 'http://localhost:5000'
    : 'https://satoshi-homepage-59650ad0a3ce.herokuapp.com',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});



export const AuthProvider = ({ children }) => {
  // Keep your existing user state
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [savedCards, setSavedCards] = useState([]);
  
  // Add new state for card generation
  const [loading, setLoading] = useState(true);
  const [cardGenerationInfo, setCardGenerationInfo] = useState({
    remainingGenerations: null,
    hasHadFreeGeneration: false,
    loading: true
  });

  // Keep your existing user useEffect
  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  // Add a new useEffect to check card generation limits
  useEffect(() => {
    const checkAuth = () => {
      const storedEmail = localStorage.getItem('userEmail') || sessionStorage.getItem('userEmail');
      if (storedEmail && user) {
        checkGenerationLimit(storedEmail);
      } else {
        setCardGenerationInfo(prev => ({
          ...prev,
          loading: false
        }));
      }
      setLoading(false);
    };

    checkAuth();
  }, [user]);

  // Keep your existing login/logout functions
  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('userEmail');
    sessionStorage.removeItem('userEmail');
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    setUser(null); // Fixed: This should be setUser(null) instead of setUserEmail('')
  };

  // Function to check generation limits
  const checkGenerationLimit = async (email) => {
    if (!email) return;
    
    try {
      setCardGenerationInfo(prev => ({ ...prev, loading: true }));
      
      const response = await api.get(`/api/card-generation/check?email=${encodeURIComponent(email)}`);
      
      if (response.data) {
        setCardGenerationInfo({
          remainingGenerations: response.data.remainingGenerations,
          hasHadFreeGeneration: !response.data.isFirstTimeEver,
          totalGenerated: response.data.totalGenerated,
          loading: false
        });
      }
    } catch (error) {
      console.error('Error checking generation limit:', error);
      setCardGenerationInfo(prev => ({ ...prev, loading: false }));
    }
  };

  // Function to record a card generation
  const recordCardGeneration = async (email) => {
    if (!email) return false;
    
    try {
      const response = await api.post('/api/card-generation/record', { email });
      
      if (response.data && response.data.success) {
        setCardGenerationInfo({
          remainingGenerations: response.data.remainingGenerations,
          hasHadFreeGeneration: true,
          totalGenerated: response.data.totalGenerated,
          loading: false
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error recording card generation:', error);
      return false;
    }
  };

  // Function to update auth state after login/signup
  const updateAuthState = (email) => {
    if (email) {
      setUser({ email });
      checkGenerationLimit(email);
    }
  };
  
  // Function to mark free generation used (for non-authenticated users)
  const markFreeGenerationUsed = () => {
    setCardGenerationInfo(prev => ({
      ...prev,
      hasHadFreeGeneration: true
    }));
  };

  const fetchSavedCards = async () => {
    if (!user || !user.email) return;
    
    try {
      const response = await api.get(`/api/saved-cards?email=${encodeURIComponent(user.email)}`);
      
      if (response.data.success) {
        setSavedCards(response.data.savedCards);
      }
    } catch (error) {
      console.error('Error fetching saved cards:', error);
    }
  };
  
  // Function to save a card
  const saveCard = async (breedName, imageUrl) => {
    if (!user || !user.email) return null;
    
    try {
      const response = await api.post('/api/save-card', {
        email: user.email,
        breedName,
        imageUrl
      });
      
      if (response.data.success) {
        // Add the new card to the state
        setSavedCards(prev => [response.data.savedCard, ...prev]);
        return response.data.savedCard;
      }
      return null;
    } catch (error) {
      console.error('Error saving card:', error);
      return null;
    }
  };

  // Add refreshCardInfo function inside the component
  const refreshCardInfo = async () => {
    if (!user || !user.email) return;
    
    try {
      const response = await api.get('/api/card-generation/check', {
        params: { email: user.email }
      });
      
      if (response.data) {
        setCardGenerationInfo({
          remainingGenerations: response.data.remainingGenerations,
          hasHadFreeGeneration: !response.data.isFirstTimeEver,
          totalGenerated: response.data.totalGenerated,
          loading: false
        });
      }
    } catch (error) {
      console.error('Error refreshing card info:', error);
    }
  };

  // Calculate if user is authenticated
  const isAuthenticated = !!user?.email;
  // Extract email for easier access in components
  const userEmail = user?.email || '';

  // Add new context values
  return (
    <AuthContext.Provider value={{ 
      user, 
      login, 
      logout,
      cardGenerationInfo,
      checkGenerationLimit,
      recordCardGeneration,
      updateAuthState,
      markFreeGenerationUsed,
      refreshCardInfo,
      savedCards,
      fetchSavedCards,
      saveCard,
      isAuthenticated,
      userEmail
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

