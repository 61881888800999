import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import emotionStyled from "@emotion/styled";
import html2canvas from "html2canvas";
import { Typography, Box, IconButton } from "@mui/material";
import { styled as muiStyled } from "@mui/system";
import { useAuth } from "../contexts/AuthContext";
import AuthPopup from "./AuthPopup";

// Additional icons for navigation and actions
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import HearingIcon from '@mui/icons-material/Hearing';

const AUDIO_BASE_URL = "https://media.satoshiadvance.com/audio";
const MEDIA_BASE_URL = "https://media.satoshiadvance.com/images";

const SectionTitle = muiStyled(Typography)({
  fontFamily: "'Press Start 2P', cursive",
  color: "#FFFFFF",
  fontSize: "2.4rem",
  marginTop: "40px",
  marginBottom: "20px",
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  "@media (max-width: 600px)": {
    fontSize: "2.4rem", // Same as desktop size to make it 2x larger than before
  },
});

export const DividerBar = emotionStyled(motion.div)`
  width: 80%;
  height: 5px;
  background-color: #FFFFFF;
  margin: 20px auto 0;
  margin-bottom: 30px;
  transform-origin: center;
  box-shadow: 
    0 0 10px #FFFFFF,
    0 0 20px #FF69B4,
    0 0 30px #FF69B4,
    0 0 40px #FF69B4,
    0 0 70px #FFFFFF,
    0 0 80px #FFFFFF,
    0 0 100px #FF69B4,
    0 0 150px #FF69B4;
  
  @media (max-width: 600px) {
    width: 95%;
  }
`;

export const MotionDivider = () => {
  return (
    <DividerBar
      initial={{ scaleX: 0 }} // Start as a point in the middle
      animate={{
        scaleX: 1, // Grow to full width
        rotateX: 15, // Slight tilt for a 3D effect
      }}
      transition={{
        duration: 2, // Grow over 2 seconds
        ease: "easeInOut", // Smooth animation
      }}
    />
  );
};

const DOG_TUNES = [
  "DogTunes7.wav",
  "DogTunes5.wav",
  "DogTunes3.wav",
  "DogTunes4.wav",
  "DogTunes2.wav",
  "DogTunes6.wav",
  "DogTunes1.wav",
  "DogTunes8.wav",
  "DogTunes9.wav",
  "DogTunes10.wav",
  "DogTunes11.mp3",
];

function DogSprite() {
  const [showLargeImage, setShowLargeImage] = useState(false);
  const audioRef = useRef(new Audio(`${AUDIO_BASE_URL}/dog-barking.wav`));
  const backAudioRef = useRef(new Audio(`${AUDIO_BASE_URL}/dogs-bark-english-sheep.wav`));
  const trickAudioRef = useRef(new Audio(`${AUDIO_BASE_URL}/dogs-bark-golden-retriever.wav`));
  const [currentBreed, setCurrentBreed] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [isSpinning, setIsSpinning] = useState({ download: false, play: false, action: false });
  const [previousBreeds, setPreviousBreeds] = useState([]);
  const [nextBreed, setNextBreed] = useState("");
  const [nextDescription, setNextDescription] = useState("");
  const [nextImageUrl, setNextImageUrl] = useState("");
  const [nextActionImage, setNextActionImage] = useState({ action: "", imageUrl: "" });
  const [loading, setLoading] = useState(true); // Loading state
  const downloadAudioRef = useRef(new Audio(`${AUDIO_BASE_URL}/dogs-bark-jack-russell.wav`));
  const [attemptsLeft, setAttemptsLeft] = useState(3); // Number of attempts remaining
  const [result, setResult] = useState(null); // Game result: "Winner!" or "Game Over"
  const [clickTimeout, setClickTimeout] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [gameStarted, setGameStarted] = useState(false);
  const [dogTunesPaths] = useState(DOG_TUNES.map((file) => `${AUDIO_BASE_URL}/${file}`));
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState("low");
  const musicAudioRef = useRef(new Audio(dogTunesPaths[0]));
  const [transparentSpriteUrl, setTransparentSpriteUrl] = useState(null);
  const [miniGameData, setMiniGameData] = useState(null);
  const [wrongGuess, setWrongGuess] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  // Replace the constant hotspot with a state variable
  const [hotspot, setHotspot] = useState({
    x1: 5925, // Left boundary
    y1: 5050, // Top boundary
    x2: 6550, // Right boundary
    y2: 5550, // Bottom boundary
  });


  const setShowLargeImageWithSprite = async () => {
    try {
      // Only process if we have a current breed and image
      if (currentBreed && currentImageUrl) {
        // Remove blue background from current sprite
        const transparentSprite = await removeBlueBackground(currentImageUrl);
        setTransparentSpriteUrl(transparentSprite);

        // Position the sprite in the mini-game
        const gameData = positionSpriteInMiniGame(transparentSprite, currentBreed);
        setMiniGameData(gameData);

        // Update the hotspot boundaries with the new position
        setHotspot({
          x1: gameData.position.x,
          y1: gameData.position.y,
          x2: gameData.position.x + gameData.position.width,
          y2: gameData.position.y + gameData.position.height
        });

        // Now show the large image/mini-game
        setShowLargeImage(true);
      } else {
        console.error('Current breed or image not available');
      }
    } catch (error) {
      console.error('Error preparing sprite for mini-game:', error);
      // Fallback to original behavior if processing fails
      setShowLargeImage(true);
    }
  };



  // New state for auth popup
  const [showAuthPopup, setShowAuthPopup] = useState(false);

  // Use the auth context
  const { user, cardGenerationInfo, recordCardGeneration, markFreeGenerationUsed, isAuthenticated, saveCard } = useAuth();



  const cardRef = useRef(null);

  // Replace the old ACTIONS array with this new one:
const ACTIONS = [
  // Basic Poses
  "Sit",
  "Lie Down",
  "Stand",          // Kept for variety
  "Shake Paw",      // Renamed from "Shake Hands"
  "Speak",          // Renamed from "Bark" for potentially clearer visual

  // Dynamic Actions from Original
  "Jump",
  "Run",
  "Catch Frisbee",
  "Roll Over",      // Kept, prompt refinement will help
  "Bow (Play Position)",

  // New Actions from User Request
  "Fetch (Ball)",   // Clarified from just "Fetch"
  "Fetch Newspaper",
  "Play Dead",
  "Spin",
  "Dig",
  "Stretch",
  "Sniff",
]; // Total: 17 Actions

  const fetchDogDescription = async (breedName) => {
    try {
      const baseURL =
        window.location.hostname === "localhost"
          ? "http://localhost:5000"
          : "https://satoshi-homepage-59650ad0a3ce.herokuapp.com";

      const response = await fetch(`${baseURL}/api/openai/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          prompt: `Provide a brief 2-3 line description of the ${breedName} dog breed, including a bit about its history and characteristics. Keep it concise and engaging.`,
          type: "text",
        }),
      });

      console.log("Raw Response:", response);

      if (!response.ok) {
        const errorText = await response.text();
        console.error("API Error Response:", {
          status: response.status,
          statusText: response.statusText,
          body: errorText,
        });
        throw new Error(`API error: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      console.log("Description API Success:", data);
      return data.result || "Unable to load description.";
    } catch (error) {
      console.error("Error in fetchDogDescription:", error);
      return "Unable to load description. Please try again later.";
    }
  };

  const fetchDogImage = async (breedName, action = null) => {
    try {
      const baseURL =
        window.location.hostname === "localhost"
          ? "http://localhost:5000"
          : "https://satoshi-homepage-59650ad0a3ce.herokuapp.com";

      const actionPrompt = action ? `, performing the action: "${action}"` : "";

      console.log("Making request to:", `${baseURL}/api/openai/generate`);
      console.log("Request payload:", {
        prompt: `A retro 32-bit pixel art sprite of a ${breedName} dog breed${actionPrompt}...`,
        type: "image",
      });

      const response = await fetch(`${baseURL}/api/openai/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          prompt: `A retro 32-bit pixel art sprite of a ${breedName} dog breed${actionPrompt}. The sprite should be well-defined with bold and thick black outlines around the dog, ensuring no part is cut off. Use a deep blue background color (#1a237e) that matches the website. Keep the sprite centered with padding to prevent cropping.`,
          type: "image",
        }),
      });

      console.log("Raw Response:", response);

      if (!response.ok) {
        const errorText = await response.text();
        console.error("API Error Response:", {
          status: response.status,
          statusText: response.statusText,
          body: errorText,
        });
        throw new Error(`API error: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      console.log("Image API Success:", data);

      if (!data.image_url) {
        throw new Error("No image URL in response");
      }

      return `${baseURL}/proxy-image?url=${encodeURIComponent(data.image_url)}`;
    } catch (error) {
      console.error("Error in fetchDogImage:", {
        error: error.message,
        stack: error.stack,
      });
      return `${MEDIA_BASE_URL}/placeholder.png`;
    }
  };


  const preloadNextBreed = async () => {
    try {
      const randomIndex = Math.floor(Math.random() * DOG_BREEDS.length);
      const newBreed = DOG_BREEDS[randomIndex];
      const description = await fetchDogDescription(newBreed);
      const imageUrl = await fetchDogImage(newBreed);

      console.log("Preloading next breed:", newBreed);

      setNextBreed(newBreed || "Unknown Breed");
      setNextDescription(description || "Unable to load description.");
      setNextImageUrl(imageUrl || "/placeholder.png");
    } catch (error) {
      console.error("Error preloading next breed:", error);
      setNextBreed("Error");
      setNextDescription("Unable to load description.");
      setNextImageUrl("/placeholder.png");
    }
  };

  const preloadNextAction = async () => {
    if (!currentBreed) return false;

    try {
      const randomAction = ACTIONS[Math.floor(Math.random() * ACTIONS.length)];
      const imageUrl = await fetchDogImage(currentBreed, randomAction);

      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = imageUrl;
      });

      setNextActionImage({ action: randomAction, imageUrl });
      return true;
    } catch (error) {
      console.error("Error preloading next action:", error);
      setNextActionImage({ action: "", imageUrl: "/placeholder.png" });
      return false;
    }
  };

  // Modified showNextBreed to check limits
  const showNextBreed = async () => {
    if (!nextBreed || !nextImageUrl) {
      console.error("Next breed or image is not ready yet.");
      return;
    }

    if (!cardGenerationInfo.hasHadFreeGeneration) {
      if (isAuthenticated) {
        await recordCardGeneration(user.email);
      } else {
        markFreeGenerationUsed();
      }
      proceedWithBreedGeneration();
      return;
    }

    if (!isAuthenticated) {
      setShowAuthPopup(true);
      return;
    }

    if (cardGenerationInfo.remainingGenerations <= 0) {
      alert("You've reached your monthly limit of 25 dog breed cards. Come back next month for more!");
      return;
    }

    const success = await recordCardGeneration(user.email);
    if (success) {
      proceedWithBreedGeneration();
    }
  };

  // Extract breed generation logic
  const proceedWithBreedGeneration = () => {
    setLoading(true);
    playBarkSound();

    if (currentBreed) {
      setPreviousBreeds((prev) => [
        ...prev,
        {
          breed: currentBreed,
          description: currentDescription,
          imageUrl: currentImageUrl,
        },
      ]);
    }

    setTimeout(() => {
      const preloadedBreed = nextBreed;
      const preloadedDesc = nextDescription;
      const preloadedImg = nextImageUrl;

      setNextBreed("");
      setNextDescription("");
      setNextImageUrl("");
      setNextActionImage({ action: "", imageUrl: "" });

      setCurrentBreed(preloadedBreed);
      setCurrentDescription(preloadedDesc);
      setCurrentImageUrl(preloadedImg);

      const preloadNext = async () => {
        try {
          const randomIndex = Math.floor(Math.random() * DOG_BREEDS.length);
          const newBreed = DOG_BREEDS[randomIndex];

          const [nextDesc, nextImg] = await Promise.all([
            fetchDogDescription(newBreed),
            fetchDogImage(newBreed),
          ]);

          setNextBreed(newBreed);
          setNextDescription(nextDesc);
          setNextImageUrl(nextImg);

          await preloadNextAction();
        } catch (error) {
          console.error("Error preloading next breed:", error);
        } finally {
          setLoading(false);
        }
      };

      preloadNext();
    }, 500);
  };

  const navigateBack = () => {
    if (previousBreeds.length > 0) {
      playBackBarkSound();
      const lastBreed = previousBreeds[previousBreeds.length - 1];
      setPreviousBreeds((prev) => prev.slice(0, -1));
      setCurrentBreed(lastBreed.breed);
      setCurrentDescription(lastBreed.description);
      setCurrentImageUrl(lastBreed.imageUrl);

      preloadNextAction();
    }
  };

  const generateRandomAction = async () => {
    if (!currentBreed) return;

    setIsSpinning((prev) => ({ ...prev, action: true }));
    playTrickBarkSound();

    try {
      if (nextActionImage.imageUrl && nextActionImage.imageUrl !== "/placeholder.png") {
        setCurrentImageUrl(nextActionImage.imageUrl);
        setNextActionImage({ action: "", imageUrl: "" });
        setIsSpinning((prev) => ({ ...prev, action: false }));
        preloadNextAction();
      } else {
        const randomAction = ACTIONS[Math.floor(Math.random() * ACTIONS.length)];
        const imageUrl = await fetchDogImage(currentBreed, randomAction);

        await new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = resolve;
          img.onerror = reject;
          img.src = imageUrl;
        });

        setCurrentImageUrl(imageUrl);
        setIsSpinning((prev) => ({ ...prev, action: false }));
        preloadNextAction();
      }
    } catch (error) {
      console.error("Error generating random action:", error);
      setCurrentImageUrl("/placeholder.png");
      setIsSpinning((prev) => ({ ...prev, action: false }));
    }
  };


  const removeBlueBackground = (imageUrl) => {
    return new Promise((resolve, reject) => {
      // Create an offscreen canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Create an image object to load the sprite
      const img = new Image();
      img.crossOrigin = 'Anonymous';

      // Handle image load event
      img.onload = () => {
        // Set canvas dimensions
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image on canvas
        ctx.drawImage(img, 0, 0);

        // Get image data for processing
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        // Process each pixel - making blue background transparent
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];

          // Check if pixel is blue background (#1a237e)
          // You may need to adjust these thresholds based on your exact blue color
          if ((b > 100 && b > r * 1.2 && b > g * 1.2) ||
            (r < 50 && g < 50 && b > 180) ||  // Bright blue
            (Math.abs(r - 26) < 30 && Math.abs(g - 35) < 30 && Math.abs(b - 126) < 30)) {  // Deep blue
            // Make the pixel transparent
            data[i + 3] = 0; // Alpha channel (0 = fully transparent)
          }
        }

        // Put the modified image data back to canvas
        ctx.putImageData(imageData, 0, 0);

        // Generate transparent PNG
        const transparentPngUrl = canvas.toDataURL('image/png');
        resolve(transparentPngUrl);
      };

      // Handle errors
      img.onerror = (error) => {
        console.error('Error loading image for background removal:', error);
        reject(error);
      };

      // Set source to the provided imageUrl
      img.src = imageUrl;
    });
  };

  const positionSpriteInMiniGame = (spriteUrl, currentBreed) => {
    // Create an object to return the sprite's position and the updated mini-game data
    const gameData = {
      spriteUrl: '',
      breed: currentBreed,
      position: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      }
    };

    // Generate random position within the mini-game landscape
    // These values should be adjusted based on the size of your background image
    const minX = 500; // Minimum X position
    const maxX = 9000; // Maximum X position
    const minY = 500; // Minimum Y position
    const maxY = 5000; // Maximum Y position

    // Generate random position
    gameData.position.x = Math.floor(Math.random() * (maxX - minX)) + minX;
    gameData.position.y = Math.floor(Math.random() * (maxY - minY)) + minY;

    // Set sprite dimensions (adjust based on your sprite size)
    gameData.position.width = 280;
    gameData.position.height = 280;

    // Set the sprite URL
    gameData.spriteUrl = spriteUrl;

    return gameData;
  };

  const handleImageClick = (e) => {
    e.stopPropagation();

    if (result) return;

    const rect = e.target.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const clickY = e.clientY - rect.top;

    console.log(`Click at X: ${clickX}, Y: ${clickY}`);
    console.log(
      `Hotspot: X1=${hotspot.x1}, Y1=${hotspot.y1}, X2=${hotspot.x2}, Y2=${hotspot.y2}`
    );

    if (clickX >= hotspot.x1 && clickX <= hotspot.x2 && clickY >= hotspot.y1 && clickY <= hotspot.y2) {
      console.log("You clicked inside the hotspot!");
      setResult("Winner!");
    } else {
      console.log("You clicked outside the hotspot.");
      setWrongGuess(true); // Set wrong guess state to true

      // Reset wrong guess state after animation finishes
      setTimeout(() => {
        setWrongGuess(false);
      }, 800);

      setAttemptsLeft((prev) => {
        const newAttempts = prev - 1;
        if (newAttempts <= 0) {
          setResult("Game Over");
        }
        return newAttempts;
      });

    }
  };

  const downloadCard = async () => {
    if (cardRef.current) {
      setIsSpinning((prev) => ({ ...prev, download: true }));
      playDownloadBarkSound();

      try {
        const canvas = await html2canvas(cardRef.current, {
          backgroundColor: null,
          useCORS: true,
          allowTaint: true,
          imageTimeout: 0,
          logging: true,
        });

        const dataURL = canvas.toDataURL("image/png");

        // Save to user's account if logged in and saveCard function exists
        if (isAuthenticated && typeof saveCard === 'function') {
          // Try to save the card to the server
          saveCard(currentBreed, dataURL).catch(error =>
            console.error("Error saving card to server:", error)
          );
        }

        // Download to user's device (this already worked before)
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = `${currentBreed.replace(/\s+/g, "_")}dogcard.png`;
        link.click();
      } catch (error) {
        console.error("Error generating card:", error);
      } finally {
        setIsSpinning((prev) => ({ ...prev, download: false }));
      }
    }
  };

  const speakDescription = () => {
    if ("speechSynthesis" in window) {
      const synth = window.speechSynthesis;
      const utterance = new SpeechSynthesisUtterance(
        `${currentBreed}: ${currentDescription}`
      );
      utterance.lang = "en-US";
      utterance.rate = 0.9;
      utterance.pitch = 1.0;
      setIsSpinning((prev) => ({ ...prev, play: true }));
      setTimeout(() => {
        setIsSpinning((prev) => ({ ...prev, play: false }));
        synth.speak(utterance);
      }, 500);
    } else {
      alert("Speech Synthesis not supported in this browser.");
    }
  };

  useEffect(() => {
    const initializeBreeds = async () => {
      setLoading(true);

      try {
        const randomIndex = Math.floor(Math.random() * DOG_BREEDS.length);
        const initialBreed = DOG_BREEDS[randomIndex];

        const [description, imageUrl] = await Promise.all([
          fetchDogDescription(initialBreed),
          fetchDogImage(initialBreed),
        ]);

        setCurrentBreed(initialBreed);
        setCurrentDescription(description);
        setCurrentImageUrl(imageUrl);
        setLoading(false);

        setTimeout(async () => {
          try {
            const nextRandomIndex = Math.floor(Math.random() * DOG_BREEDS.length);
            const newBreed = DOG_BREEDS[nextRandomIndex];

            const [nextDesc, nextImg] = await Promise.all([
              fetchDogDescription(newBreed),
              fetchDogImage(newBreed),
            ]);

            setNextBreed(newBreed);
            setNextDescription(nextDesc);
            setNextImageUrl(nextImg);

            await preloadNextAction();
          } catch (error) {
            console.error("Error in background preloading:", error);
          }
        }, 1000);
      } catch (error) {
        console.error("Error initializing breeds:", error);
        setLoading(false);
      }
    };

    initializeBreeds();
  }, []);

  useEffect(() => {
    musicAudioRef.current.src = dogTunesPaths[currentTrackIndex];

    if (isMusicPlaying) {
      musicAudioRef.current.play().catch((err) => {
        console.log("Music autoplay error:", err);
      });
    }
  }, [currentTrackIndex, isMusicPlaying, dogTunesPaths]);

  useEffect(() => {
    const newVolume = volumeLevel === "low" ? 0.3 : 0.7;
    musicAudioRef.current.volume = newVolume;
  }, [volumeLevel]);

  useEffect(() => {
    return () => {
      musicAudioRef.current.pause();
      musicAudioRef.current.src = "";
    };
  }, []);

  useEffect(() => {
    audioRef.current.volume = 0.5;
    backAudioRef.current.volume = 0.5;
    trickAudioRef.current.volume = 0.5;
    downloadAudioRef.current.volume = 0.5;
    audioRef.current.load();
    backAudioRef.current.load();
    trickAudioRef.current.load();
    downloadAudioRef.current.load();
    console.log("Audio files initialized");

    audioRef.current.addEventListener("play", () => console.log("Forward bark playing"));
    backAudioRef.current.addEventListener("play", () => console.log("Back bark playing"));
  }, []);

  useEffect(() => {
    const handleEscape = (e) => {
      console.log("Key pressed:", e.key);
      if (e.key === "Escape" && showLargeImage) {
        console.log("Escape pressed, closing image");
        setShowLargeImage(false);
        setResult(null);
        setAttemptsLeft(3);
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [showLargeImage]);

 // Update this section in the setupControls function
// This goes inside the useEffect where we set up the drag functionality

// Update this section in the setupControls function
// This goes inside the useEffect where we set up the drag functionality

useEffect(() => {
  const setupControls = () => {
    // First, make sure the container exists
    const container = document.getElementById("dog-game-container");
    if (!container) {
      console.error("Container element not found");
      return;
    }

    console.log("Setting up drag controls for mini-game");
    
    // Remove smooth scrolling which can interfere with direct drag
    container.style.scrollBehavior = "auto";
    
    // Set initial state variables
    let isDraggingInternal = false;
    let startX, startY, scrollLeft, scrollTop;
    let hasMoved = false; // Track if actually moved (for distinguishing clicks from drags)
    let startTime = 0; // Track when the mouse/touch started
    
    // --- DESKTOP MOUSE CONTROLS ---
    const handleMouseDown = (e) => {
      // Only handle left mouse button
      if (e.button !== 0) return;
      
      // Prevent default browser behavior that might interfere
      e.preventDefault();
      
      // Reset move tracking
      hasMoved = false;
      startTime = Date.now();
      
      // Record start position and current scroll position
      startX = e.pageX;
      startY = e.pageY;
      scrollLeft = container.scrollLeft;
      scrollTop = container.scrollTop;
      
      // Mark as potential drag starting
      isDraggingInternal = true;
      
      // Update cursor
      container.style.cursor = "grabbing";
      document.body.style.userSelect = "none";
    };
    
    const handleMouseMove = (e) => {
      if (!isDraggingInternal) return;
      
      // Calculate how far the mouse has moved
      const x = e.pageX;
      const y = e.pageY;
      const moveDistance = Math.sqrt(
        Math.pow(startX - x, 2) + Math.pow(startY - y, 2)
      );
      
      // If moved more than a minimum threshold, consider it a drag
      if (moveDistance > 5) {
        hasMoved = true;
        setIsDragging(true);
        
        // Calculate how far to scroll based on mouse movement
        const walkX = (startX - x) * 1.5; // Increased multiplier
        const walkY = (startY - y) * 1.5;
        
        // Update scroll position directly
        container.scrollLeft = scrollLeft + walkX;
        container.scrollTop = scrollTop + walkY;
      }
    };
    
    const handleMouseUp = (e) => {
      // Process as a click if it wasn't a significant drag
      const endTime = Date.now();
      const clickDuration = endTime - startTime;
      
      if (isDraggingInternal && !hasMoved && clickDuration < 300 && gameStarted) {
        // This was a click, not a drag - handle hotspot detection
        const imgElement = document.querySelector("#game-image-container img");
        if (imgElement) {
          const rect = imgElement.getBoundingClientRect();
          const clickX = e.clientX - rect.left;
          const clickY = e.clientY - rect.top;
          
          // Scale to the actual image dimensions
          const scaleX = imgElement.naturalWidth / rect.width;
          const scaleY = imgElement.naturalHeight / rect.height;
          
          const scaledX = clickX * scaleX;
          const scaledY = clickY * scaleY;
          
          console.log(`Click at X: ${scaledX}, Y: ${scaledY}`);
          console.log(`Hotspot: X1=${hotspot.x1}, X2=${hotspot.x2}, Y1=${hotspot.y1}, Y2=${hotspot.y2}`);
          
          // Check if click is in hotspot
          if (
            scaledX >= hotspot.x1 &&
            scaledX <= hotspot.x2 &&
            scaledY >= hotspot.y1 &&
            scaledY <= hotspot.y2
          ) {
            console.log("You clicked inside the hotspot!");
            setResult("Winner!");
          } else {
            console.log("You clicked outside the hotspot.");
            setWrongGuess(true);
            
            // Reset wrong guess state after animation
            setTimeout(() => {
              setWrongGuess(false);
            }, 800);
            
            setAttemptsLeft((prev) => {
              const newAttempts = prev - 1;
              if (newAttempts <= 0) {
                setResult("Game Over");
              }
              return newAttempts;
            });
          }
        }
      }
      
      // End dragging state
      isDraggingInternal = false;
      hasMoved = false;
      
      // Small delay before resetting isDragging state
      setTimeout(() => {
        setIsDragging(false);
      }, 50);
      
      // Reset cursor
      container.style.cursor = "grab";
      document.body.style.userSelect = "";
    };
    
    // --- MOBILE TOUCH CONTROLS ---
    const handleTouchStart = (e) => {
      if (e.touches.length !== 1) return;
      
      // Reset move tracking
      hasMoved = false;
      startTime = Date.now();
      
      // Record initial touch position and current scroll position
      isDraggingInternal = true;
      
      startX = e.touches[0].pageX;
      startY = e.touches[0].pageY;
      scrollLeft = container.scrollLeft;
      scrollTop = container.scrollTop;
    };
    
    const handleTouchMove = (e) => {
      if (!isDraggingInternal || e.touches.length !== 1) return;
      
      // Calculate how far the touch has moved
      const x = e.touches[0].pageX;
      const y = e.touches[0].pageY;
      const moveDistance = Math.sqrt(
        Math.pow(startX - x, 2) + Math.pow(startY - y, 2)
      );
      
      // If moved more than a minimum threshold, consider it a drag
      if (moveDistance > 10) { // Higher threshold for touch
        hasMoved = true;
        setIsDragging(true);
        
        // Higher multiplier for touch to make it more responsive
        const walkX = (startX - x) * 2.5;
        const walkY = (startY - y) * 2.5;
        
        // Directly update scroll position
        container.scrollLeft = scrollLeft + walkX;
        container.scrollTop = scrollTop + walkY;
      }
      
      // Only prevent default if we're actually dragging
      if (hasMoved) {
        e.preventDefault();
      }
    };
    
    const handleTouchEnd = (e) => {
      // Process as a tap if it wasn't a significant drag
      const endTime = Date.now();
      const tapDuration = endTime - startTime;
      
      if (isDraggingInternal && !hasMoved && tapDuration < 300 && gameStarted) {
        // This was a tap, not a drag - handle hotspot detection
        const imgElement = document.querySelector("#game-image-container img");
        if (imgElement && e.changedTouches && e.changedTouches.length > 0) {
          const rect = imgElement.getBoundingClientRect();
          const touchX = e.changedTouches[0].clientX - rect.left;
          const touchY = e.changedTouches[0].clientY - rect.top;
          
          // Scale to the actual image dimensions
          const scaleX = imgElement.naturalWidth / rect.width;
          const scaleY = imgElement.naturalHeight / rect.height;
          
          const scaledX = touchX * scaleX;
          const scaledY = touchY * scaleY;
          
          console.log(`Tap at X: ${scaledX}, Y: ${scaledY}`);
          console.log(`Hotspot: X1=${hotspot.x1}, X2=${hotspot.x2}, Y1=${hotspot.y1}, Y2=${hotspot.y2}`);
          
          // Check if tap is in hotspot
          if (
            scaledX >= hotspot.x1 &&
            scaledX <= hotspot.x2 &&
            scaledY >= hotspot.y1 &&
            scaledY <= hotspot.y2
          ) {
            console.log("You tapped inside the hotspot!");
            setResult("Winner!");
          } else {
            console.log("You tapped outside the hotspot.");
            setWrongGuess(true);
            
            // Reset wrong guess state after animation
            setTimeout(() => {
              setWrongGuess(false);
            }, 800);
            
            setAttemptsLeft((prev) => {
              const newAttempts = prev - 1;
              if (newAttempts <= 0) {
                setResult("Game Over");
              }
              return newAttempts;
            });
          }
        }
      }
      
      // End dragging state
      isDraggingInternal = false;
      hasMoved = false;
      
      // Small delay before resetting isDragging state
      setTimeout(() => {
        setIsDragging(false);
      }, 50);
    };

    // Add all event listeners with the right options
    container.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove, { passive: false });
    document.addEventListener('mouseup', handleMouseUp);
    
    // Touch events need { passive: false } to allow preventDefault()
    container.addEventListener('touchstart', handleTouchStart, { passive: true });
    container.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
    
    // Cleanup all event listeners when the component unmounts
    return () => {
      container.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  };
  
  // Set up controls when showLargeImage becomes true
  if (showLargeImage) {
    // Use a slightly longer delay to make sure the DOM is fully ready
    setTimeout(setupControls, 800);
  }
  
  // Make sure to clean up when unmounting
  return () => {
    const container = document.getElementById("dog-game-container");
    if (container) {
      container.style.scrollBehavior = "smooth"; // Reset to default
    }
  };
}, [showLargeImage, setIsDragging, hotspot, gameStarted, setResult, setWrongGuess, setAttemptsLeft]);

// Also, remove or comment out the onClick event on the image element in the container-fix implementation
// The above useEffect will handle the clicks now


  
  const playBarkSound = () => {
    console.log("Attempting to play sound...");
    try {
      audioRef.current.currentTime = 0;
      audioRef.current
        .play()
        .then(() => console.log("Sound played successfully"))
        .catch((error) => console.error("Error playing sound:", error));
    } catch (error) {
      console.error("Error in playBarkSound:", error);
    }
  };

  const playBackBarkSound = () => {
    console.log("Attempting to play back sound...");
    try {
      backAudioRef.current.currentTime = 0;
      backAudioRef.current
        .play()
        .then(() => console.log("Back sound played successfully"))
        .catch((error) => console.error("Error playing back sound:", error));
    } catch (error) {
      console.error("Error in playBackBarkSound:", error);
    }
  };

  const playTrickBarkSound = () => {
    console.log("Attempting to play trick sound...");
    try {
      trickAudioRef.current.currentTime = 0;
      trickAudioRef.current
        .play()
        .then(() => console.log("Trick sound played successfully"))
        .catch((error) => console.error("Error playing trick sound:", error));
    } catch (error) {
      console.error("Error in playTrickBarkSound:", error);
    }
  };

  const playDownloadBarkSound = () => {
    console.log("Attempting to play download sound...");
    try {
      downloadAudioRef.current.currentTime = 0;
      downloadAudioRef.current
        .play()
        .then(() => console.log("Download sound played successfully"))
        .catch((error) => console.error("Error playing download sound:", error));
    } catch (error) {
      console.error("Error in playDownloadBarkSound:", error);
    }
  };

  const handleMusicPlayPause = (e) => {
    e.stopPropagation();
    if (!isMusicPlaying) {
      musicAudioRef.current
        .play()
        .then(() => {
          setIsMusicPlaying(true);
        })
        .catch((err) => console.log("Music play error:", err));
    } else {
      musicAudioRef.current.pause();
      setIsMusicPlaying(false);
    }
  };

  const handleSkipTrack = (e) => {
    e.stopPropagation();
    const nextIndex = (currentTrackIndex + 1) % dogTunesPaths.length;
    setCurrentTrackIndex(nextIndex);
  };

  const handleVolumeToggle = (e) => {
    e.stopPropagation();
    setVolumeLevel((prev) => (prev === "low" ? "medium" : "low"));
  };

  // Handler for auth popup close
  const handleAuthClose = () => {
    setShowAuthPopup(false);
  };

  return (
    <div>
      <SectionTitle>Retro Dog Cards</SectionTitle>
      <MotionDivider />

      {/* Dog Card Container with Navigation Buttons */}
      <div
        style={{
          // Minimal padding & smaller radius on mobile
          padding: window.innerWidth < 600 ? "0.25rem" : "2rem",
          borderRadius: window.innerWidth < 600 ? "0.25rem" : "8px",
          backgroundColor: "#1a237e",
          color: "#fff",
          width: "100%",
          maxWidth: "100%",
          boxSizing: "border-box",
          margin: "1rem auto", // only top/bottom margin
        }}
      >
        {/* Outer flex to center our sub-container */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Sub-container that keeps arrows near the dog card on desktop */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // On desktop, a larger gap so arrows are ~1 inch from the card
              gap: window.innerWidth < 600 ? "0.5rem" : "2rem",
              maxWidth: "40rem", // ~640px wide so they don't stretch too far
              width: "100%",
              margin: "0 auto",
              boxSizing: "border-box",
            }}
          >
            {/* Left Navigation Button */}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigateBack();
                playBackBarkSound();
              }}
              sx={{
                background: "linear-gradient(145deg, #3f51b5, #283593)",
                color: "white",
                width: "50px",
                height: "50px",
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>

            {/* Dog Card */}
            <div
              ref={cardRef}
              style={{
                backgroundColor: "#1a237e",
                padding: window.innerWidth < 600 ? "0.5rem" : "1rem",
                borderRadius: window.innerWidth < 600 ? "0.25rem" : "8px",
                cursor: "pointer",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              {loading ? (
                <p
                  style={{
                    fontFamily: "'Press Start 2P', cursive",
                    fontSize: "1.5rem",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  Loading Breed...
                </p>
              ) : (
                <div>
                  {/* Breed Name */}
                  <h2
                    style={{
                      fontFamily: "'Press Start 2P', cursive",
                      marginBottom: "1rem",
                      fontSize: "clamp(1rem, 4vw, 1.75rem)",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                      textAlign: "center",
                      width: "100%",
                      padding: window.innerWidth < 600 ? "0.25rem" : "0 10px",
                      overflowWrap: "break-word",
                      wordWrap: "break-word",
                      hyphens: "auto",
                      boxSizing: "border-box",
                    }}
                  >
                    {currentBreed}
                  </h2>

                  {/* Image Container */}
                  <div style={{ margin: "1rem 0", width: "100%" }}>
                    <img
                      src={currentImageUrl}
                      alt={`${currentBreed} sprite`}
                      onError={(e) => {
                        console.error("Error loading image:", e.target.src);
                        e.target.onerror = null;
                      }}
                      style={{
                        display: "block",
                        width: "100%", // fill container
                        maxWidth: window.innerWidth < 600 ? "100%" : "256px",
                        height: "auto",
                        margin: "0 auto",
                        boxSizing: "border-box",
                        backgroundColor: "#1a237e",
                        padding: window.innerWidth < 600 ? "2px" : "10px",
                      }}
                    />
                  </div>

                  {/* Description Section */}
                  <div
                    style={{
                      margin: window.innerWidth < 600 ? "0.5rem 0" : "1.5rem auto",
                      width: "100%",
                      maxWidth: window.innerWidth < 600 ? "100%" : "600px",
                      padding: window.innerWidth < 600 ? "0.5rem" : "1rem",
                      backgroundColor: "rgba(255,255,255,0.1)",
                      borderRadius: "4px",
                      minHeight: window.innerWidth < 600 ? "80px" : "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        padding: window.innerWidth < 600 ? "0.25rem" : "0.5rem",
                        fontSize: window.innerWidth < 600 ? "0.85rem" : "1rem",
                        textAlign: "center",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        hyphens: "auto",
                        width: "100%",
                        lineHeight: window.innerWidth < 600 ? "1.3" : "1.5",
                      }}
                    >
                      {currentDescription}
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Right Navigation Button */}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                showNextBreed();
              }}
              sx={{
                background: "linear-gradient(145deg, #3f51b5, #283593)",
                color: "white",
                width: "50px",
                height: "50px",
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        </div>

        {/* Action Buttons Row */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: window.innerWidth < 600 ? "0.5rem" : "1rem",
            marginTop: "1rem",
            flexWrap: window.innerWidth < 400 ? "wrap" : "nowrap",
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setShowLargeImageWithSprite();
            }}
            sx={{
              background: "linear-gradient(145deg, #3f51b5, #283593)",
              color: "white",
              width: window.innerWidth < 600 ? "50px" : "60px",
              height: window.innerWidth < 600 ? "50px" : "60px",
              borderRadius: "50%",
              boxShadow:
                "4px 4px 10px rgba(0, 0, 0, 0.2), -4px -4px 10px rgba(255, 255, 255, 0.1)",
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
            }}
          >
            <SearchIcon fontSize={window.innerWidth < 600 ? "medium" : "large"} />
          </IconButton>

          {/* Download Button */}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              downloadCard();
              playDownloadBarkSound();
            }}
            sx={{
              background: "linear-gradient(145deg, #3f51b5, #283593)",
              color: "white",
              width: window.innerWidth < 600 ? "50px" : "60px",
              height: window.innerWidth < 600 ? "50px" : "60px",
              borderRadius: "50%",
              boxShadow:
                "4px 4px 10px rgba(0, 0, 0, 0.2), -4px -4px 10px rgba(255, 255, 255, 0.1)",
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
            }}
          >
            <DownloadIcon fontSize={window.innerWidth < 600 ? "medium" : "large"} />
          </IconButton>

          {/* Play Description Button */}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              speakDescription();
            }}
            sx={{
              background: "linear-gradient(145deg, #3f51b5, #283593)",
              color: "white",
              width: window.innerWidth < 600 ? "50px" : "60px",
              height: window.innerWidth < 600 ? "50px" : "60px",
              borderRadius: "50%",
              boxShadow:
                "4px 4px 10px rgba(0, 0, 0, 0.2), -4px -4px 10px rgba(255, 255, 255, 0.1)",
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
              outline: "none",
            }}
          >
            <HearingIcon fontSize={window.innerWidth < 600 ? "medium" : "large"} />
          </IconButton>

          {/* Generate Random Action Button */}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              generateRandomAction();
              playTrickBarkSound();
            }}
            sx={{
              background: "linear-gradient(145deg, #3f51b5, #283593)",
              color: "white",
              width: window.innerWidth < 600 ? "50px" : "60px",
              height: window.innerWidth < 600 ? "50px" : "60px",
              borderRadius: "50%",
              boxShadow:
                "4px 4px 10px rgba(0, 0, 0, 0.2), -4px -4px 10px rgba(255, 255, 255, 0.1)",
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
              outline: "none",
              animation: isSpinning.action ? "spinAndPulse 1.2s infinite linear" : "none",
            }}
          >
            <RecordVoiceOverIcon fontSize={window.innerWidth < 600 ? "medium" : "large"} />
          </IconButton>
        </div>


        {/* Large Image Section - Improved Mobile Experience */}
        {showLargeImage && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              overflow: "hidden",
              zIndex: 9999,
              WebkitOverflowScrolling: "touch",
            }}
            onClick={() => {
              if (loadingMessage === "Ready!" && !gameStarted) {
                setGameStarted(true);
                setLoadingMessage("");
              }
            }}
          >
            {/* Instructions Header - Only show when NOT started */}
            {!gameStarted && (
              <div
                style={{
                  position: "fixed",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  color: "#FFFFFF",
                  fontFamily: "'Press Start 2P', cursive",
                  fontSize: window.innerWidth < 600 ? "10px" : "14px",
                  textAlign: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  padding: window.innerWidth < 600 ? "6px 10px 25px 10px" : "10px 20px 25px 20px", // Increased bottom padding
                  borderRadius: "8px",
                  zIndex: 10001,
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
                  width: window.innerWidth < 600 ? "95%" : "90%",
                  maxHeight: window.innerWidth < 600 ? "40vh" : "auto",
                  overflowY: window.innerWidth < 600 ? "auto" : "visible",
                }}
              >
                {/* Music Controls */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: window.innerWidth < 600 ? "0.5rem" : "1rem",
                    marginBottom: "8px",
                  }}
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMusicPlayPause();
                    }}
                    style={{
                      border: "2px solid #fff",
                      borderRadius: "4px",
                      backgroundColor: "#1a237e",
                      color: "#fff",
                      width: window.innerWidth < 600 ? "40px" : "50px",
                      height: window.innerWidth < 600 ? "40px" : "50px",
                      fontFamily: "'Press Start 2P', cursive",
                      cursor: "pointer",
                      fontSize: window.innerWidth < 600 ? "12px" : "16px",
                    }}
                  >
                    {isMusicPlaying ? "⏸" : "▶"}
                  </button>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSkipTrack(e);
                    }}
                    style={{
                      border: "2px solid #fff",
                      borderRadius: "4px",
                      backgroundColor: "#1a237e",
                      color: "#fff",
                      width: window.innerWidth < 600 ? "40px" : "50px",
                      height: window.innerWidth < 600 ? "40px" : "50px",
                      fontFamily: "'Press Start 2P', cursive",
                      cursor: "pointer",
                      fontSize: window.innerWidth < 600 ? "12px" : "16px",
                    }}
                  >
                    ⏭
                  </button>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleVolumeToggle(e);
                    }}
                    style={{
                      border: "2px solid #fff",
                      borderRadius: "4px",
                      backgroundColor: "#1a237e",
                      color: "#fff",
                      width: window.innerWidth < 600 ? "40px" : "50px",
                      height: window.innerWidth < 600 ? "40px" : "50px",
                      fontFamily: "'Press Start 2P', cursive",
                      cursor: "pointer",
                      fontSize: window.innerWidth < 600 ? "12px" : "16px",
                    }}
                  >
                    {volumeLevel === "low" ? "🔈" : "🔉"}
                  </button>
                </div>

                {/* Show isolated sprite in the instructions */}
                {transparentSpriteUrl && (
                  <div style={{
                    margin: "10px auto",
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <img
                      src={transparentSpriteUrl}
                      alt={`${currentBreed} sprite`}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain"
                      }}
                    />
                  </div>
                )}

                <p style={{ margin: window.innerWidth < 600 ? "6px 0" : "10px 0" }}>
                  Find the {currentBreed}!
                </p>


                {window.innerWidth < 600 ? (
                  <p style={{ margin: "6px 0" }}>
                    Tap, drag, and pinch to navigate the image. Find and tap on the {currentBreed}!
                  </p>
                ) : (
                  <>
                    {navigator.platform.toLowerCase().includes("mac") ? (
                      <>
                        <p>Step 1 - Use two fingers on trackpad to scroll</p>
                        <p>Step 2 - Navigate to locate the dog breed</p>
                        <p>Step 3 - Click on Dog Breed (3 chances to win!)</p>
                      </>
                    ) : (
                      <>
                        <p>Step 1 - Use mouse wheel or middle-click to scroll</p>
                        <p>Step 2 - Navigate to locate the dog breed</p>
                        <p>Step 3 - Click on Dog Breed (3 chances to win!)</p>
                      </>
                    )}
                  </>
                )}
              </div>
            )}

            {/* Minimal Game Status Bar - Only show when game IS started */}
            {gameStarted && (
              <div style={{
                position: "fixed",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                padding: "4px 10px",
                background: "rgba(0, 0, 0, 0.5)",
                borderRadius: "4px",
                zIndex: 10001,
                display: "flex",
                alignItems: "center",
                gap: "10px"
              }}
                className={wrongGuess ? "wrong-guess" : ""}
              >
                {/* Keep music controls in the gameplay mode too */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMusicPlayPause();
                  }}
                  style={{
                    border: "2px solid #fff",
                    borderRadius: "4px",
                    backgroundColor: "#1a237e",
                    color: "#fff",
                    width: "30px",
                    height: "30px",
                    fontFamily: "'Press Start 2P', cursive",
                    cursor: "pointer",
                    fontSize: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {isMusicPlaying ? "⏸" : "▶"}
                </button>

                <p style={{
                  margin: "0",
                  fontSize: window.innerWidth < 600 ? "8px" : "12px",
                  color: "#FFFFFF",
                  fontFamily: "'Press Start 2P', cursive"
                }}>
                  Find the {currentBreed}! {wrongGuess ? "Nope! • " : "• "}
                  <span className={wrongGuess ? "text-flash" : ""}>
                    Attempts: {attemptsLeft}
                  </span>
                </p>
              </div>
            )}

            {/* "Ready!" message - Only show when game is not started */}
            {!gameStarted && (
              <div
                style={{
                  position: "fixed",
                  top: window.innerWidth < 600 ? "170px" : "300px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  color: loadingMessage === "Ready!" ? "#00FF00" : "#FFD700",
                  fontFamily: "'Press Start 2P', cursive",
                  fontSize: window.innerWidth < 600 ? "14px" : "18px",
                  textAlign: "center",
                  zIndex: 10001,
                }}
              >
                {loadingMessage}
                {loadingMessage === "Ready!" && (
                  <p
                    style={{
                      color: "#FFFFFF",
                      marginTop: "15px", // Increased from 10px to 15px
                      marginBottom: "15px", // Added margin-bottom for more space
                      fontSize: window.innerWidth < 600 ? "12px" : "16px",
                      padding: "0 10px", // Added horizontal padding
                    }}
                  >
                    {window.innerWidth < 600 ? "Tap to Start" : "Click Here to Start Mini Game"}
                  </p>
                )}
              </div>
            )}

            

            
            

            {/* Game Container */}
            <div
  id="dog-game-container"
  style={{
    height: "calc(100vh - 100px)",
    width: "100%",
    overflowY: "scroll",
    overflowX: "scroll",
    marginTop: gameStarted ? "60px" : (window.innerWidth < 600 ? "100px" : "380px"),
    scrollBehavior: "auto", // Changed from "smooth" to "auto" for better drag response
    WebkitOverflowScrolling: "touch",
    position: "relative",
    willChange: "scroll-position", // Performance optimization for scroll
    msOverflowStyle: "none", // Hide scrollbars on IE/Edge
    scrollbarWidth: "none", // Hide scrollbars on Firefox
  }}
  onScroll={(e) => {
    if (window.innerWidth < 600) {
      const container = e.currentTarget;
      const viewport = document.getElementById("minimap-viewport");
      if (viewport && container) {
        const percentX = container.scrollLeft / (10240 - window.innerWidth);
        const percentY = (container.scrollTop - 350) / (5760 - window.innerHeight);

        viewport.style.left = `${percentX * 80}px`;
        viewport.style.top = `${percentY * 45}px`;
      }
    }
  }}
>
  <div
    id="game-image-container"
    style={{
      transformOrigin: "0 0",
      position: "relative", // Ensure positioning context for absolute sprite
      width: window.innerWidth < 600 ? "5120px" : "10240px",  // Explicitly set dimensions
      height: window.innerWidth < 600 ? "2880px" : "5760px",
    }}
  >
    <img
      src={`${MEDIA_BASE_URL}/FindDogBreed.png`}
      alt="Find the Dog Breed"
      onLoad={() => setLoadingMessage("Ready!")}
      onError={() => setLoadingMessage("Failed to Load Image")}
      style={{
        width: "100%",
        height: "100%",
        cursor: result ? "default" : "pointer",
        display: "block",
        userSelect: "none",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
      }}
    />

    {/* Add the transparent sprite overlay - only when game has started */}
    {gameStarted && miniGameData && transparentSpriteUrl && (
      <img
        src={transparentSpriteUrl}
        alt={`${currentBreed} sprite`}
        style={{
          position: "absolute",
          left: `${miniGameData.position.x}px`,
          top: `${miniGameData.position.y}px`,
          width: `${miniGameData.position.width}px`,
          height: `${miniGameData.position.height}px`,
          pointerEvents: "none", // Allows click to pass through to background
        }}
      />
    )}
  </div>
</div>
          </div>
        )}

        {/* Result Section */}
        {result && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10001,
            }}
            onClick={() => {
              setShowLargeImage(false);
              setResult(null);
              setAttemptsLeft(3);
            }}
          >
            <h1
              style={{
                fontFamily: "'Press Start 2P', cursive",
                color: result === "Winner!" ? "#00FF00" : "#FF0000",
                fontSize: "48px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              {result}
            </h1>
            <Typography
              style={{
                fontFamily: "'Press Start 2P', cursive",
                color: "#FFFFFF",
                fontSize: "16px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              Hit Back or Hit (Esc) to exit / retry
            </Typography>
          </div>
        )}

<style>{`
  @keyframes spinAndPulse {
    0% {
      transform: rotate(0deg) scale(1);
      transform-origin: center;
    }
    50% {
      transform: rotate(180deg) scale(1.1);
      transform-origin: center;
    }
    100% {
      transform: rotate(360deg) scale(1);
      transform-origin: center;
    }
  }

  button:focus, button:active {
    outline: none;
    box-shadow: none;
    background: linear-gradient(145deg, #3f51b5, #283593);
  }

  button {
    -webkit-tap-highlight-color: transparent;
  }
  
 @keyframes wrongGuessFlash {
  0% { background-color: rgba(0, 0, 0, 0.5); }
  50% { background-color: rgba(200, 0, 0, 0.9); } /* Brighter red */
  100% { background-color: rgba(0, 0, 0, 0.5); }
}

.wrong-guess {
  animation: wrongGuessFlash 0.8s ease;
}

@keyframes textFlash {
  0% { color: #FFFFFF; }
  50% { color: #FF3333; } /* Brighter red */
  100% { color: #FFFFFF; }
}

.text-flash {
  animation: textFlash 0.8s ease;
}

#dog-game-container {
  cursor: grab;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  
}

#dog-game-container:active {
  cursor: grabbing;
}

.is-dragging {
  cursor: grabbing !important;
}

/* Add smooth inertia scrolling for iOS Safari */
@media (pointer: coarse) {
  #dog-game-container {
    -webkit-overflow-scrolling: touch;
  }
}

/* Hide scrollbars but keep functionality */
#dog-game-container::-webkit-scrollbar {
  display: none;
}

/* Ensure drag cursor is consistently shown */
#dog-game-container.is-dragging,
#dog-game-container.is-dragging * {
  cursor: grabbing !important;
  user-select: none !important;
}

`}</style>

      </div>

      {/* Auth Popup */}
      {showAuthPopup && <AuthPopup onClose={handleAuthClose} fromDogCards={true} />}


    </div>
  );
}

const DOG_BREEDS = [
  "Affenpinscher",
  "Afghan Hound",
  "Airedale Terrier",
  "Akita",
  "Alaskan Malamute",
  "American Bulldog",
  "American Eskimo Dog",
  "American Foxhound",
  "American Pit Bull Terrier",
  "American Staffordshire Terrier",
  "Anatolian Shepherd Dog",
  "Australian Cattle Dog",
  "Australian Shepherd",
  "Australian Terrier",
  "Basenji",
  "Basset Hound",
  "Beagle",
  "Bearded Collie",
  "Beauceron",
  "Bedlington Terrier",
  "Belgian Malinois",
  "Belgian Sheepdog",
  "Belgian Tervuren",
  "Bergamasco",
  "Berger Picard",
  "Bernese Mountain Dog",
  "Bichon Frise",
  "Black and Tan Coonhound",
  "Bloodhound",
  "Boerboel",
  "Border Collie",
  "Border Terrier",
  "Borzoi",
  "Boston Terrier",
  "Bouvier des Flandres",
  "Boxer",
  "Boykin Spaniel",
  "Briard",
  "Brittany",
  "Brussels Griffon",
  "Bull Terrier",
  "Bulldog",
  "Bullmastiff",
  "Cairn Terrier",
  "Canaan Dog",
  "Cane Corso",
  "Cardigan Welsh Corgi",
  "Cavalier King Charles Spaniel",
  "Chesapeake Bay Retriever",
  "Chihuahua",
  "Chinese Crested",
  "Chow Chow",
  "Clumber Spaniel",
  "Cocker Spaniel",
  "Collie",
  "Coton de Tulear",
  "Dachshund",
  "Dalmatian",
  "Dandie Dinmont Terrier",
  "Doberman Pinscher",
  "Dogue de Bordeaux",
  "English Cocker Spaniel",
  "English Foxhound",
  "English Setter",
  "English Springer Spaniel",
  "English Toy Spaniel",
  "Entlebucher Mountain Dog",
  "Field Spaniel",
  "Finnish Spitz",
  "Flat-Coated Retriever",
  "French Bulldog",
  "German Pinscher",
  "German Shepherd Dog",
  "German Shorthaired Pointer",
  "German Wirehaired Pointer",
  "Giant Schnauzer",
  "Glen of Imaal Terrier",
  "Golden Retriever",
  "Gordon Setter",
  "Great Dane",
  "Great Pyrenees",
  "Greater Swiss Mountain Dog",
  "Greyhound",
  "Harrier",
  "Havanese",
  "Ibizan Hound",
  "Icelandic Sheepdog",
  "Irish Setter",
  "Irish Terrier",
  "Irish Wolfhound",
  "Italian Greyhound",
  "Japanese Chin",
  "Keeshond",
  "Kerry Blue Terrier",
  "Komondor",
  "Kuvasz",
  "Labrador Retriever",
  "Labradoodle",
  "Lakeland Terrier",
  "Leonberger",
  "Lhasa Apso",
  "Lowchen",
  "Maltese",
  "Manchester Terrier",
  "Mastiff",
  "Miniature American Shepherd",
  "Miniature Pinscher",
  "Miniature Schnauzer",
  "Newfoundland",
  "Norfolk Terrier",
  "Norwegian Buhund",
  "Norwegian Elkhound",
  "Norwich Terrier",
  "Nova Scotia Duck Tolling Retriever",
  "Old English Sheepdog",
  "Otterhound",
  "Papillon",
  "Parson Russell Terrier",
  "Pekingese",
  "Pembroke Welsh Corgi",
  "Pharaoh Hound",
  "Plott (Plott Hound)",
  "Pointer",
  "Polish Lowland Sheepdog",
  "Portuguese Podengo Pequeno",
  "Portuguese Water Dog",
  "Pug",
  "Puli",
  "Pyrenean Mastiff",
  "Pyrenean Shepherd",
  "Rat Terrier",
  "Redbone Coonhound",
  "Rhodesian Ridgeback",
  "Rottweiler",
  "Russell Terrier",
  "Saint Bernard",
  "Saluki",
  "Samoyed",
  "Schipperke",
  "Scottish Deerhound",
  "Scottish Terrier",
  "Sealyham Terrier",
  "Segugio Italiano",
  "Shetland Sheepdog",
  "Shiba Inu",
  "Shih Tzu",
  "Siberian Husky",
  "Silky Terrier",
  "Skye Terrier",
  "Sloughi",
  "Smooth Fox Terrier",
  "Spanish Water Dog",
  "Spinone Italiano",
  "Stabyhoun",
  "Staffordshire Bull Terrier",
  "Standard Schnauzer",
  "Sussex Spaniel",
  "Swedish Lapphund",
  "Swedish Vallhund",
  "Tibetan Mastiff",
  "Tibetan Spaniel",
  "Tibetan Terrier",
  "Toy Fox Terrier",
  "Treeing Walker Coonhound",
  "Vizsla",
  "Weimaraner",
  "Welsh Springer Spaniel",
  "Welsh Terrier",
  "West Highland White Terrier",
  "Whippet",
  "Wire Fox Terrier",
  "Wirehaired Pointing Griffon",
  "Xoloitzcuintli",
  "Yorkshire Terrier",
  "Zuchon",
  // Additional Breeds
  "Aidi",
  "Azawakh",
  "Carolina Dog",
  "Cirneco dell'Etna",
  "Czechoslovakian Wolfdog",
  "Dogo Argentino",
  "Presa Canario",
  "English Shepherd",
  "Hovawart",
  "Japanese Spitz",
  "Kai Ken",
  "Kooikerhondje",
  "Mudi",
  "Norwegian Lundehund",
  "Peruvian Inca Orchid",
  "Pumi",
  "Rajapalayam",
  "Thai Ridgeback",
  "Tosa Inu",
  "American Hairless Terrier",
  "Spanish Mastiff",
  "New Guinea Singing Dog",
  "Portuguese Podengo Grande",
  "Saarloos Wolfdog"
];

export default DogSprite;

