// src/components/BlogPost.js
import React from 'react';
import { motion } from 'framer-motion';
import AdBanner from './AdBanner';

// Add this function to calculate reading time
const calculateReadingTime = (content) => {
  if (!Array.isArray(content) || content.length === 0) {
    return 1; // Default to 1 minute if no content
  }
  
  // Count words in all text content
  const wordsPerMinute = 200; // Average reading speed
  let wordCount = 0;
  
  content.forEach((section) => {
    if (section.type === 'regular' && section.text) {
      wordCount += section.text.split(/\s+/).length;
    }
    if (section.type === 'bullet-points' && Array.isArray(section.points)) {
      section.points.forEach((point) => {
        wordCount += point.split(/\s+/).length;
      });
    }
    if (section.heading) {
      wordCount += section.heading.split(/\s+/).length;
    }
  });
  
  // Calculate reading time in minutes
  const readingTime = Math.ceil(wordCount / wordsPerMinute);
  return Math.max(1, readingTime); // Minimum 1 minute
};

// Reading Time component
const ReadingTime = ({ minutes, isDesktop }) => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      fontSize: isDesktop ? '0.9rem' : '0.8rem',
      color: '#666',
      marginBottom: isDesktop ? '1.5rem' : '1rem',
      justifyContent: isDesktop ? 'center' : 'flex-start'
    }}>
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={isDesktop ? "16" : "14"} 
        height={isDesktop ? "16" : "14"} 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        style={{ marginRight: '5px' }}
      >
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 6 12 12 16 14"></polyline>
      </svg>
      <span>{minutes} min read</span>
    </div>
  );
};

const BlogPost = ({ post, isDesktop }) => {
  // Add safety checks for post properties
  if (!post) {
    return (
      <div style={{ padding: isDesktop ? '2rem' : '1rem', textAlign: 'center' }}>
        <p>Post data not available</p>
      </div>
    );
  }

  const {
    title,
    date,
    category,
    mainQuote,
    headerImage,
    authors,
    content = [], // Provide default empty array
    keyTakeaways = [], // Provide default empty array
    affiliateLink
  } = post;

  // Debug logging - can be removed in production
  console.log('Rendering post:', post.slug);
  console.log('Content array length:', content?.length || 0);

  const renderContent = (section, idx) => {
    // Safety check
    if (!section || !section.type) {
      console.warn('Invalid content section:', section);
      return null;
    }

    // Handle ad banners
    if (section.type === 'ad') {
      return <AdBanner key={`ad-${idx}`} adSlot="4250251137" />;
    }

    // Handle bullet points sections
    if (section.type === 'bullet-points') {
      return (
        <div
          key={idx}
          style={{
            margin: isDesktop ? '3rem auto' : '1.5rem 0',
            maxWidth: isDesktop ? '750px' : '100%',
            backgroundColor: isDesktop ? '#f8f9fa' : 'transparent',
            padding: isDesktop ? '2rem' : '0',
            borderRadius: isDesktop ? '12px' : '0',
            boxShadow: isDesktop ? '0 2px 8px rgba(0, 0, 0, 0.05)' : 'none'
          }}
        >
          {section.heading && (
            <h3
              style={{
                fontSize: isDesktop ? '1.8rem' : '1.2rem',
                fontWeight: 'bold',
                marginBottom: isDesktop ? '1.5rem' : '1rem',
                color: '#1a1a1a'
              }}
            >
              {section.heading}
            </h3>
          )}
          <ul
            style={{
              listStyle: 'none',
              padding: 0,
              margin: 0
            }}
          >
            {(section.points || []).map((point, i) => (
              <li
                key={i}
                style={{
                  fontSize: isDesktop ? '1.2rem' : '1rem',
                  lineHeight: isDesktop ? '1.9' : '1.7',
                  marginBottom: isDesktop ? '1.2rem' : '1rem',
                  paddingLeft: isDesktop ? '1.5rem' : '1.2rem',
                  position: 'relative',
                  color: '#1a1a1a'
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    left: 0,
                    color: isDesktop ? '#3B82F6' : '#1a1a1a',
                    fontWeight: 'bold'
                  }}
                >
                  •
                </span>
                {point}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    // Handle regular sections with or without headings
    return (
      <div
        key={idx}
        style={{
          margin: isDesktop ? '2rem auto' : '1.5rem 0',
          maxWidth: isDesktop ? '800px' : '100%',
          backgroundColor: isDesktop ? '#fff' : 'transparent',
          padding: isDesktop ? '1.5rem' : '0',
          borderRadius: isDesktop ? '12px' : '0',
          boxShadow: isDesktop ? '0 2px 8px rgba(0, 0, 0, 0.05)' : 'none'
        }}
      >
        {section.heading && (
          <h3
            style={{
              fontSize: isDesktop ? '2rem' : '1.2rem',
              fontWeight: 'bold',
              marginBottom: isDesktop ? '1rem' : '0.75rem',
              color: '#1a1a1a'
            }}
          >
            {section.heading}
          </h3>
        )}
        {section.text && (
          <p
            style={{
              fontSize: isDesktop ? '1.2rem' : '1rem',
              lineHeight: isDesktop ? '1.9' : '1.7',
              margin: 0,
              color: '#1a1a1a',
              overflowWrap: 'break-word',
              wordBreak: 'break-word'
            }}
          >
            {section.text}
            {(section.links || []).map((link, i) => (
              <a
                key={i}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#0066cc',
                  textDecoration: 'none',
                  marginLeft: '0.5rem'
                }}
              >
                {link.text}
              </a>
            ))}
          </p>
        )}
      </div>
    );
  };

  // If content is missing or invalid, display a fallback message
  const hasValidContent = Array.isArray(content) && content.length > 0;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      style={{
        width: '100%',
        maxWidth: isDesktop ? '1000px' : '100%',
        margin: isDesktop ? '0 auto 3rem' : '0',
        backgroundColor: isDesktop ? '#fff' : 'transparent',
        borderRadius: isDesktop ? '15px' : '0',
        boxShadow: isDesktop ? '0 4px 24px rgba(0, 0, 0, 0.1)' : 'none',
        padding: isDesktop ? '2rem' : '0.5rem 0.75rem',
      }}
    >
      {/* Header Image */}
      {headerImage && (
        <div style={{ width: '100%' }}>
          <img
            src={headerImage}
            alt={title || 'Article header'}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              borderRadius: isDesktop ? '12px' : '0',
              marginBottom: isDesktop ? '2rem' : '1rem'
            }}
          />
        </div>
      )}

      {/* Article Header */}
      <div style={{
        width: '100%',
        maxWidth: isDesktop ? '800px' : '100%',
        margin: '0 auto',
        padding: isDesktop ? '0 1rem' : '0'
      }}>
        {title && (
          <h2 style={{
            fontSize: isDesktop ? '2.8rem' : '1.5rem',
            fontWeight: 'bold',
            marginBottom: isDesktop ? '1.5rem' : '1rem',
            textAlign: isDesktop ? 'center' : 'left',
            lineHeight: '1.2',
            color: '#1a1a1a',
            letterSpacing: isDesktop ? '-0.02em' : 'normal'
          }}>
            {title}
          </h2>
        )}

        {(category || date) && (
          <div style={{
            textAlign: isDesktop ? 'center' : 'left',
            color: '#666',
            marginBottom: isDesktop ? '1.5rem' : '1rem',
            fontSize: isDesktop ? '1.1rem' : '0.9rem'
          }}>
            {category && (
              <span style={{
                fontWeight: '600',
                color: '#0066cc'
              }}>
                {category}
              </span>
            )}
            {category && date && ' • '}
            {date && date}
          </div>
        )}

        {mainQuote && (
          <blockquote style={{
            fontStyle: 'italic',
            marginBottom: isDesktop ? '2rem' : '1.5rem',
            color: '#444',
            textAlign: isDesktop ? 'center' : 'left',
            fontSize: isDesktop ? '1.4rem' : '1rem',
            lineHeight: isDesktop ? '1.6' : '1.4',
            padding: isDesktop ? '1rem' : '0 0 0 0.75rem',
            borderLeft: '2px solid #0066cc',
            backgroundColor: isDesktop ? '#f8f9fa' : 'transparent'
          }}>
            {mainQuote}
          </blockquote>
        )}

        {authors && (
          <p style={{
            textAlign: isDesktop ? 'center' : 'left',
            fontSize: isDesktop ? '1.1rem' : '0.9rem',
            color: '#666',
            marginBottom: isDesktop ? '1rem' : '0.5rem'
          }}>
            {authors}
          </p>
        )}

        {/* Reading Time indicator */}
        <ReadingTime 
          minutes={calculateReadingTime(content)} 
          isDesktop={isDesktop} 
        />

        {/* Key Takeaways - only render if there are takeaways */}
        {keyTakeaways.length > 0 && (
          <div style={{
            margin: isDesktop ? '3rem auto' : '1.5rem 0',
            maxWidth: isDesktop ? '750px' : '100%',
            backgroundColor: isDesktop ? '#f8f9fa' : 'transparent',
            padding: isDesktop ? '2rem' : '0',
            borderRadius: isDesktop ? '12px' : '0',
            boxShadow: isDesktop ? '0 2px 8px rgba(0, 0, 0, 0.05)' : 'none'
          }}>
            <h3 style={{
              fontSize: isDesktop ? '1.8rem' : '1.2rem',
              fontWeight: 'bold',
              marginBottom: isDesktop ? '1.5rem' : '1rem',
              color: '#1a1a1a'
            }}>
              Key Takeaways:
            </h3>
            <ul style={{
              listStyle: 'none',
              padding: 0,
              margin: 0
            }}>
              {keyTakeaways.map((point, i) => (
                <li key={i} style={{
                  fontSize: isDesktop ? '1.2rem' : '1rem',
                  lineHeight: isDesktop ? '1.9' : '1.7',
                  marginBottom: isDesktop ? '1.2rem' : '1rem',
                  paddingLeft: isDesktop ? '1.5rem' : '1.2rem',
                  position: 'relative',
                  color: '#1a1a1a'
                }}>
                  <span style={{
                    position: 'absolute',
                    left: 0,
                    color: isDesktop ? '#3B82F6' : '#1a1a1a',
                    fontWeight: 'bold'
                  }}>•</span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Main Content */}
        {hasValidContent ? (
          content.map((section, idx) => renderContent(section, idx))
        ) : (
          <div style={{
            margin: '2rem auto',
            padding: isDesktop ? '2rem' : '1rem',
            textAlign: 'center',
            backgroundColor: isDesktop ? '#f8f9fa' : 'transparent',
            borderRadius: isDesktop ? '12px' : '0'
          }}>
            <p style={{ fontSize: isDesktop ? '1.2rem' : '1rem', color: '#666' }}>
              Article content is being updated. Please check back soon.
            </p>
          </div>
        )}

        {/* Affiliate Link */}
        {affiliateLink && (
          <div style={{
            textAlign: 'center',
            margin: isDesktop ? '2rem auto' : '1.5rem auto'
          }}>
            <a
              href={affiliateLink.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'inline-block',
                padding: isDesktop ? '1rem 2rem' : '0.75rem 1.5rem',
                backgroundColor: '#0066cc',
                color: 'white',
                borderRadius: '8px',
                textDecoration: 'none',
                fontWeight: '600',
                boxShadow: isDesktop ? '0 4px 12px rgba(0, 102, 204, 0.2)' : 'none',
                fontSize: isDesktop ? '1rem' : '0.9rem'
              }}
            >
              {affiliateLink.text}
            </a>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default BlogPost;